import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Container = styled.div`
z-index: 900;
position: fixed;
top: 0;
left: 0;
bottom: 0;
right: 0;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
background: rgba(0, 0, 0, 0.7);
`

export const Modal = styled.div`
  z-index: 1;
  width: 90%;
  height: 70%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 25px;
  position: relative;
  animation: animate 0.3s;
  @media (min-width: 768px) {
    width: 50%;
  }
  @keyframes animate {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
`

export const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.blue6};
  margin: 20px;
  text-align: center;
`
