import React from 'react';
import {
  Row,
  ColumnOne,
  CollapsibleOne,
  TitleEspOne
} from './lpSpecialties.styles';

export default function ContentChild( { showList } ) {

  const result = [
    { id: 1, title: "Acupuntura" },
    { id: 2, title: "Alergologista" },
    { id: 3, title: "Angiologista" }, //
    { id: 4, title: "Cardiologista" }, //
    { id: 5, title: "Cirurgião Geral" }, // 
    { id: 6, title: "Cirurgião Plástico" }, //
    { id: 7, title: "Cirurgião Vascular" },
    { id: 8, title: "Clínico Geral" }, //
    { id: 9, title: "Dermatologista" }, //
    { id: 10, title: "Endocrinologista" }, //
    { id: 11, title: "Endocrinologista Infantil" },
    { id: 12, title: "Fisioterapia" },
    { id: 13, title: "Fonoaudiólogo" }, //
    { id: 14, title: "Gastroenterologia" }, //
    { id: 15, title: "Geriatria" },
    { id: 16, title: "Ginecologista" }, //
    { id: 17, title: "Hematologia" },
    { id: 18, title: "Homeopatia" },
    { id: 97, title: "Hepatologia Infantil" },
    { id: 19, title: "Infectologia" },
    { id: 20, title: "Imunoalergologista" },
    { id: 21, title: "Medicina do Trabalho" }, //
    { id: 21, title: "Médico da Família" }, //
    { id: 22, title: "Nefrologia" },
    { id: 23, title: "Neurocirurgia" },
    { id: 24, title: "Neurologista" }, //
    { id: 25, title: "Neurologista Infantil" },
    { id: 26, title: "Nutricionista" }, //
    { id: 27, title: "Obstetra" },
    { id: 28, title: "Odontologia" },
    { id: 29, title: "Oftalmologista" }, //
    { id: 30, title: "Oftalmologista Infantil" },
    { id: 31, title: "Oncologista" }, //
    { id: 32, title: "Ortopedista" }, //
    { id: 33, title: "Otorrinolaringologista" }, //
    { id: 34, title: "Pediatra" }, //
    { id: 35, title: "Pilates" },
    { id: 36, title: "Pneumologista" }, //
    { id: 98, title: "Podologia" }, //
    { id: 37, title: "Proctologista" },
    { id: 38, title: "Psicólogo" }, //
    { id: 99, title: "PsicoPedagogia" }, //
    { id: 39, title: "Psiquiatra" }, //
    { id: 40, title: "Reumatologista" }, //
    { id: 41, title: "Urologista" }, //
    { id: 42, title: "Terapia Ocupacional" }, //
    { id: 42, title: "Terapia de Casal" }, //
  ]

  return (
    <Row>
      {result.map( ( item, index ) => (
        <ColumnOne key={index}>
          <CollapsibleOne>
            <TitleEspOne >
              {item.title}
            </TitleEspOne>
          </CollapsibleOne>
        </ColumnOne>
      ) )}
    </Row>
  )
}