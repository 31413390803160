import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Title = styled.h2`
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 70px 0px 0px 0px;
  }
`

export const Description = styled.p`
  font-size: 12px;
  color: ${colors.gray2};
  margin: 5px 0px 20px 0px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 10px 0px 30px 0px;
  }
`

export const BarGrid = styled.div`
  width: 100%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const BarCard = styled.div`
  grid-column: span 4;
  height: 400px;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 1;
    display: block;
  }
`

//CARD FIXO


export const Card = styled.div`
  position: relative;
  width: 100%;
  height: -webkit-fill-available;
  background: ${colors.blue6};
`

export const CardAfter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(${colors.blue2}, rgba(0, 0, 0, .3));
  transition: all 0.2s ease-in;
  :hover{
    background: rgba(0, 0, 0, .1);
  }
`

export const Container = styled.div`
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const TextP = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: white;
  margin: 5px 0px 10px 0px;
`

export const Logo = styled.img`
  width: 50px;
`