import React, { useState } from 'react';
import {
  Row,
  ColumnOne,
  CollapsibleOne,
  TitleEspOne,
  TitleEspOnePointer,
} from './lpSpecialties.styles'
import colors from '../../assets/styles/colors'

export default function ContentChildFull( { showList } ) {

  const [ show, setShow ] = useState( false )
  const result = [
    { id: 1, title: "Exames de Laboratório" },
    { id: 2, title: "Ultrassom" },
    { id: 3, title: "Cardiologista" }, //
    { id: 4, title: "Vascular" }, //
    { id: 5, title: "Alergista" }, // 
    { id: 6, title: "Fisioterapeuta" }, //
    { id: 7, title: "Nutricionista" },
    { id: 8, title: "Fonoaudiólogo" }, //
  ]

  const full = [


    { id: 3, title: "Angiologista" }, //

    { id: 5, title: "Cirurgião Geral" }, // 

    { id: 7, title: "Cirurgião Vascular" },
    { id: 8, title: "Clínico Geral" }, //
    { id: 9, title: "Dermatologista" }, //
    { id: 10, title: "Endocrinologista" }, //
    { id: 11, title: "Endocrinologista Infantil" },


    { id: 14, title: "Gastroenterologia" }, //
    { id: 15, title: "Geriatria" },
    { id: 16, title: "Ginecologista" }, //
    { id: 17, title: "Hematologia" },

    { id: 97, title: "Hepatologia" },
    { id: 19, title: "Infectologia" },
    { id: 20, title: "Imunoalergologista" },

    { id: 21, title: "Médico da Família" }, //
    { id: 22, title: "Nefrologia" },
    { id: 23, title: "Neurocirurgia" },
    { id: 24, title: "Neurologista" }, //
    { id: 25, title: "Neurologista Infantil" },

    { id: 27, title: "Obstetra" },
    { id: 28, title: "Odontologia" },
    { id: 29, title: "Oftalmologista" }, //
    { id: 30, title: "Oftalmologista Infantil" },
    { id: 31, title: "Oncologista" }, //
    { id: 32, title: "Ortopedista" }, //
    { id: 33, title: "Otorrinolaringologista" }, //
    { id: 34, title: "Pediatra" }, //
    { id: 35, title: "Pilates" },
    { id: 36, title: "Pneumologista" }, //

    { id: 37, title: "Proctologista" },
    { id: 38, title: "Psicólogo" }, //

    { id: 39, title: "Psiquiatra" }, //
    { id: 40, title: "Reumatologista" }, //
    { id: 940, title: "Ressonância Magnética" }, //
    { id: 41, title: "Urologista" }, //
    { id: 42, title: "Terapia Ocupacional" }, //
    { id: 942, title: "Tomografia Computadorizada" }, //

  ]

  return (
    <>
      <Row>
        {result.map( ( item, index ) => (
          <ColumnOne key={index}>
            <CollapsibleOne>
              <TitleEspOne >
                {item.title}
              </TitleEspOne>
            </CollapsibleOne>
          </ ColumnOne>
        ) )}
        <ColumnOne>
          <CollapsibleOne>
            <TitleEspOnePointer color={colors.yellow2} onClick={() => setShow( !show )}>
              {show ? 'Ver menos -' : 'Ver mais +'}
            </TitleEspOnePointer>
          </CollapsibleOne>
        </ ColumnOne>

        {show && full.map( ( item, index ) => (
          <ColumnOne key={index}>
            <CollapsibleOne>
              <TitleEspOne >
                {item.title}
              </TitleEspOne>
            </CollapsibleOne>
          </ ColumnOne>
        ) )}
      </Row>
    </>
  )
}