import styled from 'styled-components';
import colors from '../../../../assets/styles/colors';

export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: ${props => props.background ? props.background : colors.blue6};
  border: none;
  border-radius: 50px;
  padding: 16px;
  font-family: inherit;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  margin-right: 10px;
  color: ${props => props.color ? props.color : 'white'};
  width: 35%;
  position: relative;
  display: inline-block;
  @media (min-width: 768px) {
    width: 40%;
    font-size: inherit;
  }
  :hover{
    opacity: 0.7;
  }
`