import React from 'react';

import {
  Leading,
  LeadingBigText,
  ButtonsDiv,
  CustomButton,
  PriceDivA,
  LeadingText,
  DivBottom,
  TextName,
  DivSelo,
  TextSelo
} from './banner.styles';

export default function Banner() {

  const sendWhatsApp = () => {
    window.open( `https://api.whatsapp.com/send?phone=5541998150123&text=Olá, gostaria de receber uma apresentação da Solumedi Franquias`, "_blank" )
  }

  return (
    <>
      <Leading>
        <LeadingBigText>INVISTA NA FRANQUIA QUE MAIS CRESCE EM TODO BRASIL</LeadingBigText>

        <PriceDivA>
          <LeadingText>Receba nossa apresentação</LeadingText>
        </PriceDivA>

        <ButtonsDiv>
          <CustomButton onClick={sendWhatsApp}>ENVIE UM WHATS</CustomButton>
        </ButtonsDiv>

        <DivBottom>

          <DivSelo>
            <TextSelo>
              Não precisa ser profissional da saúde para ser um franqueado!
            </TextSelo>
          </DivSelo>

          <TextName>
            Daniela Passos<br></br>
            <span style={{ fontWeight: "normal" }}>Franqueada</span>
          </TextName>

        </DivBottom>


      </Leading>
    </>
  );
}