function maskCep(value) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
  return value;
}

function maskCPF(value) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  return value;
}

function maskCNPJ(value) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  return value;
}

function maskPhone(value) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d)/g, "($1)$2");
  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
  return value;
}

function maskCard(value) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
  return value;
}

export { maskCep, maskCPF, maskPhone, maskCard, maskCNPJ };