import React, { useEffect, useState } from 'react';

import { Header } from '../../components/LpHeader/header';
import { Footer } from './LpFooterF/footer';

import LpBannerF from './LpBannerF/lpBanner';
import LpCampaign from '../../components/LpCampaign/campaign';
import LpCampaignB from '../../components/LpCampaignB/campaign';
import LpCampaignOftalmo from '../../components/LpCampaignOftalmo/campaign';
import LpMapa from '../../components/LpMapa/lpMapa';
import AgendeBoard from '../../components/AgendeBoard/agendeBoard';
import Testimonials from '../../components/Testimonials/testimonials';
import CarouselExames from '../../components/CarouselExames/carouselExames';

// import SetembroAmarelo from '../../components/SetembroAmarelo/setembroAmarelo';
// import OutubroRosa from '../../components/OutubroRosa/outubroRosa';
import BannerCampaign from '../../components/BannerCampaign/bannerCampaign';

export default function ModelE( { data } ) {

  const [ showLpCampaignStand, setShowLpCampaignStand ] = useState()
  const [ showLpCampaignOdonto, setShowLpCampaignOdonto ] = useState()
  const [ showLpCampaignOftalmo, setShowLpCampaignOftalmo ] = useState()
  const [ onMapa, setOnMapa ] = useState( true )
  const [ showPicarras, setShowPicarras ] = useState( false )
  const [ showSorocaba, setShowSorocaba ] = useState( false )
  const [ showOdonto, setShowOdonto ] = useState( false )


  useEffect( () => {
    if ( data.data.slug === "picarras" ) {
      setShowLpCampaignStand( true )
      setShowPicarras( true )
    }
    if ( data.data.slug === "picarras-odonto" ) {
      setShowLpCampaignOdonto( true )
      setShowPicarras( true )
    }
    if ( data.data.slug === "sorocaba-odonto" ) {
      setShowLpCampaignOdonto( true )
      setShowSorocaba( true )
    }
    if ( data.data.slug === "araucaria-oftalmologia" ) {
      setShowLpCampaignOftalmo( true )
      setShowOdonto( true )
    }
    if ( data.data.slug === "curitiba-centro-oftalmologia" ) {
      setShowLpCampaignOftalmo( true )
      setShowOdonto( true )
      setOnMapa( false )
    }
    if ( data.data.slug === "curitiba-cajuru-oftalmologia" ) {
      setShowLpCampaignOftalmo( true )
      setShowOdonto( true )
    }
  }, [ data ] )


  return (
    <>
      <Header email={data.data.email_adm} />
      {/* <BannerCampaign /> */}
      {showOdonto && <LpBannerF data={data} />}
      {showPicarras && <LpBannerF data={data} />}
      {showSorocaba && <LpBannerF data={data} />}
      {/* {showLpCampaignStand ? <LpCampaign data={data} /> : <></>} */}
      {showLpCampaignOdonto ? <LpCampaignB data={data} /> : <></>}
      {showLpCampaignOftalmo ? <LpCampaignOftalmo data={data} /> : <></>}
      {onMapa && <LpMapa data={data} />}
      <AgendeBoard data={data} infoPrice={"70"} />
      <Testimonials />
      <CarouselExames data={data} />
      <Footer data={data} />
    </>
  );
}