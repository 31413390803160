import React from 'react';
import { Link } from 'react-router-dom'
import {
  Content,
  Text,
  TextA,
  TextLink
} from './descEspecialidades.styles';

export default function ContentChild({ slug, desc, showDetails, onPressCloseDetails }) {

  return (
    <>
      {showDetails ?
        <>
          <Content onClick={onPressCloseDetails}>
            <Text>{desc}</Text>
            <Link to='/unidades/buscar' style={{ textDecoration: "none" }} >
              <TextA>AGENDAR</TextA>
            </Link>
            <Link to={`especialidades/${slug}`} style={{ textDecoration: "none" }} >
              <TextLink>
                Saiba mais +
              </TextLink>
            </Link>
          </Content>
        </>
        :
        <>
        </>
      }
    </>
  )
}