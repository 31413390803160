import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  Grid,
  Column,
  MaxContainer,
  DivImg,
  ContainerIframe,
  Container,
  Title,
  Description,
  DescriptionOne
} from './lpCardPromotion.styles';

import { CustomButton } from '../CustomButton/customButton';
import Imagem from '../../assets/image/s-bg.png';
import UrlImagem from '../../assets/image/atendimento.jpeg';

export default function LpMapa({ data, setShow }) {

  const history = useHistory()
  const [hasMapa, setHasMapa] = useState(false)
  const [showCadastrar, setShowCadastrar] = useState(false)

  useEffect(() => {
    if(data.enderecos[0].url){
      setHasMapa(data.enderecos[0].url)
    }
    if(
      data.data.slug === 'belem'
    ){
      setShowCadastrar(true)
    }

  }, [data])

  const seeLocal = () => {
    if (hasMapa !== false) {
      window.open(`${data.enderecos[0].url}`, "_blank")
    } else{
      alert(`Nosso endereço:
      ${data.enderecos[0].logradouro}, ${data.enderecos[0].numlogradouro}, ${data.enderecos[0].complemento ? ` ${data.enderecos[0].complemento},` : ""}${data.enderecos[0].bairro}, ${data.enderecos[0].cidade}, ${data.enderecos[0].uf}, CEP: ${data.enderecos[0].cep}
     `)
    }
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginBottom: "20px" }}>
        <Grid>
          <Column>
            <MaxContainer>
            <DivImg background={UrlImagem}/>
            </MaxContainer>
          </Column>

          <Column>
            <MaxContainer>
              <Container>
                <Title>CARTÃO DO USUÁRIO</Title>
                {/* <DescriptionOne>Peça já o seu e tenha todos os benefícios exclusivos oferecidos pela unidade Solumedi Belém</DescriptionOne> */}
                <DescriptionOne>Tenha todos os benefícios exclusivos oferecidos pela unidade Solumedi Belém</DescriptionOne>
                {/* <Description>Somente na Solumedi Belém você vai poder contar com as vantagens das nossas parcerias em diversos estabelecimentos, como farmácias e redes credenciadas, e além disso, adquirindo o seu cartão durante a pré-inauguração da unidade, você ganha nos três primeiros meses, na sua primeira consulta ou exame do mês, uma redução especial de 10% no valor do agendamento.</Description> */}
                {/* <Description>Somente na Solumedi Belém você vai poder contar com as vantagens em nossa rede credenciada, através do seu <strong>cartão de identificação</strong>, e além disso, adquirindo o seu cartão durante a pré-inauguração da unidade, você ganha nos três primeiros meses, na sua primeira consulta ou exame do mês, uma redução especial de 10% no valor do agendamento.</Description> */}
                {/* <Description>Faça agora o seu <strong>Cartão de Identificação</strong> por apenas <strong>R$9,90</strong> (taxa única), e ganhe nos três primeiros meses, na sua primeira consulta ou exame do mês, uma <strong>redução especial de 10%</strong> no valor do agendamento.</Description> */}
                <Description>Faça agora o seu <strong>Cartão de Identificação</strong> por apenas <strong>R$9,90</strong> (taxa única), e tenha o benefício de fazer suas consultas pelos seis primeiros meses, com uma <strong>redução especial de 5%</strong> no valor do agendamento.</Description>
                {/* <Description>Faça agora o seu <strong>Cartão Solumedi por apenas R$9,90</strong> clicando no botão abaixo.</Description> */}
                                
                 {showCadastrar ? 
                 <>
                <CustomButton
                  onClick={setShow}
                  margin={"10px 0px 10px 0px"}
                  width={"60%"}
                  color={"#2a4f72"}
                  background={"#fdd460"}
                  >EU QUERO</CustomButton>
                 </>
                :
                <>
                </>
              }
              </Container>
            </MaxContainer>
          </Column>
        </Grid>
      </Flex>
    </>
  );
}