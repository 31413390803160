import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import bannerB1 from '../../assets/image/bannerB1.jpg';
import bannerB2 from '../../assets/image/bannerB2.png';


export const BarGrid = styled.div`
  width: 100%;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const BarContainerImg = styled.div`
  grid-column: span 3;
  height: 400px;
  display: block;
  background: url(${bannerB1}) center no-repeat;
  backgroud-size: cover;
  @media (min-width: 768px) {
    grid-column: span 3;
    display: block;
  }
`

export const BarContainerArea = styled.div`
  grid-column: span 3;  
  height: 400px;
  display: block;
  background: url(${bannerB2}) left no-repeat;
  @media (min-width: 768px) {
    grid-column: span 3;
    display: block;
  }
`

export const Title = styled.p`
  word-break: break-word;
  font-size: 40px;
  font-weight: 700;
  color: white;
  margin: 25px 0px 0px 25px;
  @media (min-width: 768px) {
    margin: 30px 0px 15px 25px;
  }
`

export const SubTitle = styled.p`
  word-break: break-word;  
  font-size: 24px;
  font-weight: 300;
  color: white;
  margin: 5px 0px 0px 25px;
  @media (min-width: 768px) {
    margin: 5px 0px 15px 25px;
  }
`

export const Description = styled.p`
  word-break: break-word;   
  font-size: 14px;
  color: white;
  margin: 10px 15px 15px 25px;
`

export const Form = styled.div`
@media (min-width: 768px) {
  display: inline-flex;
  width: 100%
}
`

export const FormInput = styled.div`
@media (min-width: 768px) {
  display: inline-flex;
  width: 100%
}
`

export const FormButton = styled.div`
@media (min-width: 768px) {
  display: inline-flex;
  width: 30%
}
`

export const Input = styled.input`
  border: none;
  border-radius: 50px;
  padding: 1rem 3rem;
  margin-bottom: 5px;
  width: -webkit-fill-available;
  position: relative;
  display: inline-block;

  ::-webkit-input-placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  :-ms-input-placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  ::placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  @media (min-width: 768px) {
    max-width: -webkit-fill-available;
    margin: 0;
    border-radius: 50px 0px 0px 50px;
  }
`

export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: ${colors.yellow2};
  border: none;
  border-radius: 50px;
  padding: 1rem 3rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin: ${props => props.margin ? props.margin : '0' };
  color: ${colors.blue6};
  width: 100%;
  position: relative;
  display: inline-block;
  @media (min-width: 768px) {
    max-width: -webkit-fill-available;
    margin: 0;
    border-radius: 0px 50px 50px 0px;
  }
`