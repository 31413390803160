import React, { useEffect, useState } from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Section,
  Row,
  CustomButton,
  Title,
  Description,
  DescriptionA,
  BarGrid,
  BarCard,
  MainDiv,
  DivNumber,
  DivText,
  Icones,
  SubTitleText,
  MensagemText
} from './agendeBoard.styles';

import Clock from '../../assets/image/clock.png'
import DollarSuitCase from '../../assets/image/dollarSuitcase.png'
import Stethoscope from '../../assets/image/stethoscope.png'
import Medical from '../../assets/image/medical.png'
import Flask from '../../assets/image/flask.png'
import Heart from '../../assets/image/heart.png'

import { ContactWAForm } from '../ContactForm/contactWAForm';
import { requestForm } from '../../helpers/requestForm';

export default function HowTo({ data, infoPrice }) {

  const [showForm, setShowForm] = useState(false);
  const [valueTitle, setValueTitle] = useState("AGENDE HOJE MESMO")
  const [valueSubtitle, setValueSubtitle] = useState(`Consultas particulares com valores reduzidos`)

  const [textA, setTextA] = useState("Consultas Médicas e Odontológicas")
  const [textAa, setTextAa] = useState("Contamos com os melhores profissionais da área de saúde para você ser bem atendido com respeito e agilidade.")

  const [textB, setTextB] = useState("Exames de Imagem e Exames Laboratoriais")
  const [textBb, setTextBb] = useState("Soluções em tomografias, ressonâncias, radiografias, mamografia, ultrassom e vários outros exames, tudo realizado com segurança e para resultados altamente precisos.")

  const [textC, setTextC] = useState("Cirurgias e Atendimento Personalizado")
  const [textCc, setTextCc] = useState("Cirurgiões renomados para as mais diversas especialidades, com um atendimento ágil e personalizado, conforme a sua necessidade.")


  useEffect(() => {
    if (infoPrice === "70") {
      //setValueTitle( false )
    } else {
      var texto = `Consultas particulares com valores reduzidos a partir de R$${infoPrice}`
      setValueSubtitle(texto)
    }

    if (data) {
      if (data.data.slug === 'belem') {
        setValueTitle("SOLUMEDI BELÉM")
        setValueSubtitle("Consultas particulares com os melhores especialistas")
      }
      if (
        data.data.slug === 'jaraguadosul' ||
        data.data.slug === 'mogidascruzes'
      ) {
        setValueSubtitle("Consultas particulares com os melhores especialistas")
      }
      if (
        data.data.slug === 'ribeiraopreto' ||
        data.data.slug === 'ribeiraopreto-exames' ||
        data.data.slug === 'ribeiraopreto-v2'
      ) {
        setTextA("Consultas Médicas")
        setTextC("Atendimento Personalizado")
        setTextCc("Diversas especialidades, com um atendimento ágil e personalizado, conforme a sua necessidade.")
      }
      if (
        data.data.slug === 'criciuma' ||
        data.data.slug === "chapeco"
      ) {
        setValueSubtitle("Consultas particulares com valores reduzidos")
      }

      if (data.data.slug === 'salvador') {
        setValueSubtitle(`Consultas médicas particulares a partir de R$ ${infoPrice} e exames a partir de R$ 7`)
        setTextA('Adeus Filas e Espera')
        setTextAa('Agende online, em sua casa, sem precisar esperar meses por uma consulta no SUS. Evite filas demoradas e o desgaste de ir e voltar ao posto de saúde.')
        setTextB('Preços Acessíveis para Todos')
        setTextBb(`Consultas médicas a partir de R$${infoPrice} e exames a partir de R$7, muito mais baratos do que em relação ao preço do particular da clínica. Priorize sua saúde sem comprometer suas finanças`)
        setTextC('Ampla Variedade de Especialidades')
        setTextCc('Mais de 30 especialidades médicas disponíveis, desde cardiologia até pediatria. Encontre o atendimento especializado que você precisa, sem precisar esperar por meses no SUS.')

      }
      if (data.data.slug === 'saojosedoscampos' || data.data.slug === 'jacarei') {
        setValueTitle('')
        setValueSubtitle('')
      }

    }
  }, [infoPrice])

  const callWhatsApp = () => {

    if (data.data.slug !== 'mogidascruzes' && data.data.slug !== 'curitiba-centro') {
      window.open(`https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace(/[^\d]+/g, '')}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es.`)
    }

  }

  function handleClick(params) {
    setShowForm(false);
    if (!params?.accept) return
    // SEND REQUEST
    requestForm(params.telefone_whats)
    callWhatsApp();
  }

  return (
    <Section id="consultas">
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} Background={"#eaeaea"} style={{ padding: "25px 0px 25px 0px", marginTop: "30px", marginBottom: "30px" }} >
        <Title>{valueTitle}</Title>
        {data.data.slug === 'salvador'
          ? <DescriptionA>{valueSubtitle}</DescriptionA>
          : <Description>{valueSubtitle}</Description>
        }

        <BarGrid>
          <BarCard>
            <MainDiv>
              <DivNumber>
                <Icones src={data.data.slug === 'salvador' ? Clock : Medical} />
              </DivNumber>
              <DivText>
                <SubTitleText>
                  {textA}
                </SubTitleText>
                <MensagemText align={data.data.slug === 'salvador' ? 'none' : 'justify'}>
                  {textAa}
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <Icones src={data.data.slug === 'salvador' ? DollarSuitCase : Flask} />
              </DivNumber>
              <DivText>
                <SubTitleText>
                  {textB}
                </SubTitleText>
                <MensagemText align={data.data.slug === 'salvador' ? 'none' : 'justify'}>
                  {textBb}
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <Icones src={data.data.slug === 'salvador' ? Stethoscope : Heart} />
              </DivNumber>
              <DivText>
                <SubTitleText>
                  {textC}
                </SubTitleText>
                <MensagemText align={data.data.slug === 'salvador' ? 'none' : 'justify'}>
                  {textCc}
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

        </BarGrid>

        <Row style={{ justifyContent: 'center' }}>
          <CustomButton
            onClick={() => {
              if (data.data.slug === 'mossoro') {
                setShowForm(true)
              } else {
                callWhatsApp()
              }
            }}
            className='agendamento_via_whats'
            background={"#2a4f72"}
            width={"70%"}>AGENDAR</CustomButton>
        </Row>
      </Flex>

      {showForm && <ContactWAForm handleClick={handleClick} />}

    </Section>
  );
}