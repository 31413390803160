import React, { useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import api from '../../api';
import axios from 'axios';

import validaCpf from '../../helpers/validaCpf';
import { clearExpression } from '../../helpers/clearExpression';
import { maskCep, maskCPF, maskPhone } from '../../helpers/masks';

import { Flex } from '../../assets/styles/global';
import {
  Title,
  Description,
  Row,
  Column,
  Container,
  Form,
  FormInput,
  InputLabel,
  Input,
  Select,
  TextArea,
  InputCheckBox,
  DivLabel,
  TextLabel,
  TextLink
} from './form.styles'

import { CustomButton } from '../../components/CustomButton/customButton';
import { checkAge } from '../../helpers/checkAge';

export default function Formulario() {
  const { slug } = useParams();
  const history = useHistory()

  const [ lieAceito, setLieAceito ] = useState( false )
  const [ nome, setNome ] = useState( "" )
  const [ cpf, setcpf ] = useState( "" )
  const [ born, setBorn ] = useState( "" )

  const [ email, setEmail ] = useState( "" )
  const [ telefone, setTelefone ] = useState( "" )

  const [ cep, setcep ] = useState( "" )
  const [ cepNotFound, setcepNotFound ] = useState( false )
  const [ address, setAddress ] = useState( "" )
  const [ address_complement, setAddress_complement ] = useState( "" )
  const [ address_district, setAddress_district ] = useState( "" )
  const [ address_city, setAddress_city ] = useState( "" )
  const [ address_uf, setAddress_uf ] = useState( "" )

  const enviar = async () => {
    if ( lieAceito === false ) {
      alert( "Você primeiro precisa aceitar os Termos de Uso para o envio" )
      return
    }

    if (
      nome === "" ||
      cpf === "" ||
      born === "" ||
      telefone === "" ||
      address_uf === "0"
    ) {
      alert( "Por favor, preencha os campos obrigatórios para o envio" )
      return
    }

    var strOk = checkStr( nome )
    if ( !strOk ) return

    var cpfOK = checkCPF()
    if ( !cpfOK ) return

    var bornOK = checkBorn()
    if ( !bornOK ) return


    var data = {
      author_name: nome,
      author_email: email,
      content:
        `Cadastrado na Unidade ${slug} - 
          ${nome}
          ${cpf}
          ${born}
          ${email}
          ${telefone}
          ${cep}
          ${address}
          ${address_complement}
          ${address_district}
          ${address_city} - ${address_uf}`
    }

    try {
      await api.post( `wp/v2/comments?post=3646`, data )
      alert( "Seus dados foram recebidos com sucesso!" )
      history.push( '/' )
    } catch ( err ) {
      console.log( "FAIL", err )
    }
  }


  const consultaCEP = async () => {
    let clearCep = cep.replace( /\.|\-/g, '' )
    var url = "https://viacep.com.br/ws/" + clearCep + "/json";
    var response = await axios.get( url )
    if ( response.status === 200 ) {
      if ( response.data.erro === true ) {
        setcepNotFound( true )
      } else {
        setcepNotFound( false )
        setAddress( response.data.logradouro )
        setAddress_district( response.data.bairro )
        setAddress_city( response.data.localidade )
        setAddress_uf( response.data.uf )
      }
      console.log( response.data.logradouro )
    } else {
      setcepNotFound( false )
    }
  }


  const checkStr = ( str ) => {
    let letters = clearExpression( str )
    if ( str.length !== letters.length ) {
      alert( "Não é possível utilizar números ou caracteres especiais no campo nome" )
      return false
    }
    return true
  }


  const checkCPF = () => {
    if ( !validaCpf( cpf ) ) {
      alert( `CPF digitado ${cpf} inválido, por favor, confira os dados informados!` )
      return false
    } else {
      return true
    }
  }


  const checkBorn = ( params ) => {
    if ( !params ) {
      alert( "Por favor, verifique a data de nascimento informada" )
      return false
    }
    var idade = checkAge( params )
    if ( idade > 150 ) {
      alert( "Por favor, verifique a data selecionada" )
      return false
    }
    if ( idade < 18 ) {
      alert( "Para cadastro de menores de idade, por favor, entre em contato com a unidade Solumedi mais próxima" )
      return false
    }
    return true
  }


  function titleize( text ) {
    var words = text.toLowerCase().split( " " );
    for ( var a = 0; a < words.length; a++ ) {
      var w = words[ a ];
      words[ a ] = w[ 0 ].toUpperCase() + w.slice( 1 );
    }
    return words.join( " " );
  }


  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginTop: "20px", marginBottom: "20px" }} >
        <Title>FORMULÁRIO DE CADASTRO</Title>
        <Description>Obrigado por escolher a Solumedi!</Description>
      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
        <Row>
          <Column>
            <Container>
              <Form>
                <FormInput>
                  <InputLabel>Nome*</InputLabel>
                  <Input
                    type="text"
                    id="nome"
                    name="nome"
                    placeholder="Nome completo"
                    maxLength={150}
                    value={nome}
                    onChange={( e ) => setNome( e.target.value )}
                    onBlur={( e ) => checkStr( e.target.value )}
                  />

                  <InputLabel>CPF*</InputLabel>
                  <Input
                    type="cpf"
                    id="cpf"
                    name="cpf"
                    placeholder="CPF"
                    maxLength={14}
                    value={cpf}
                    keyboardType='numeric'
                    pattern="[0-9]*"
                    onChange={( e ) => setcpf( maskCPF( e.target.value ) )}
                    onBlur={checkCPF}
                  />

                  <InputLabel>Data de Nascimento*</InputLabel>
                  <Input
                    type="date"
                    id="born"
                    name="born"
                    value={born}
                    onChange={( e ) => setBorn( e.target.value )}
                    onBlur={( e ) => checkBorn( e.target.value )}
                  />

                  <InputLabel>E-mail</InputLabel>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-mail"
                    maxLength={150}
                    value={email}
                    onChange={( e ) => setEmail( e.target.value )}
                  />

                  <InputLabel>Telefone*</InputLabel>
                  <Input
                    type="phone"
                    id="telefone"
                    name="telefone"
                    placeholder="Telefone ou WhatsApp"
                    maxLength={14}
                    value={telefone}
                    keyboardType='numeric'
                    pattern="[0-9]*"
                    onChange={( e ) => setTelefone( maskPhone( e.target.value ) )}
                  />

                  <InputLabel>CEP</InputLabel>
                  <Input
                    type="cep"
                    id="cep"
                    name="cep"
                    maxLength={10}
                    value={cep}
                    placeholder="CEP"
                    keyboardType='numeric'
                    pattern="[0-9]*"
                    onChange={( e ) => setcep( maskCep( e.target.value ) )}
                    onBlur={consultaCEP}
                  />
                  {cepNotFound && ( <InputLabel style={{ color: "red", fontSize: "12px" }}>CEP não encontrado!</InputLabel> )}

                  <InputLabel>Endereço</InputLabel>
                  <Input
                    type="address"
                    id="address"
                    name="address"
                    maxLength={150}
                    value={address}
                    placeholder="Rua, Avenida..."
                    onChange={( e ) => setAddress( e.target.value )}
                  />

                  <InputLabel>Número</InputLabel>
                  <Input
                    type="address_complement"
                    id="address_complement"
                    name="address_complement"
                    maxLength={150}
                    value={address_complement}
                    placeholder="Número e complemento"
                    onChange={( e ) => setAddress_complement( e.target.value )}
                  />

                  <InputLabel>Bairro</InputLabel>
                  <Input
                    type="address_district"
                    id="address_district"
                    name="address_district"
                    maxLength={150}
                    value={address_district}
                    placeholder="Cidade"
                    onChange={( e ) => setAddress_district( e.target.value )}
                  />

                  <InputLabel>Cidade</InputLabel>
                  <Input
                    type="address_city"
                    id="address_city"
                    name="address_city"
                    maxLength={150}
                    value={address_city}
                    placeholder="Cidade"
                    onChange={( e ) => setAddress_city( e.target.value )}
                  />

                  <InputLabel>Estado*</InputLabel>
                  <Select
                    onChange={( e ) => setAddress_uf( e.target.value )}
                    value={address_uf}
                  >
                    <option value="0">Selecione</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </Select>

                  <DivLabel>
                    <InputCheckBox
                      type="checkbox"
                      id="lieAceito"
                      name="lieAceito"
                      onChange={() => setLieAceito( !lieAceito )}
                    />
                    <TextLabel>Li e aceito os
                      <span>
                        <TextLink
                          href="/politica-de-privacidade"
                          style={{ textDecoration: "none" }}
                        >
                          &nbsp;Termos de Uso&nbsp;
                        </TextLink>
                      </span>
                      deste site.</TextLabel>
                  </DivLabel>

                  <CustomButton
                    onClick={enviar}
                    margin={"20px 0px 0px 0px"}
                  >CADASTRAR</CustomButton>
                </FormInput>
              </Form>

            </Container>
          </Column>
        </Row>
      </Flex>
    </>
  );
}