import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Flex,
  BarGrid,
  BarContainerTitle,
  Title,
  BarContainerInput,
  CButton
} from './cookiesNotification.styles';

export function CookiesNotification() {

  const [ accept, setAccept ] = useState( false )

  const infoConsent = `(
    gtag( 'consent', 'update', {
      'ad_storage': 'granted',
      'analytics_storage': 'granted',
      'functionality_storage': 'granted',
      'security_storage': 'granted',
      'personalization_storage': 'granted'
    } )
  )`

  useEffect( () => {
    checkTermsOfUse()
  }, [] );

  function checkTermsOfUse() {
    let getLocalStorage = window.sessionStorage.getItem( 'consentMode' )
    if ( !getLocalStorage || getLocalStorage == null ) {
      return
    } else {
      setAccept( true )
    }
  };

  function acceptTermsOfUse() {
    const consent = {
      'ad_storage': 'granted',
      'analytics_storage': 'granted',
      'functionality_storage': 'granted',
      'security_storage': 'granted',
      'personalization_storage': 'granted'
    }
    window.sessionStorage.setItem( 'consentMode', JSON.stringify( consent ) )

    const consentModeScript = document.createElement( "script" );
    document.body.appendChild( consentModeScript );
    consentModeScript.innerHTML = infoConsent;

    setAccept( !accept )
  };

  return (
    <>
      <Flex style={{ display: accept ? "none" : "flex" }}>
        <BarGrid>
          <BarContainerTitle>
            <Title>
              Este site usa cookies para melhorar sua experiência. Ao clicar em "Aceitar" você concorda com o uso dos cookies, termos e políticas do site.
              <span>
                <Link to='/politica-de-privacidade' style={{ textDecoration: "none", color: "#007bff" }}>
                  &nbsp;Leia Mais
                </Link>
              </span>
            </Title>
          </BarContainerTitle>

          <BarContainerInput>
            <CButton onClick={acceptTermsOfUse}>Aceitar</CButton>
          </BarContainerInput>
        </BarGrid>
      </Flex>
    </>
  );
}