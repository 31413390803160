import React from 'react';
import { Flex } from '../../assets/styles/global';
import {
  Title,
  Description,
  Observacoes
} from './lpSpecialtiesCollapse.styles';
import ContentChildFull from './lpListEspecialidadesFull';


export default function LpSpecialtiesCollapse( { data } ) {


  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>PRINCIPAIS ESPECIALIDADES</Title>
        <Description>Atendimento realizado nas melhores clínicas e por excelentes profissionais</Description>
      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "30px" }}>
        <ContentChildFull />

        <Observacoes>
          <span>
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=55${data.data.telefone_whats}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es.`}
              style={{ textDecoration: "none", color: "#007bff" }}
            > Consulte </a>
          </span>
          outras especialidades e disponibilidade de agenda em nosso atendimento.
        </Observacoes>
      </Flex>
    </>
  )
}