import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import banner from '../../assets/image/home-page-bg-banner.jpg';


export const Leading = styled.section`
  flex-direction: column;
  height: 270px;
  padding: 10px 40px 30px 20px;
  background: url(${banner}) center #2A4F72 no-repeat;
  background-size: cover;
  color: white;
  text-shadow: 0px 0px 5px #000;
  /* background-attachment: fixed; */
    @media (min-width: 700px) {
      padding: 0px 30px 30px 30px;
      height: 700px;
      display: flex;
      align-items: center;
      background: url(${banner}) top #2A4F72 no-repeat;
      /* background-attachment: fixed; */
   }
`

export const LeadingBigText = styled.p`
  /* margin-right: 35%; */
  margin: 0% 35% 0% 0%;
  font-weight: bold;
  font-size: 7vw;
  @media (min-width: 700px){
    margin: 3% 50% 0% 0%;
    font-size: 6vw;
  } 
  @media (min-width: 1500px){
    margin: 1% 50% 0% 0%;
    font-size: 4vw;
  }
  
`

export const ButtonsDiv = styled.div`
  /* position: relative; */
  width: 210px;
  @media (min-width: 700px) {
    width: 240px;
    align-self: start;
    margin: 1% 0% 1% 20%; 
  }
`

export const CustomButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: ${colors.blue6 };
  border: none;
  border-radius: 50px;
  padding: 10px;
  font-size: 10;
  font-weight: 900;
  margin: 10px 0px 0px 0px;
  color: white;
  width: 60%;
  position: relative;
  display: inline-block;
  :hover{
    opacity: 0.7;
  }
  @media (min-width: 700px) {
    padding: 20px;
    font-size: 18;
  }
`

export const PriceDivA = styled.div`
  /* position: relative; */
  width: 60%;
  @media (min-width: 700px) {
    width: 50%;
    align-self: start;
  }
` 

export const LeadingTextPriceA = styled.p`
  color: ${colors.yellow2};
  font-weight: bold;
  font-size: 6vw;
  margin: 1% 25% 0% 0%;
  @media (min-width: 700px) {
    margin: 1% 5% 0% 0%;
    font-size: 4vw;
  }
`

export const LeadingTextPriceB = styled.p`
  color: white;
  font-weight: bold;
  font-size: 4vw;
  margin: 0% 0% 0% 0%;
  @media (min-width: 700px) {
    margin: 0% 0% 0% 0%;
    font-size: 3vw;
  }
`

export const PriceDivB = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  @media (min-width: 414px) {
    display: none;
  }
  @media (min-width: 700px) {
    display: flex;
    align-self: start;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    width: 50%;
  }
` 

export const Row = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
`

export const LeadingTextPriceC = styled.p`
  color: white;
  font-weight: bold;
  font-size: 5vw;
  margin: 3% 0% 0% 10%;
  @media (min-width: 700px) {
    margin: 0% 0% 0% 0%;
    font-size: 4vw;
  }
  @media (min-width: 1500px) {
    margin: 0% 0% 0% 0%;
    font-size: 3vw;
  }
`

export const LeadingTextPriceD = styled.p`
  color: white;
  font-size: 3vw;
  margin: 0% 0% 0% 10%;
  @media (min-width: 700px) {
    margin: 5% 2% 0% 0%;
    font-size: 2vw;
  }
  @media (min-width: 1500px) {
    margin: 2% 2% 0% 0%;
  }
  
`

export const LeadingPrice = styled.p`
  color: ${colors.yellow2};
  margin: -5px 0px 0px 7px;
  font-family: 'Montserrat', sans-serif;
  font-size: 9vw;
  @media (min-width: 700px) {
    margin: -5px 0px 0px 0px;
    font-size: 10vw;
  }
  @media (min-width: 1500px) {
    margin: -3% 0% 0% 0%;
    font-size: 9vw;
  }

`