import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Title = styled.h2`
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 70px 0px 0px 0px;
  }
`

export const Description = styled.p`
  font-size: 12px;
  color: ${colors.gray2};
  margin: 5px 0px 20px 0px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 10px 0px 30px 0px;
  }
`

export const Row = styled.div`
  width: 90%;
  display: flex;
  flex-flow: row wrap;
`

export const Column = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 33%;
  }
`

export const Collapsible = styled.div`
  background-color: ${colors.gray0};
  border-radius: 50px;
  padding: 20px;
  margin: 20px; 
  text-align: center;
  outline: none;
  font-size: 21px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  transition: all 0.2s ease-in;
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
  }
`

export const TitleEsp = styled.h3`
  color: ${colors.blue6};
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;
  margin: 0px;
`

export const TitleLast = styled.h3`
  text-align: center;
  color: ${colors.red1};
  font-weight: 700;
  font-size: 18px;
  margin: 20px 5px 0px 5px;
  @media (min-width: 768px) {
    margin: 20px 0px 0px 0px;
  }
`
