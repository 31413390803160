import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: absolute;
  right: -100%;
  width: 100%;
  height: 100%;
   
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0s;
  animation: slide 0.5s forwards;
  animation-delay: 0s;
    
  @-webkit-keyframes slide {
    100% { right: 0; }
  }
  
  @keyframes slide {
    100% { right: 0; }
  }
`

export const MySlides = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 400px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const BigImg = styled.img`
  border-radius: 50%;
  vertical-align: middle;
  width: 140px;
  height: 140px;
  @media (min-width: 768px) {
    width: 210px;
    height: 210px;
  }
`

export const ButtonPrev = styled.a`
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: ${colors.blue4};
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  :hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`

export const ButtonNext = styled.a`
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: ${colors.blue4};
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
  right: 0;
  border-radius: 3px 0 0 3px;
  :hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`

export const CaptionContainer = styled.div`
  text-align: center;
  padding: 2px 15px;
  color: ${colors.blue5};
`

export const Caption = styled.h3`
  margin-bottom: 0px;
  font-size: 18px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`

export const TextTitle = styled.p`
  margin-top: 0px;
  color: ${colors.gray2};
  font-size: 10px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
`

export const Text = styled.p`
  color: ${colors.gray2};
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`