import React from 'react';

import {
  Leading,
  LeadingBigText
} from './banner.styles';

export default function Banner() {
  return (
    <>
      <Leading id="banner">
        <LeadingBigText>FAQ</LeadingBigText>
      </Leading>
    </>
  );
}