import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerTitle,
  Title,
  SubTitle,
  BarContainerInput,
  Form,
  FormInput,
  FormButton,
  CButton
} from './unidadesBar.styles';

import Autocomplete from './autocomplete';

export default function UnidadesBar({ callCheckCity }) {

  const history = useHistory()
  const [dataValue, setDataValue] = useState("")

  const buscar = () => {
    if(dataValue === undefined || dataValue === null || dataValue === "") return
    if (!callCheckCity) {
      history.push(`/unidades/${dataValue}`)
    } else {
      callCheckCity(dataValue)
    }
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} Background={"#2a4f72"}>
        <BarGrid>
          <BarContainerTitle>
            <Title>NOSSAS UNIDADES PERTO DE VOCÊ!</Title>
            <SubTitle>Encontre a Solumedi mais próxima para ser atendido</SubTitle>
          </BarContainerTitle>
          <BarContainerInput>
            <Form>
              <FormInput>
                <Autocomplete currentValue={setDataValue} />
              </FormInput>
              <FormButton>
                <CButton onClick={buscar}>Buscar</CButton>
              </FormButton>
            </Form>
          </BarContainerInput>
        </BarGrid>
      </Flex>
    </>
  );
}