import styled from 'styled-components';
import colors from '../../../assets/styles/colors';

//SEE STICKY ELEMENT POSITION

export const Topbar = styled.div`
  height: 30px;
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.blue3};
  @media (max-width: 768px) {
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    max-height: ${({ isOpen }) => (isOpen ? "30px" : "0")};
    transition: max-height 0.3s ease-in;
  }
`;

export const TopDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TopLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 12px;
  cursor: pointer;
`

export const TopLink2 = styled.a`
  text-decoration: none;
  color: white;
  font-size: 12px;
`

export const Nav = styled.div`
  padding: 0.5rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  @media (max-width: 768px) {
    padding: 0.5rem 2rem;
  }

`;

export const Logo = styled.img`
  width: 160px;
  margin: 5px 0px 7px 0px;
`