import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Title = styled.h2`
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 70px 0px 0px 0px;
  }
`

export const Description = styled.p`
  text-align: center;
  font-size: 12px;
  color: ${colors.gray2};
  margin: 5px 0px 20px 0px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 10px 0px 30px 0px;
  }
`

export const Row = styled.div`
  width: 90%;
  display: flex;
  flex-flow: row wrap;
`

export const Column = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
`

export const ColumnOne = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 25%;
  }
`

export const Collapsible = styled.div`
  background-color: ${colors.blue5};
  border: solid;
  border-color: white;
  border-width: 0px 5px 1px 5px;
  padding: 50px 10px 20px 10px;
  margin: 0px; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  outline: none;
`

export const CollapsibleOne = styled.div`
  background-color: ${colors.blue5};
  border: solid;
  border-color: white;
  border-width: 0px 5px 1px 5px;
  padding: 20px 10px 20px 10px;
  margin: 0px; 
  outline: none;
`

export const TitleEsp = styled.h3`
  word-break: break-word;
  color: white;
  font-size: 16px;
  font-weight: 300;
  margin: 0px;
  @media (min-width: 768px) {
    font-size: 24px;
    word-break: break-all;
  }
`

export const TitleEspOne = styled.h3`
  color: white;
  font-size: 16px;
  font-weight: 300;
  margin: 0px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`

export const TitleLast = styled.h3`
  word-break: break-all;
  color: ${colors.yellow2};
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  margin: 0px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
  :hover{
    opacity: 0.7;
  }
`

export const TitlePrice = styled.h3`
  color: ${colors.yellow2};
  font-size: 18px;
  font-weight: 900;
  margin: 0px;
  @media (min-width: 768px) {
    font-size: 25px;
  }
`

export const Observacoes = styled.p`
  text-align: justify;
  color: ${colors.gray1};
  font-size: 12px;
  margin: 20px 20px 0px 20px;
  @media (min-width: 768px) {
    margin: 20px 0px 0px 0px;
    font-size: 16px;
  }
`