import React, { useEffect, useState } from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Title,
  Subtitle,
  Description,
  Row,
  Column,
  Collapsible,
  TitleEsp,
  TitlePrice,
  TitleComments,
  DivTitle,
  DivPrice,
  TitleLast,
  Observacoes
} from './lpPriceBoardGroupCard.styles';

import ContentChild from './lpListEspecialidades';
import ListMore from './lpListMore';
import priceListSaojosedoscampos from '../../content/priceListSaojosedoscampos.json';
import priceListJacarei from '../../content/priceListJacarei.json';

const LpPriceBoardGroupCard = ( { data } ) => {

  const [ showList, setShowList ] = useState( true )
  const [ showMoreList, setShowMoreList ] = useState( true )
  const [ showMoreListButton, setShowMoreListButton ] = useState( true )
  const [ result, setResult ] = useState()
  const [ parcelamento, setParcelamento ] = useState( "" )
  const [ aPartirDe, setaPartirDe ] = useState( false )

  useEffect( () => {
    if ( data.data.slug === "saojosedoscampos" ) {
      let orderList = priceListSaojosedoscampos.sort( ( a, b ) => ( a.titulo > b.titulo ) ? 1 : ( ( b.titulo > a.titulo ) ? -1 : 0 ) )
      orderList = orderList.sort( ( a, b ) => ( a.group > b.group ) ? 1 : ( ( b.group > a.group ) ? -1 : 0 ) )
      let groups = orderList.reduce( function ( results, item ) {
        ( results[ item?.group ] = results[ item?.group ] || [] ).push(
          item
        );
        return results;
      }, {} )
      setResult( groups );
      setShowMoreListButton( false )
    }

    if ( data.data.slug === "jacarei" ) {
      let orderList = priceListJacarei.sort( ( a, b ) => ( a.titulo > b.titulo ) ? 1 : ( ( b.titulo > a.titulo ) ? -1 : 0 ) )
      orderList = orderList.sort( ( a, b ) => ( a.group > b.group ) ? 1 : ( ( b.group > a.group ) ? -1 : 0 ) )
      let groups = orderList.reduce( function ( results, item ) {
        ( results[ item?.group ] = results[ item?.group ] || [] ).push(
          item
        );
        return results;
      }, {} )
      setResult( groups );
      setShowMoreListButton( false )
    }

    setTimeout( () => {
      setShowList( false )
      setShowMoreList( false )
    }, 1000 );

  }, [ data ] )

  const callWhatsApp = () => {
    window.open( `https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace( /[^\d]+/g, '' )}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es.` )
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>VALORES ACESSÍVEIS</Title>
        <Description>Preços* especiais praticados por {data.data.nomefantasia} <strong>{parcelamento}</strong></Description>
      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "30px" }}>
        <Row>

          {result &&
            <>
              {
                Object.entries( result ).map( ( x, y ) => (
                  <React.Fragment key={y}>
                    <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
                      <Subtitle>{x[ 0 ]}</Subtitle>
                    </Flex>
                    {
                      x[ 1 ].map( ( item, index ) => (
                        <Column key={index}>
                          <Collapsible>
                            <DivTitle>

                              <TitleEsp>
                                {item.titulo}
                                {item.preco === "0,00" ? <></> :
                                  <>
                                    {aPartirDe ?
                                      <>
                                        <span style={{ fontSize: "14px", fontWeight: "150", color: "white" }}> a partir de:</span>
                                      </>
                                      :
                                      <></>
                                    }
                                  </>
                                }
                              </TitleEsp>

                              <TitleComments>{item?.comments}</TitleComments>

                            </DivTitle>
                            <DivPrice onClick={() => callWhatsApp()}>
                              {item.preco === "0,00"
                                ?
                                <TitlePrice>
                                  GRÁTIS
                                </TitlePrice>
                                :
                                <TitlePrice>
                                  <span style={{ fontSize: "14px", fontWeight: "300" }}>R$</span>
                                  {item.preco}
                                  <small style={{ fontWeight: "normal" }}>*</small>
                                </TitlePrice>
                              }
                            </DivPrice>
                          </Collapsible>
                        </Column>
                      ) )
                    }
                  </React.Fragment>
                ) )
              }
            </>
          }


          {showMoreListButton ?
            <Column>
              <Collapsible>
                <TitleLast onClick={() => setShowList( !showList )}>
                  {showList ? "Lista " : "Ver lista "} de especialidades atendidas <strong>{showList ? "-" : "+"}</strong>
                </TitleLast>
              </Collapsible>
            </Column>
            :
            <Column>
              <Collapsible>
                <TitleEsp onClick={() => callWhatsApp()}>
                  E muito mais. Entre em contato conosco.
                </TitleEsp>
              </Collapsible>
            </Column>
          }
        </Row>
        {showList && <ContentChild data={data} />}

        {showList && data.data.slug === "pontagrossa" &&
          <>
            <Row>
              <Column>
                <Collapsible>
                  <TitleLast onClick={() => setShowMoreList( !showMoreList )}>
                    {showMoreList ? "Outras " : "Ver outras "}especialidades <strong>{showMoreList ? "-" : "+"}</strong>
                  </TitleLast>
                </Collapsible>
              </Column>
            </Row>
            {showMoreList && <ListMore />}
          </>
        }

        <Observacoes>
          * Valores a partir de e sujeitos a alteração sem prévio aviso.
          <span>
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=55${data.data.telefone_whats}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es.`}
              style={{ textDecoration: "none", color: "#007bff" }}
            > Consulte </a>
          </span>
          outras especialidades e disponibilidade de agenda em nosso atendimento.
        </Observacoes>

      </Flex>
    </>
  )
}

export { LpPriceBoardGroupCard }