import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  Grid,
  Column,
  MaxContainer,
  ContainerIframe,
  Container,
  Title,
  Description
} from './lpMapa.styles';

import { CustomButton } from '../CustomButton/customButton';

export default function LpMapa( { data, setShow } ) {

  const history = useHistory()
  const [ hasMapa, setHasMapa ] = useState( false )
  const [ showCadastrar, setShowCadastrar ] = useState( false )
  const [ title, setTitle ] = useState( 'VENHA PARA A SOLUMEDI' )
  const [ text, setText ] = useState( 'A Solumedi surgiu da ideia de conectar clínicas particulares com pessoas que buscam atendimento médico de qualidade. Não realizamos nenhum tipo de cobrança recorrente e o usuário efetua somente o pagamento do agendamento. O que faz com que a Solumedi tenha o melhor CUSTO x BENEFÍCIO no ramo da saúde.' )

  useEffect( () => {
    if ( data.enderecos[ 0 ].url ) {
      setHasMapa( data.enderecos[ 0 ].url )
    }
    if (
      data.data.slug === 'belem'
    ) {
      setShowCadastrar( true )
    }

    if ( data.data.slug === 'salvador' ) {
      setTitle( 'Saúde com Economia!' )
      setText( 'Chega de filas e esperas! Agende consultas e exames online, em +30 clínicas, com preços baixos e parcelamento em até 10x sem juros. Na Solumedi você cuida da sua saúde e ainda economiza. Agende Agora!' )
    }

  }, [ data ] )

  const seeLocal = () => {
    if ( hasMapa !== false ) {
      window.open( `${data.enderecos[ 0 ].url}`, "_blank" )
    } else {
      alert( `Nosso endereço:
      ${data.enderecos[ 0 ].logradouro}, ${data.enderecos[ 0 ].numlogradouro}, ${data.enderecos[ 0 ].complemento ? ` ${data.enderecos[ 0 ].complemento},` : ""}${data.enderecos[ 0 ].bairro}, ${data.enderecos[ 0 ].cidade}, ${data.enderecos[ 0 ].uf}, CEP: ${data.enderecos[ 0 ].cep}
     `)
    }
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Grid>
          <Column>
            <MaxContainer>
              <ContainerIframe src={hasMapa}
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></ContainerIframe>
            </MaxContainer>
          </Column>

          <Column>
            <MaxContainer>
              <Container>
                <Title>{title}</Title>
                <Description>{text}</Description>
                <CustomButton
                  onClick={seeLocal}
                  margin={"10px 0px 10px 0px"}
                  width={"60%"}>LOCALIZAÇÃO</CustomButton>

                {showCadastrar ?
                  <>
                    <CustomButton
                      onClick={setShow}
                      margin={"10px 0px 10px 0px"}
                      width={"60%"}
                      color={"#2a4f72"}
                      background={"#fdd460"}
                    >CADASTRO GRATUITO</CustomButton>
                  </>
                  :
                  <>
                  </>
                }
              </Container>
            </MaxContainer>
          </Column>
        </Grid>
      </Flex>
    </>
  );
}