import React from 'react';

import {
  Content,
  Text
} from './faqAnswer.styles';

export default function ContentChild({ desc, showDetails }) {

  return (
    <>
      {showDetails ?
        <>
          <Content>
            <Text>{desc}</Text>
          </Content>
        </>
        :
        <>
        </>
      }
    </>
  )
}