import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Flex } from "../../assets/styles/global";
import {
  Bottom,
  BottomText,
  ContainerArea,
  Description,
  TextA,
  TextALink,
  TextB,
  BarGrid,
  BarCard,
  Card,
  Container,
  LogoABF,
  LogoSocial,
} from "./footer.styles";
import Solumedi from "../../assets/image/s.png";
import ABF from "../../assets/image/abf2.png";
import FaceBook from "../../assets/image/facebook.png";
import Instagram from "../../assets/image/instagram.png";
import YouTube from "../../assets/image/youtube.png";

import UnidadesModal from "../unidadesModal/modalForm";

export function Footer( { show } ) {

  const [ unidadesModal, setUnidadesModal ] = useState( false );

  return (
    <>
      {show && (
        <>
          {unidadesModal && <UnidadesModal setUnidadesModal={setUnidadesModal} />}
          <Flex
            AlignItems={"center"}
            JustifyContent={"center"}
            FlexDirection={"row"}
          >
            <ContainerArea>
              <BarGrid>
                <BarCard>
                  <Card url={Solumedi}></Card>
                </BarCard>

                <BarCard>
                  <Card>
                    <Container>
                      <Description>Empresa Associada ABF</Description>
                      <LogoABF src={ABF} alt="Logo_ABF" />
                      <TextA
                        href="https://app.centraldofranqueado.com.br/login"
                        rel="noreferrer"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        CENTRAL DO FRANQUEADO
                      </TextA>

                      <TextA
                        href="https://sol.uex.io/"
                        rel="noreferrer"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        PORTAL SOL 2.0
                      </TextA>

                      <TextA
                        href="https://webmail-seguro.com.br/solumedi.com.br/"
                        rel="noreferrer"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        WEBMAIL
                      </TextA>

                      <Link to="/faq" style={{ textDecoration: "none" }}>
                        <TextALink>FAQ</TextALink>
                      </Link>

                      <Link
                        onClick={() => setUnidadesModal( !unidadesModal )}
                        style={{ textDecoration: "none" }}
                      >
                        <TextALink>POLÍTICA DE PRIVACIDADE</TextALink>
                      </Link>

                      <TextA
                        href="/seja-franqueado"
                        //href="https://solumedifranquia.com.br"
                        rel="noreferrer"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        SEJA UM FRANQUEADO
                      </TextA>

                    </Container>
                  </Card>
                </BarCard>

                <BarCard>
                  <Card>
                    <Container>
                      <Description>SIGA NOSSAS REDES</Description>

                      <a
                        href="https://web.facebook.com/SolumediOficial/"
                        rel="noreferrer"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <LogoSocial src={FaceBook} alt="Logo_Facebook" />
                      </a>
                      <a
                        href="https://www.instagram.com/solumedioficial/"
                        rel="noreferrer"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <LogoSocial src={Instagram} alt="Logo_Instagram" />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCMSBIH64rB1OfRkWAGBbODA"
                        rel="noreferrer"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <LogoSocial src={YouTube} alt="Logo_YouTube" />
                      </a>

                      <span itemProp="telephone">
                        <TextA
                          href="tel:40036534"
                          style={{ textDecoration: "none" }}
                        >
                          4003-6534
                        </TextA>
                      </span>
                      <TextA
                        href="mailto:sac@solumedi.com.br"
                        style={{ textDecoration: "none" }}
                      >
                        sac@solumedi.com.br
                      </TextA>
                      <TextB>*Não somos plano de saúde</TextB>
                    </Container>
                  </Card>
                </BarCard>
              </BarGrid>
            </ContainerArea>
          </Flex>

          <Bottom>
            <BottomText>&copy; 2024 | Desenvolvido por Solumedi</BottomText>
          </Bottom>
        </>
      )}
    </>
  );
}
