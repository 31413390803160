import React from 'react';

import { Flex } from '../../../assets/styles/global';

import {
  TitleA,
  TitleB,
  Description,
  BarGrid,
  BarCard,
  MainDiv,
  DivText,
  Icones,
  SubTitleText,
  MensagemText,
} from './bannerA.styles';

import MapBrazil from '../../../assets/image/mapBrazil.png'
import PlusProfit from '../../../assets/image/plusProfit.png'
import Software from '../../../assets/image/software.png'
import NewProcess from '../../../assets/image/newProcess.png'

export default function BannerA() {

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} Background={"#eaeaea"} >
        <TitleA>
          Crescer trabalhando com o melhor sistema de saúde
          <span style={{ color: "#2a4f72", fontWeight: "900" }}>
            &nbsp;Solumedi
          </span>
        </TitleA>

        <TitleB>
          VANTAGENS E BENEFÍCIOS
        </TitleB>
        <Description>Conte com a experiência e nossos diferenciais</Description>

        <BarGrid>
          <BarCard>
            <MainDiv>
              <DivText>
                <Icones src={MapBrazil} />
                <SubTitleText>
                  Força da Marca
                </SubTitleText>
                <MensagemText>
                  No cenário da saúde, estamos entre as empresas com melhor reputação, além de um alto índice de crescimento e de visibilidade. Expertise respeitada em todo o Brasil!
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivText>
                <Icones src={PlusProfit} />
                <SubTitleText>
                  Valores Justos
                </SubTitleText>
                <MensagemText>
                  Com grandes taxas de atração e fidelização, oferecemos, além da qualidade dos serviços prestados, a ausência de carências. 
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivText>
                <Icones src={Software} />
                <SubTitleText>
                  Plataforma e How-How
                </SubTitleText>
                <MensagemText>
                  Do projeto arquitetônico até o suporte jurídico e contábil de confiança, você contará com uma plataforma robusta que faz da Solumedi a franquia de agendamento médico que mais cresce no Brasil.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivText>
                <Icones src={NewProcess} />
                <SubTitleText>
                  Processos de Inovação
                </SubTitleText>
                <MensagemText>
                  Com um sistema próprio de gestão, e um modelo de negócio validado, sólido e de alta lucratividade. Sua agenda e os valores de cada serviço serão administrados da melhor forma.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

        </BarGrid>







      </Flex>


    </>
  );
}