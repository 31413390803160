import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom'

import { Flex } from '../../assets/styles/global';
import {
  Title,
  SubTitle,
  Row,
  Column,
  ColumnImg,
  Container,
  TextLink
} from './especialidadesDetails.styles'

import Banner from './banner';
import UnidadesBar from '../../components/unidadesBar/unidadesBar';

import UrlImagem from '../../assets/image/especialidadesAll.png';

import { DSetInnerHTML } from '../../components/DSetInnerHTML';
import Especialidades from '../../content/especialidades.json';

export function EspecialidadesDetails() {

  const { slug } = useParams()
  const conteudo = Especialidades[`${slug}`]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginTop: "20px", marginBottom: "20px" }} >
        <Title>{conteudo.title}</Title>
      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
        <Row>
          <ColumnImg background={UrlImagem}></ColumnImg>
          <Column>
            <Container>

              <DSetInnerHTML data={conteudo.text} />

              <Link to='/unidades/buscar' style={{ textDecoration: "none", color: "#007ffb" }}>
                <SubTitle>Agendar {conteudo.title}</SubTitle>
              </Link>
              <p>Para ter o melhor atendimento de {conteudo.title}, conte com as unidades Solumedi por todo Brasil.</p>
              <p>Temos um preço justo para que todos tenham acesso a profissionais extremamente qualificados para cuidar da sua saúde!</p>
              <p>
                <span>
                  <Link to='/unidades/buscar' style={{ textDecoration: "none", color: "#007ffb" }}>Clique</Link>
                </span>
                &nbsp;e entre em contato com nossa equipe.</p>
              <Link to='/especialidades' style={{ textDecoration: "none" }}>
                <TextLink>
                  Ver outras especialidades.
                </TextLink>
              </Link>
            </Container>
          </Column>
        </Row>
      </Flex>
      <UnidadesBar />
    </>
  );
}