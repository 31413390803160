import React, { useEffect } from 'react';

import { Header } from './LpHeaderModelC/header';
import { Footer } from './LpFooterModelC/footer';

import LpBannerA from './LpBannerA/lpBannerA';
import LpThemeBCINC from '../../components/LpThemeA/lpThemeBCINC';
import LpMapa from './LpMapaModelC/lpMapa';
import AgendeBoard from '../../components/AgendeBoard/agendeBoard';
import GridEspecialidades from '../../components/GridEspecialidades/gridEspecialidades';
import CarouselExames from '../../components/CarouselExames/carouselExames';

// import SetembroAmarelo from '../../components/SetembroAmarelo/setembroAmarelo';
// import OutubroRosa from '../../components/OutubroRosa/outubroRosa';
import BannerCampaign from '../../components/BannerCampaign/bannerCampaign';
import LpSpecialtiesCollapse from '../../components/LpSpecialtiesCollapse/lpSpecialtiesCollapse';

export default function ModelC( { data } ) {

  var infoTag = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '423559089515198');
fbq('track', 'PageView');`;



  useEffect( () => {
    const $pixelScript = document.createElement( "script" );
    document.head.appendChild( $pixelScript );
    $pixelScript.innerHTML = infoTag;

    const $pixelNoscript = document.createElement( "noscript" );
    document.head.appendChild( $pixelNoscript );

    const $imgNoscript = document.createElement( "img" );

    $imgNoscript.height = "1";
    $imgNoscript.width = "1";
    $imgNoscript.style = "display:none";
    $imgNoscript.src = "https://www.facebook.com/tr?id=423559089515198&ev=PageView&noscript=1";

    $pixelNoscript.appendChild( $imgNoscript )

    /* const $scriptRemove = document.createElement("script"); */

    return () => {
      document.head.removeChild( $pixelScript );
    }
  }, [] )




  return (
    <>

      <Header email={data.data.email_adm} />
      {/* <BannerCampaign /> */}
      <LpBannerA data={data} />
      <LpMapa data={data} />
      <LpThemeBCINC />
      {/* <GridEspecialidades /> */}
      <LpSpecialtiesCollapse data={data} />
      <AgendeBoard data={data} infoPrice={"70"} />
      <CarouselExames data={data} />
      <Footer data={data} />

    </>
  );
}