import React from 'react';
import { Link } from 'react-router-dom';

import { Flex } from '../../../assets/styles/global';
import {
  Bottom,
  BottomText,
  ButtonDiv,
  ContainerArea,
  Description,
  Enderecos,
  BarGrid,
  BarCard,
  Card,
  LogoSocial,
  Row,
  RowEnderecos,
  Column,
  LogoImg,
  HashTag
} from './footer.styles'

import Solumedi from '../../../assets/image/s.png'
import FaceBook from '../../../assets/image/facebook.png'
import Instagram from '../../../assets/image/instagram.png'
import YouTube from '../../../assets/image/youtube.png'

import { CustomButton } from '../../../components/CustomButton/customButton';

export function Footer( { data } ) {


  const callFB = () => {
    if ( data.data.facebook === 0 ) {
      window.open( `https://web.facebook.com/SolumediOficial`, "_blank" )
    } else {
      window.open( `https://web.facebook.com/${data.data.facebook}`, "_blank" )
    }
  }

  const callInsta = () => {
    if ( data.data.instagram === 0 ) {
      window.open( `https://www.instagram.com/solumedioficial`, "_blank" )
    } else {
      window.open( `https://www.instagram.com/${data.data.instagram}`, "_blank" )
    }
  }

  const callYou = () => {
    window.open( 'https://www.youtube.com/channel/UCMSBIH64rB1OfRkWAGBbODA', "_blank" )
  }

  return (
    <>
      {/* <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <ButtonDiv>
          <Link to='/' style={{ textDecoration: "none" }}>
            <CustomButton
              margin={"0px 0px 20px 0px"}
              color={"#2a4f72"}
              background={"#fdd460"}>Ir para o site completo</CustomButton>
          </Link>
        </ButtonDiv>
      </Flex> */}

      {/*       <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ backgroundColor: "#2a4f72" }}>
        <Description style={{ margin: "10px", fontWeight: "300" }}>Nossas informações: Camboriumedi Apoio Administrativo e Consultoria Empresarial Ltda. CNPJ: 26.570.303/0001-33 Avenida do Estado Dalmo Vieira, 1900 sala 02, Nações, Balneário Camboriú/SC, CEP: 88.338-063 Telefone: (47) 3363-4335 </Description>
      </Flex> */}

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <ContainerArea>
          <BarGrid>
            <BarCard>
              <Card>
                <Row>
                  <LogoImg background={Solumedi}></LogoImg>
                  <Column>
                    <RowEnderecos>
                      <Enderecos>
                        {data.enderecos[ 0 ].logradouro}
                      </Enderecos>
                    </RowEnderecos>
                    <RowEnderecos>
                      <Enderecos>{data.data.telefone_whats}</Enderecos>
                    </RowEnderecos>
                    <Row>
                      <Description>SIGA NOSSAS REDES</Description>
                    </Row>
                    <Row>
                      <LogoSocial src={FaceBook} onClick={callFB} />
                      <LogoSocial src={Instagram} onClick={callInsta} />
                      <LogoSocial src={YouTube} onClick={callYou} />
                    </Row>
                  </Column>
                </Row>
                <Row>
                  <HashTag>
                    <span style={{ color: "#fdd460" }}>#</span>
                    voce
                    <span style={{ color: "#fdd460" }}>merece</span>
                    ter
                    <span style={{ color: "#fdd460" }}>saude</span>
                  </HashTag>
                </Row>
              </Card>
            </BarCard>
          </BarGrid>
        </ContainerArea>
      </Flex>

      <Bottom>
        <BottomText>
          &copy; 2024 | Desenvolvido por Solumedi
        </BottomText>
      </Bottom>
    </>
  );
}
