import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import quote from '../../assets/image/quote.png';
import quoteLt from '../../assets/image/quoteLt.png';

export const Title = styled.h2`
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 250px 0px 0px 200px;
  }
`

export const Description = styled.p`
  font-size: 12px;
  color: ${colors.gray2};
  margin: 5px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 18px;
    margin: 10px 0px 0px 100px;
  }
`

export const BarGrid = styled.div`
  width: 90%;
  padding: 25px 0px;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const BarContainerTitle = styled.div`
  width: 100%
  grid-column: span 3;
  margin-bottom: 5px;
  background: url(${quoteLt}) right no-repeat;
  background-size: 60px;

  @media (min-width: 768px) {
    display: block;
    grid-column: span 3;
    margin-bottom: 0;
    background: url(${quote}) left no-repeat;
    background-size: 250px;
  }
`

export const BarContainerInput = styled.div`
  text-align: center;
  width: 100%
  grid-column: span 3;
  @media (min-width: 768px) {
    grid-column: span 3;
  }
`

export const Form = styled.div`
@media (min-width: 768px) {
  display: inline-flex;
  width: 100%
}
`

export const FormInput = styled.div`
@media (min-width: 768px) {
  display: inline-flex;
  width: 100%
}
`

export const FormButton = styled.div`
@media (min-width: 768px) {
  display: inline-flex;
  width: 30%
}
`

export const Input = styled.input`
  border: none;
  border-radius: 50px;
  padding: 1rem 3rem;
  margin-bottom: 5px;
  width: -webkit-fill-available;
  position: relative;
  display: inline-block;

  ::-webkit-input-placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  :-ms-input-placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  ::placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  @media (min-width: 768px) {
    max-width: -webkit-fill-available;
    margin: 0;
    border-radius: 50px 0px 0px 50px;
  }
`

export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: ${colors.yellow2};
  border: none;
  border-radius: 50px;
  padding: 1rem 3rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin: ${props => props.margin ? props.margin : '0' };
  color: ${colors.blue6};
  width: 100%;
  position: relative;
  display: inline-block;
  @media (min-width: 768px) {
    max-width: -webkit-fill-available;
    margin: 0;
    border-radius: 0px 50px 50px 0px;
  }
`



