import React from 'react';


import {
  Leading,
  
  TitleA,
  
  Description,
  Description2,
  BarGrid,
  BarCard,
  MainDiv,
  DivText,
  Icones,
  SubTitleText,
  MensagemText,
} from './bannerNumeros.styles';

import MapBrazil from '../../../assets/image/mapBrazil.png'
import PlusProfit from '../../../assets/image/plusProfit.png'
import Software from '../../../assets/image/software.png'
import NewProcess from '../../../assets/image/newProcess.png'
import bannerLt from '../../../assets/image/unidades-bg-bannerLt.jpg';

export default function BannerA() {

  return (
    <>
      <Leading >


          <TitleA>
            SUA
            <span style={{ color: "white", fontWeight: "900" }}>
              &nbsp;FRANQUIA
            </span>
            &nbsp;EM NÚMEROS
          </TitleA>
          
          <Description>Conheça a capacidade de movimentação de uma franquia Solumedi</Description>

          <BarGrid>
            <BarCard>
              <MainDiv>
                <DivText>

                  <SubTitleText>
                    4,8milhões
                  </SubTitleText>
                  <MensagemText>
                    Faturamento<br></br>anual estimado*
                  </MensagemText>
                </DivText>
              </MainDiv>
            </BarCard>

            <BarCard>
              <MainDiv>
                <DivText>
                  <SubTitleText>
                    18
                  </SubTitleText>
                  <MensagemText>
                    Meses de payback<br></br>em média
                  </MensagemText>
                </DivText>
              </MainDiv>
            </BarCard>

            <BarCard>
              <MainDiv>
                <DivText>
                  <SubTitleText>
                    30%
                  </SubTitleText>
                  <MensagemText>
                    Margem de lucro**
                  </MensagemText>
                </DivText>
              </MainDiv>
            </BarCard>

            <BarCard>
              <MainDiv>
                <DivText>
                  <SubTitleText>
                    60
                  </SubTitleText>
                  <MensagemText>
                    Dias<br></br>de implantação
                  </MensagemText>
                </DivText>
              </MainDiv>
            </BarCard>

          </BarGrid>

        
          <Description2>*Dados baseados em unidades ativas na rede</Description2>
          <Description2>**Valor pode variar em função de fatores regionais</Description2>
      </Leading>

    </>
  );
}