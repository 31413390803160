import React, { useState } from 'react';

import { Flex } from '../../../assets/styles/global';
import {
  MainRow,
  Row,
  Column,
  ColumnImg,
  Container,
  Title,
  SubTitle,
  DescriptionA,
  DescriptionB,
  RowPrice,
  DescriptionPrice
} from './lpBanner.styles'

import UrlImagem from '../../../assets/image/s-bg.png';
import UrlImagem2 from '../../../assets/image/especialidadeExemplo1.webp';

import { CustomButton } from '../../../components/CustomButton/customButton';
import { ContactWAForm } from '../../../components/ContactForm/contactWAForm';
import { requestForm } from '../../../helpers/requestForm';

export default function LpBannerA( { data } ) {

  const [ showContactForm, setShowContactForm ] = useState( false )

  const sendWhatsApp = () => {
    window.open( `https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace( /[^\d]+/g, '' )}&text=${data.data.mensagem_whats}`, "_blank" )
  }

  const handleClick = ( params ) => {
    setShowContactForm( false )
    if ( !params?.accept ) return
    // SEND REQUEST
    requestForm( params.telefone_whats )
    sendWhatsApp()
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
        <MainRow>
          <Column>
            <Container background={UrlImagem2}></Container>
          </Column>

          <ColumnImg background={UrlImagem}>
            <Row>
              <Title>Santa Catarina Litoral Norte</Title>
            </Row>
            <Row>
              <Row>
                <SubTitle>Consultas</SubTitle>
              </Row>
              <Row>
                <SubTitle>&amp; Exames</SubTitle>
              </Row>
              <RowPrice>
                <DescriptionA>a partir de</DescriptionA>
                <DescriptionPrice>R$70</DescriptionPrice>
              </RowPrice>
            </Row>

            <Row>
              <Row>
                <DescriptionB>Atendemos mais de 30 especialidades com valores reduzidos!</DescriptionB>
              </Row>
              <Row>
                <DescriptionB>Clique no botão abaixo e fale conosco pelo <strong>WhatsApp</strong> ou ligue <br></br><strong>{data.data.telefone_fixo}</strong></DescriptionB>
              </Row>
            </Row>

            <Row>
              <CustomButton
                onClick={() => sendWhatsApp()}
                color={"#2a4f72"}
                background={"#fdd460"}
                width={"70%"}>AGENDAMENTO VIA WHATS</CustomButton>
            </Row>

          </ColumnImg>
        </MainRow>

        {showContactForm ? <ContactWAForm handleClick={handleClick} /> : <></>}

      </Flex>
    </>
  );
}