import React, { useEffect, useState } from 'react';
import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description,
  BarGrid,
  BarCard
} from './gridAddress.styles';

import LpBoxAddress from '../LpBoxAddress/LpBoxAddress';

import unidades from "../../content/unidades.json";
const londrinaGroup = ["londrina", "apucarana", "arapongas", "cambe"]

export default function GridEspecialidades({ data }) {

  const [result, setResult] = useState([])

  useEffect(() => {
    if (data.data.slug === 'londrina') {
      getLondrinaAddress()
    }
  }, [data])

  const getLondrinaAddress = (slug) => {
    var array = []

    for (let index = 0; index < londrinaGroup.length; index++) {
      var slugIndex = londrinaGroup[index];
      var info = unidades.find((unidade) => unidade.data.slug === slugIndex);
      if (info) {
        array.push(info)
      }
    }
    setResult(array)
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>VENHA PARA A SOLUMEDI</Title>
        <Description>O melhor CUSTO x BENEFÍCIO no ramo da saúde</Description>
      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <BarGrid>
          {result.map((item, index) => (
            <BarCard key={index}>
              <LpBoxAddress data={item} />
            </BarCard>
          ))}
        </BarGrid>

      </Flex>
    </>
  );
}