import React, { useEffect } from 'react';

import { Flex } from '../../assets/styles/global';
import {
  Title,
  Description,
} from './contato.styles'



import Banner from './banner';
//import Form from './form';
import Form from './htmlForm/form';

export function Contato() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginTop: "20px", marginBottom: "20px" }} >
        <Title>COMO PODEMOS TE AJUDAR?</Title>
        <Description>Envie sua mensagem, nós queremos te ouvir</Description>
      </Flex>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
        <Form />
      </Flex>
    </>
  );
}