import styled from 'styled-components';
import colors from '../../assets/styles/colors';

//SEE STICKY ELEMENT POSITION

export const Topbar = styled.div`
  height: 30px;
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.blue3};
  @media (max-width: 768px) {
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    max-height: ${({ isOpen }) => (isOpen ? "30px" : "0")};
    transition: max-height 0.3s ease-in;
  }
`;

export const TopDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Separador = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  border-right: 1px solid white;
`


export const TopLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 12px;
  cursor: pointer;
`

export const Nav = styled.div`
  padding: 0.5rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  @media (max-width: 768px) {
    padding: 0.5rem 2rem;
  }

`;

export const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  span {
    height: 4px;
    width: 25px;
    background: ${colors.blue4};
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 5px;
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const MenuLink = styled.a`
  padding: 0.5rem 0rem 0.5rem 0rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: ${colors.gray2};
  transition: all 0.2s ease-in;
  font-size: 0.9rem;
  margin-left: 20px; 
  &:hover {
    color: ${colors.blue4};
/*     font-weight: bold; */
    text-decoration: none;
  }
`;

export const MenuLinkButton = styled.a`
  padding: 0.5rem 0rem 0.5rem 0rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: white;
  transition: all 0.2s ease-in;
  font-size: 0.9rem;
  font-weight: 700;
  margin-left: 20px;
  background-color: ${colors.yellow2};
  border-radius: 50px;
  padding: 5px 15px 5px 15px;
  &:hover {
    opacity: 0.7;
/*     font-weight: bold; */
    text-decoration: none;
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 768px) {
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
    transition: max-height 0.3s ease-in;
  }
`;

export const Logo = styled.img`
  height: 30px;
`