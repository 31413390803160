const dataColombo = [
  {
    "id": 1,
    "logradouro": "Rodovia da Uva",
    "numlogradouro": "1360",
    "complemento": "loja 16",
    "bairro": "Jd. Osasco",
    "cidade": "Colombo",
    "uf": "PR",
    "cep": "83.403-144",
    "entidade_id": "29",
    "favorito": 1,
    "telefone_fixo": "(41) 3621-0902",
    "latitude": 0,
    "longitude": 0,
    "url": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.5742866258256!2d-49.22176898500082!3d-25.352062583824384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce7dd77ef0793%3A0x1b24e8ac4aa03a92!2sSolumedi%20Colombo%20-%20Jardim%20Osasco!5e0!3m2!1spt-BR!2sbr!4v1651145569777!5m2!1spt-BR!2sbr"
  },
  {
    "id": 2,
    "logradouro": "Rua Pasteur",
    "numlogradouro": "23",
    "complemento": "loja 2",
    "bairro": "Guarani",
    "cidade": "Colombo",
    "uf": "PR",
    "cep": "83.408-020",
    "entidade_id": "29",
    "favorito": 1,
    "telefone_fixo": "(41) 3621-0902",
    "latitude": -25.2925,
    "longitude": -49.2262,
    "url": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14420.461493108163!2d-49.19520473022458!3d-25.367449499999992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce8bec62ecf2d%3A0x919182ee5bbfa397!2sSoluMedi%20Colombo%20-%20Maracan%C3%A3!5e0!3m2!1spt-BR!2sbr!4v1639753382885!5m2!1spt-BR!2sbr"
  }
]

export { dataColombo }