import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import examesBanner from '../../assets/image/examesBanner.jpg';

export const Section = styled.section``

export const Title = styled.h2`
  font-size: 24px;
  color: ${colors.blue4};
  margin: 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 70px 0px 0px 0px;
  }
`

export const Description = styled.p`
  font-size: 12px;
  color: ${colors.gray2};
  margin: 5px 0px 20px 0px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 10px 0px 30px 0px;
  }
`

//


export const BarGrid = styled.div`
  width: 100%;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const BarContainerVideo = styled.div`
  grid-column: span 3;
  height: 250px;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 3;
    display: block;
    height: 400px;
  }
`

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: -webkit-fill-available;
  background: url(${examesBanner}) center no-repeat;
  background-size: 75%;
  @media (min-width: 768px) {
    background-size: 90%;
  }
`

export const BarContainerArea = styled.div`
  grid-column: span 3;  
  height: 350px;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 3;
    display: block;
  }
`

export const TitleA = styled.p`
  word-break: break-word;
  font-size: 24px;
  font-weight: 700;
  color: ${colors.blue6};
  margin: 25px 5px 15px 25px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 30px 5px 15px 25px;
  }
`

export const SubTitle = styled.p`
  word-break: break-word;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.blue3};
  margin: 5px 5px 0px 25px;
  @media (min-width: 768px) {
    margin: 5px 5px 15px 25px;
  }
`

export const DescriptionA = styled.p`
  word-break: break-word;
  font-size: 14px;
  color: ${colors.blue6};
  margin: 10px 15px 15px 25px;
`

export const CustomButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: ${props => props.background ? props.background : colors.blue6};
  border: none;
  border-radius: 50px;
  padding: 1rem 1.5rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin: 0px 0px 0px 25px;
  color: ${props => props.color ? props.color : 'white'};
  width: 50%;
  position: relative;
  display: inline-block;
  @media (min-width: 768px) {
    width: 30%;
  }
  :hover{
    opacity: 0.7;
  }
`