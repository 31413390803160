import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerTitle,
  Title,
  Description,
  BarContainerInput
  
} from './testimonials.styles';

import CarouselTestimonials from '../CarouselTestimonials/carouselTestimonials';

export default function Testemonials() {
  return (
    <>
       <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{marginTop: "30px"}}>
        <BarGrid>
          <BarContainerTitle>
            <Title>DEPOIMENTOS</Title>
            <Description>Veja o que nossos clientes tem a dizer sobre nós!</Description>
          </BarContainerTitle>

          <BarContainerInput>
            <CarouselTestimonials />
          </BarContainerInput>
        </BarGrid>
      </Flex>
    </>
  );
}