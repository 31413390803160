import React from 'react';

import { Header } from './LpHeaderModelD/header';
import { Footer } from './LpFooterModelD/footer';

import LpBannerA from '../../components/LpBannerA/lpBannerA';
import LpThemeB from '../../components/LpThemeB/lpThemeB';
import LpMapa from './LpMapaModelD/lpMapa';
import AgendeBoard from '../../components/AgendeBoard/agendeBoard';
import GridEspecialidades from '../../components/GridEspecialidades/gridEspecialidades';
import CarouselExames from '../../components/CarouselExames/carouselExames';

//import SetembroAmarelo from '../../components/SetembroAmarelo/setembroAmarelo';
// import OutubroRosa from '../../components/OutubroRosa/outubroRosa';
import BannerCampaign from '../../components/BannerCampaign/bannerCampaign';

export default function ModelD( { data } ) {

  return (
    <>
      <Header email={data.data.email_adm} />
      {/* <BannerCampaign /> */}
      <LpBannerA data={data} />
      <LpMapa data={data} />
      <LpThemeB />
      <GridEspecialidades />
      <AgendeBoard data={data} infoPrice={"70"} />
      <CarouselExames data={data} />
      <Footer data={data} />
    </>
  );
}