import React, { useState } from 'react';

import { Flex } from '../../../assets/styles/global';
import {
  MainRow,
  Row,
  Column,
  ColumnImg,
  Container,
  Title,
  SubTitle,
  DescriptionA,
  DescriptionB,
  DescriptionC,
  RowPrice,
  DescriptionPrice
} from './lpBanner.styles'

import UrlImagem from '../../../assets/image/s-bg.png';
import UrlImagem2 from '../../../assets/image/especialidadeExemplo1.webp';

import { CustomButton } from '../../../components/CustomButton/customButton';
import { ContactWAForm } from '../../../components/ContactForm/contactWAForm';
import { requestForm } from '../../../helpers/requestForm';

export default function LpBannerCuritiba( { data } ) {

  const [ showContactForm, setShowContactForm ] = useState( false )

  const sendWhatsApp = () => {
    window.open( `https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace( /[^\d]+/g, '' )}&text=${data.data.mensagem_whats}`, "_blank" )
  }

  const handleClick = ( params ) => {
    setShowContactForm( false )
    if ( !params?.accept ) return
    // SEND REQUEST
    requestForm( params.telefone_whats )
    sendWhatsApp()
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
        <MainRow>
          <Column>
            <Container background={UrlImagem2}></Container>
          </Column>

          <ColumnImg background={UrlImagem}>
            <Row>
              <Title>Oftalmologista em Curitiba</Title>
            </Row>
            <Row>
              <RowPrice>
                {/* <DescriptionA>Prótese <span style={{ fontWeight: "bold" }}>FIXA</span> é com a Solumedi <span style={{ fontWeight: "bold" }}>Piçarras</span>!</DescriptionA> */}
                <DescriptionA>Preços reduzidos sem mensalidade, sem anuidade e sem taxa de adesão.</DescriptionA>
              </RowPrice>
            </Row>

            <Row>
              <Row>
                <DescriptionC>Cirurgia de Catarata com valores especiais!</DescriptionC>
                {/* <DescriptionB><span style={{ fontWeight: "bold" }}>PRÓTESE PROTOCOLO.</span><span style={{ color: "#fdd460", fontWeight: "bold" }}> Ela é fixa, não machuca as gengivas ao mastigar, e é de fácil higienização.</span></DescriptionB> */}
                {/* <DescriptionB><span style={{ fontWeight: "bold" }}>Implantes, Carga Imediata, Prótese, Enxerto Ósseo, Clínica Geral.</span></DescriptionB> */}
              </Row>
              <Row style={{ marginTop: "25px" }}>
                {/* <DescriptionB>Temos uma estrutura completa, tecnologia moderna, e um time de especialistas com tradição em construir novos sorrisos por meio dos tratamentos com implantes dentários.</DescriptionB> */}
                <DescriptionB>Avaliações, Consultas, Exames de vista, Cirurgias e muito mais...</DescriptionB>
                <DescriptionB>Clique no botão abaixo e fale conosco pelo WhatsApp ou ligue {data.data.telefone_fixo}</DescriptionB>
              </Row>
            </Row>

            <Row>
              <CustomButton
                onClick={() => sendWhatsApp()}
                color={"#2a4f72"}
                background={"#fdd460"}
                width={"70%"}>AGENDE AGORA</CustomButton>
            </Row>

          </ColumnImg>
        </MainRow>

        {showContactForm ? <ContactWAForm handleClick={handleClick} /> : <></>}

      </Flex>
    </>
  );
}