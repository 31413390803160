import React, { useEffect } from 'react';

import BannerCampaign from '../../components/BannerCampaign/bannerCampaign';
import Banner from './banner';
import UnidadesBar from '../../components/unidadesBar/unidadesBar';
import BannerA from '../../components/BannerA/bannerA';
import BannerTagDescription from '../../components/BannerTagDescription/bannerTagDescription';
import GridEspecialidades from '../../components/GridEspecialidades/gridEspecialidades';
import BannerB from '../../components/BannerB/bannerB';
import Testimonials from '../../components/Testimonials/testimonials';
import BannerC from '../../components/BannerC/bannerC';
import Blog from '../../components/Blog/blogFrame';
import AgendeBar from '../../components/AgendeBar/agendeBar';

export function Home() {

  useEffect( () => {
    window.scrollTo( 0, 0 )
  }, [] );

  return (
    <>
      {/* <BannerCampaign /> */}
      <Banner />
      <UnidadesBar />
      <BannerTagDescription />
      <BannerA />
      <GridEspecialidades />
      <BannerB />
      <Testimonials />
      <BannerC />
      <Blog />
      <AgendeBar />
    </>
  );
}