import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import api from '../../api';

import { Flex } from '../../assets/styles/global';
import {
  Title,
  SubTitle,
  Row,
  Column,
  ColumnImg,
  Container,
  TextLink,
  ShareContainer,
  ShareLogo
} from './blogDetails.styles'
import { Loading } from '../../components/Loading/loading';

import Banner from './banner';
import { DSetInnerHTML } from '../../components/DSetInnerHTML';

import ShareFacebook from '../../assets/image/shareFacebook.png';
import ShareTwitter from '../../assets/image/shareTwitter.png';
import ShareWhatsApp from '../../assets/image/shareWhatsApp.png';
import ShareEmail from '../../assets/image/shareEmail.png';
import ShareShare from '../../assets/image/shareShare.png';

import UrlImagem from '../../assets/image/especialidadeExemplo1.webp';
//import UrlImagem from '../../assets/image/especialidadeExemplo2.png';

export function BlogDetails() {
  const { slug } = useParams()

  const [post, setPost] = useState()
  const [postTitle, setPostTitle] = useState()
  const [postImagem, setPostImagem] = useState()
  const [postContent, setPostContent] = useState()

  useEffect(() => {
    window.scrollTo(0, 0)
    getPosts()
  }, []);

  const getPosts = async () => {
    const result = await api.get(`wp/v2/posts?slug=${slug}&_embed`)
    console.log(result.data[0])
    setPost(result.data[0])
    setPostTitle(result.data[0].title.rendered)
    setPostImagem(result.data[0]._embedded["wp:featuredmedia"][0].source_url)
    setPostContent(result.data[0].content.rendered)
  }

  var url = `https://solumedi.com.br/blog/${slug}`
  const shareF = () => {
    if(!navigator.clipboard){
      alert("Compartilhamento inacessível")
      return
    } else{
      navigator.clipboard.writeText(url).then(function() {
        alert("Link copiado para sua área de transferência");
      }, function() {
        console.log('FAIL')
      });
    }
  };


  return (
    <>
      <Banner />
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginTop: "20px", marginBottom: "20px" }} >
        {post ?
          <>
            <Title>{postTitle ? postTitle : "..."}</Title>
          </>
          :
          <>
            <Loading />
          </>}
      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
        {post ?
          <>
            <Row>
              <ColumnImg background={postImagem ? postImagem : UrlImagem}></ColumnImg>

              <Column>
                <Container>
                  <DSetInnerHTML data={postContent} />
                  <SubTitle>Compartilhar este conteúdo</SubTitle>
                  <ShareContainer>
                    <a
                      href={`https://api.whatsapp.com/send?phone=?&text=${postTitle}%20https://www.solumedi.com.br/blog/${slug}`}
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <ShareLogo src={ShareWhatsApp}></ShareLogo>
                    </a>
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=https://www.solumedi.com.br/blog/${slug}`}
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <ShareLogo src={ShareFacebook}></ShareLogo>
                    </a>
                    <a
                      href={`https://twitter.com/intent/tweet?text=${postTitle}&amp;url=https://www.solumedi.com.br/blog/${slug}`}
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <ShareLogo src={ShareTwitter}></ShareLogo>
                    </a>


                    <ShareLogo
                     src={ShareShare}
                     onClick={shareF}
                     ></ShareLogo>

                    <a
                      href={`mailto:?subject=Post%20Solumedi&body=www.solumedi.com.br/blog/${slug}`}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <ShareLogo src={ShareEmail}></ShareLogo>
                    </a>
                  </ShareContainer>
                  <Link to='/blog' style={{ textDecoration: "none" }}>
                    <TextLink>
                      Ver outras postagens.
                    </TextLink>
                  </Link>
                 </Container>
              </Column>
            </Row>
          </>
          :
          <></>}
      </Flex>
    </>
  );
}
