import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const Input = styled.input`
  border: none;
  border-radius: 50px;
  height: 45px;
  width: -webkit-fill-available;
  margin-bottom: 10px;
  font-size: 21px;
  ::-webkit-input-placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  :-ms-input-placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  ::placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  @media (min-width: 768px) {
    border-radius: 50px 0px 0px 50px;
    width: 100%;
    margin: 0;
    font-size: 18px;
  }
`

export const SuggestionP = styled.p`
  cursor: pointer;
  color: white;
  font-size: 21px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
  :hover{
    font-weight: 700;
  }
` 
