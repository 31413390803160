import React, { useEffect } from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description
} from './blog.styles';

import Blog from './blog';

export default function BlogFrame() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>BLOG</Title>
        <Description>Fique sabendo de tudo que acontece por aqui</Description>
      </Flex>
      <Blog />
    </>
  );
}