import React, { useEffect, useState } from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Grid,
  Column,
  MaxContainer,
  ContainerIframe,
  Container,
  Title,
  Description
} from './lpEmBreve.styles';

import { CustomButton } from '../CustomButton/customButton';
import { ContactWAForm } from '../../components/ContactForm/contactWAForm';
import { requestForm } from '../../helpers/requestForm';

export default function LpMapa( { data } ) {

  const [ hasMapa, setHasMapa ] = useState( false )
  const [ showContactForm, setShowContactForm ] = useState( false )

  useEffect( () => {
    if ( data.enderecos[ 0 ].url ) {
      setHasMapa( data.enderecos[ 0 ].url )
    }
  }, [ data ] )

  /* const seeLocal = () => {
    if (hasMapa !== false) {
      window.open(`${data.enderecos[0].url}`, "_blank")
    } else {
      alert(`Nosso endereço:
      ${data.enderecos[0].logradouro}, ${data.enderecos[0].numlogradouro}, ${data.enderecos[0].complemento ? ` ${data.enderecos[0].complemento},` : ""}${data.enderecos[0].bairro}, ${data.enderecos[0].cidade}, ${data.enderecos[0].uf}, CEP: ${data.enderecos[0].cep}
     `)
    }
  } */

  function callWA() {
    let number = data.data.telefone_whats.replace( /[^\d]+/g, '' )
    window.open( `https://api.whatsapp.com/send?phone=55${number}&text=${data.data.mensagem_whats}`, "_blank" )
  }

  const handleClick = ( params ) => {
    setShowContactForm( false )
    if ( !params?.accept ) return
    // SEND REQUEST
    requestForm( params.telefone_whats )
    callWA()
  }


  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginBottom: "20px" }}>
        <Grid>
          <Column>
            <MaxContainer>
              <Container>
                <Title><span style={{ color: "#fdd460" }}>EM BREVE MAIS UMA UNIDADE</span><br></br>
                  Solumedi {data.data.nomefantasia}</Title>
                <Description>Estamos chegando!!! Em breve inaugura mais uma unidade Solumedi aqui em {data.enderecos[ 0 ].cidade} - {data.enderecos[ 0 ].uf}, e você vai poder agendar consultas médicas e realizar exames clínicos com os melhores profissionais particulares e tudo isso com valores reduzidos. Clique no botão do WhatsApp e fale conosco!</Description>
                <CustomButton
                  onClick={() => callWA()}
                  margin={"10px 0px 10px 0px"}
                  width={"70%"}>FALE CONOSCO</CustomButton>
              </Container>
            </MaxContainer>
          </Column>

          <Column>
            <MaxContainer>
              <ContainerIframe src={hasMapa}
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></ContainerIframe>
            </MaxContainer>
          </Column>
        </Grid>

        {showContactForm ? <ContactWAForm handleClick={handleClick} /> : <></>}

      </Flex>
    </>
  );
}