import React, { useEffect, useState } from 'react';

import { Flex } from '../../assets/styles/global';
import {
  MainRow,
  Column,
  Container
} from './lpImages.styles'

import UrlImagemJ from '../../assets/image/lpImage/imageLpJoinville1.jpeg';
import UrlImagemJ2 from '../../assets/image/lpImage/imageLpJoinville2.jpeg';

import UrlImagemSJC from '../../assets/image/lpImage/saojosedoscamposUltrassom.png';
import UrlImagemSJC2 from '../../assets/image/lpImage/saojosedoscamposUltrassom2.png';




export default function LpBannerA({ data }) {

  const [urlBG1, setUrlBG1] = useState();
  const [urlBG2, setUrlBG2] = useState();

  useEffect(() => {

    if (data.data.slug === "joinville") {
      setUrlBG1(UrlImagemJ)
      setUrlBG2(UrlImagemJ2)
    }

    if (data.data.slug === "saojosedoscampos") {
      setUrlBG1(UrlImagemSJC)
      setUrlBG2(UrlImagemSJC2)
    }

  }, [data])



  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <MainRow>
          <Column>
            <Container background={urlBG1}></Container>
          </Column>

          <Column>
            <Container background={urlBG2}></Container>
          </Column>
        </MainRow>
      </Flex>
    </>
  );
}