import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Grid = styled.div`
  width: 100%;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const Column = styled.div`
  grid-column: span 3;
  height: 340px;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 3;
    height: 400px;
  }
`

export const MaxContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Container = styled.div`
  text-align: justify;
  padding: 0px 10px 0px 10px;
  @media (min-width: 768px) {
    padding: 0px 100px 0px 20px;
  }
`

export const ContainerIframe = styled.iframe`
  width: 280px;
  height: 280px;
  border: 0;
  @media (min-width: 768px) {
    width: 560px;
    height: 315px;
  } 
`

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${colors.blue4};
  margin: 0px 0px 20px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 0px 0px 15px 0px;
  }
`

export const Description = styled.p`
  font-size: 16px;
  color: ${colors.blue6};
  margin: 10px 0px 10px 0px;
`

export const DescriptionOne = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.blue6};
  margin: 10px 0px 20px 0px;
`

export const DivImg = styled.div`
  background: url(${props => props.background});
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 90%;
  height: 450px;
  display: flex;
  flex-flow: row wrap;
  padding: 20px;
  align-items: center;
    @media (min-width: 768px) {
      background-size: 100%;
      width: 90%;
      height: 600px;
      padding: 0px;
    }
`
