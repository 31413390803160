import styled from 'styled-components';

export const MainRow = styled.div`
  width: 100%;
  background: url(${props => props.background});
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  height: 150px;

  @media (min-width: 768px) {
    height: 420px;
    background-position: bottom;
    margin-bottom: 50px;
  }
  @media (min-width: 1200px) {
    height: 500px;
  }
  @media (min-width: 1500px) {
    height: 600px;
  }
`
