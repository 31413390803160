import React from 'react';
import Lottie from "lottie-react";
import loadingAnimation from '../../assets/json/loading.json';

export function Loading() {

  return (
    <Lottie animationData={loadingAnimation} style={{ width: "18vw" }} />
  )
};
