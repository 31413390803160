import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../../api';

import { Flex } from '../../assets/styles/global';
import {
  Title,
  Row,
  Column,
  Collapsible,
  DivImg,
  DivText,
  TitleEsp,
  TextContent,
  TextDate,
  ThumbImg
} from './listBlog.styles';

import { Loading } from '../Loading/loading';
import { dateString } from '../../helpers/dateString';
import readMore from '../../assets/image/readmore.jpg';

export default function ListBlog() {
   const [posts, setPosts] = useState()
   const [offSet, setOffSet] = useState(10)
   const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    getPosts()
  }, []);

  const getPosts = async () => {
    let result = await api.get("wp/v2/posts?per_page=10&_embed")
    if(!result) return
    setPosts(result.data)
  }

  const handleExcerpt = (text) => {
    let content = text ? text.substring(3,140)+"..." : "..."
    return content
  }

  const handleReadMore = async () => {
    setIsLoading(true)
    let result = await api.get(`wp/v2/posts?offset=${offSet}&per_page=10&_embed`)
    if(!result) return

    let content = posts.concat(result.data)
    
    setPosts(content)
    setOffSet(offSet + 10)
    setIsLoading(false)
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>MAIS POSTAGENS</Title>
      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Row>
          {!posts ?
            <Loading />
            :
            posts.map((item, index, row) => (
              <>
                {index < 3 ? <></> :
                  <>
                    <Column key={index}>
                      <Collapsible>
                        <DivImg>
                          <Link to={`blog/${item.slug}`} style={{ textDecoration: "none" }}>
                            <ThumbImg background={item._embedded["wp:featuredmedia"][0].source_url ? item._embedded["wp:featuredmedia"][0].source_url : readMore}></ThumbImg>
                          </Link>
                        </DivImg>
                        <DivText>
                          <TitleEsp>
                            {item.title.rendered}
                          </TitleEsp>
                        <TextContent>
                          <TextDate>{dateString(item.date)}</TextDate>
                          {handleExcerpt(item.excerpt.rendered)}
                        </TextContent>
                        </DivText>
                      </Collapsible>
                    </Column>
                  </>
                }
                {index + 1 === row.length &&
                <Column>
                {isLoading ?
                <>
                 <Loading />
                </>
                 :
                    <Collapsible>
                      <DivImg>
                        <Link style={{ textDecoration: "none" }} onClick={handleReadMore}>
                          <ThumbImg background={readMore}></ThumbImg>
                        </Link>
                      </DivImg>
                      <DivText>
                        <TitleEsp>
                          VER MAIS +
                        </TitleEsp>
                        </DivText>
                      </Collapsible>
                }
                  </Column>
                }
              </>
            ))}
        </Row>

      </Flex>
    </>
  )
}
