import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description,
  BarGrid,
  BarCard,
  MainDiv,
  DivNumber,
  DivText,
  NumberText,
  MensagemText

} from './table3.styles';

export default function Table() {
  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <BarGrid>

          <BarCard>
            <MainDiv>
              <DivNumber >
                <NumberText style={{ fontWeight: "bold" }}>Nossos Fornecedores e Parceiros</NumberText>
                <NumberText >Sistema de Gerenciamento</NumberText>
              </DivNumber>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Instituição Financeira</NumberText>
              </DivNumber>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Prefeitura de Curitiba</NumberText>
              </DivNumber>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Rede de Farmácias e Empresa Administradora de Bens e Consórcios</NumberText>
              </DivNumber>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Escritório de Contabilidade</NumberText>
              </DivNumber>
            </MainDiv>
          </BarCard>

        </BarGrid>

      </Flex>
    </>
  );
}