import React, { useState } from "react";
import { useHistory } from 'react-router-dom';

import {
  Topbar,
  TopDiv,
  TopLink,
  Separador,
  Nav,
  Hamburger,
  MenuLink,
  MenuLinkButton,
  Menu,
  Logo,
} from "./header.styled";

import Logomarca from "../../assets/solumedi.png";

export function Header( { show } ) {
  const history = useHistory()

  const [ isOpen, setIsOpen ] = useState( false );

  return (
    <>
      {show && (
        <>
          {isOpen ? (
            <></>
          ) : (
            <>
              <Topbar>
                <TopLink href="mailto:sac@solumedi.com.br">
                  sac@solumedi.com.br
                </TopLink>
                <TopDiv>
                  <TopLink
                    href="/seja-franqueado"
                    //href="https://lp.solumedi.com.br"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    SEJA UM FRANQUEADO
                  </TopLink>
                  <Separador></Separador>
                  <TopLink href="/parceiro">SEJA UM PARCEIRO</TopLink>
                </TopDiv>
              </Topbar>
            </>
          )}
          <Nav>
            <a href="/" style={{ textDecoration: "none" }}>
              <Logo src={Logomarca} alt="Solumedi" />
            </a>
            <Hamburger onClick={() => setIsOpen( !isOpen )}>
              <span />
              <span />
              <span />
            </Hamburger>
            <Menu isOpen={isOpen}>
              <MenuLink href="/">INÍCIO</MenuLink>
              <MenuLink href="/sobrenos">SOBRE NÓS</MenuLink>
              <MenuLink href="/exames">EXAMES</MenuLink>
              <MenuLink href="/especialidades">ESPECIALIDADES</MenuLink>
              <MenuLink href="/unidades">UNIDADES</MenuLink>
              <MenuLink onClick={() => history.push( '/blog' )}>BLOG</MenuLink>
              <MenuLink href="/contato">OUVIDORIA</MenuLink>
              <MenuLinkButton href="/unidades/buscar">
                AGENDE AGORA
              </MenuLinkButton>
            </Menu>
          </Nav>
        </>
      )}
    </>
  );
}
