import React from 'react';

import {
	Frame,
	FrameContent,
	Text,
	Row
} from './modal.styles';

import { CustomButton } from '../../../components/CustomButton/customButton';

export default function Modal( { callSetShowModal } ) {

	const sendWhatsApp = () => {
		window.open( `https://api.whatsapp.com/send?phone=5541998150123&text=Olá, gostaria de receber uma apresentação da Solumedi Franquias`, "_blank" )
		callSetShowModal()
	}

	return (
		<>
			<Frame>
				<FrameContent>
					<Text>
						Não deixe a página sem conhecer a nossa apresentação!
					</Text>
					<Row>
						<CustomButton
							style={{ padding: '1rem' }}
							margin={'5px'}
							background={'#ff5c55'}
							width={'50%'}
							onClick={callSetShowModal}
						>
							Não, obrigado.
						</CustomButton>
						<CustomButton
							style={{ padding: '1rem' }}
							margin={'5px'}
							background={'#007bff'}
							width={'50%'}
							onClick={sendWhatsApp}
						>
							Saiba mais!
						</CustomButton>
					</Row>
				</FrameContent>
			</Frame>
		</>
	);
}