import React from 'react';
import {
  Row,
  ColumnOne,
  CollapsibleOne,
  TitleEspOne
} from './lpSpecialties.styles';

export default function ContentChildExams( { showList } ) {

  const result = [
    { id: 1, title: "Raio-X" },
    { id: 2, title: "Ultrassonografia" },
    { id: 3, title: "Mamografia" }, //
    { id: 4, title: "Tomografia" }, //
    { id: 5, title: "Ressonância" }, // 
    { id: 6, title: "Endoscopia" }, //
    { id: 7, title: "Colonoscopia" },
    { id: 8, title: "Densitometria" }, //
    { id: 9, title: "Eletroneuromiografia" }, //
    { id: 10, title: "Eletroencefalograma" }, //
    { id: 11, title: "Doppler Venoso e Arterial" },
    { id: 12, title: "Eco Cardiograma" },
    { id: 13, title: "Eletrocardiograma" }, //
    { id: 14, title: "Teste Ergométrico / Esforço" }, //
    { id: 15, title: "Holter / Mapa" },
    { id: 16, title: "Sangue" }, //
    { id: 17, title: "Urina" },
    { id: 18, title: "Fezes" },
    { id: 97, title: "DNA" },
    { id: 19, title: "COVID-19" },
    { id: 20, title: "Secreção (feminina e masculina)" },
    { id: 21, title: "Preventivo" }, //
  ]

  return (
    <Row>
      {result.map( ( item, index ) => (
        <ColumnOne key={index}>
          <CollapsibleOne>
            <TitleEspOne >
              {item.title}
            </TitleEspOne>
          </CollapsibleOne>
        </ColumnOne>
      ) )}
    </Row>
  )
}