import styled from 'styled-components';
import colors from '../../../assets/styles/colors';

export const Frame = styled.div`
	position: fixed;
	z-index: 1;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.4);
`;

export const FrameContent = styled.div`
	background-color: #fefefe;
	margin: auto;
	padding: 20px;
	border-radius: 5px;
	width: 80%;
	display: flex;
	flex-direction: column;
`;

export const Text = styled.p`
	text-align: center;
	color: ${colors.blue6};
	font-size: 28px;
	font-weight: bold;
`;

export const Row = styled.div`
	text-align: center;
`;