const colors = {
  blue1: "#d6f1f6",
  blue2: "#b8deef",
  blue3: "#78cFE0",
  blue4: "#007bff",
  blue5: "#1c7fbb",
  blue6: "#2a4f72",
  yellow1: "rgb(255, 253, 240)",
  yellow2: "#fdd460",
  gray0: "#f4f4f4",
  gray1: "#aaaaaa",
  gray2: "#999999",
  gray3: "#1",
  greenWA: "#059654",
  greenWAButton: "##25d366",
  greenCA: "#82ff00",
  red1: "#ff5c55",
  red2: "#ff0000"
};

export default colors;