import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { CustomButton } from '../CustomButton/customButton';

import {
  Description,
  InputCheckBox,
  DivLabel,
  TextLabel,
  TextLink,
  Input,
  Overlay,
  ModalContainer,
  Form,
  FormInput,
  ButtonContainer
} from '../ContactForm/contactWAForm.styles';

import { maskPhone } from '../../helpers/masks';

export function ContactWAForm( { handleClick } ) {

  const { id } = useParams();

  const [ url, setUrl ] = useState( "" );
  const [ lieAceito, setLieAceito ] = useState( false );
  const [ telefone_whats, setTelefone_whats ] = useState( "" );

  useEffect( () => {
    if ( id ) {
      setUrl( `/politica-de-privacidade/${id}` );
    }
  }, [ id ] );


  const handleSubmit = () => {

    if ( !lieAceito ) {
      alert( "Você primeiro precisa aceitar os Termos de Uso para o envio" );
      return;
    }

    if ( lieAceito && telefone_whats ) {
      handleClick( { accept: true, telefone_whats } );
    } else {
      alert( "Por favor, preencha o telefone e aceite os termos de uso." );
    }
  };

  return (
    <Overlay>
      <ModalContainer>
        <Description>Por favor, preencha o seu WhatsApp!</Description>
        <Form>
          <FormInput>
            <Input
              type="text"
              name="telefone"
              placeholder='(00) 9 9999-9999'
              value={telefone_whats}
              onChange={( e ) => setTelefone_whats( maskPhone( e.target.value ) )}
            />
            <DivLabel>
              <InputCheckBox
                type="checkbox"
                id="lieAceito"
                name="lieAceito"
                onChange={() => setLieAceito( !lieAceito )}
              />
              <TextLabel>Li e aceito a
                <span>
                  <TextLink href={url} style={{ textDecoration: "none" }}>
                    &nbsp;Termos de Uso&nbsp;
                  </TextLink>
                </span>
                deste site.</TextLabel>
            </DivLabel>
          </FormInput>
        </Form>

        <ButtonContainer>
          <CustomButton
            onClick={() => handleClick( { accept: false } )}
            color={"#2a4f72"}
            background={"#fdd460"}
            width={"48%"}
            style={{ marginRight: '4%' }}
          >
            Fechar
          </CustomButton>
          <CustomButton
            onClick={handleSubmit}
            color={"#ffffff"}
            background={"#2a4f72"}
            width={"48%"}
          >
            Enviar
          </CustomButton>
        </ButtonContainer>
      </ModalContainer>
    </Overlay>
  );
}