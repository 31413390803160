import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Title = styled.h2`
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 70px 0px 0px 0px;
  }
`

export const Description = styled.p`
  font-size: 12px;
  color: ${colors.gray2};
  margin: 5px 0px 20px 0px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 10px 0px 30px 0px;
  }
`

export const BarGrid = styled.div`
  width: 90%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 15px;
  }
`

export const BarCard = styled.div`
  grid-column: span 4;
  height: 450px;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 1;
    display: block;
  }
`
