import validarCpf from './validaCpfCore';

function validarMeuCpf(params) {
    if (params !== '' && params !== undefined) {
        let cpf = params.replace(/\.|\-/g,'')
        const valido = validarCpf(cpf)		
        return valido        
    }
    return true
}

export default validarMeuCpf