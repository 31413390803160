import React from 'react';

import {
  Parallax,
  Flex,
  Title,
  Description
} from './lpThemeB.styles';

export default function LpThemeA() {

  return (
    <>
      <Parallax>
        <Flex>
          <Title>Solumedi Paraná Norte Pioneiro</Title>
          <Description>Unidade Apucarana</Description>
          <Description>Unidade Arapongas</Description>
          <Description>Unidade Cambé</Description>
          <Description>Unidade Ibiporã</Description>
          <Description>Unidade Londrina</Description>
        </Flex>
      </Parallax>
    </>
  )
}