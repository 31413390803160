import React from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerImg,
  BarContainerArea,
  TextAreaDiv,
  Title,
  SubTitle,
  Description

} from './bannerC.styles';

import { CustomButton } from '../CustomButton/customButton';

export default function BannerThree() {

  const history = useHistory()

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} Background={"#78cFE0"} style={{ marginTop: "30px" }} >
        <BarGrid>

          <BarContainerArea>
            <TextAreaDiv>
              <Title>ATENDIMENTO HUMANIZADO FEITO PRA VOCÊ!</Title>
              <SubTitle>Compromisso em facilitar o acesso à saúde de qualidade.</SubTitle>
              <Description>Mais humanizada e feita para você! Você já pagou tanto plano de saúde e não usou? Ou acabou não indo ao médico porque não tinha um perto de você? Isso é passado. Com a Solumedi você realiza o seu agendamento médico sem se preocupar.</Description>
              <CustomButton
                onClick={() => history.push('/unidades/buscar')}
                color={"#2a4f72"}
                background={"#fdd460"}
                margin={"10px 0px 10px 10px"}
                width={"60%"}>
                AGENDAR
              </CustomButton>
            </TextAreaDiv>
          </BarContainerArea>

          <BarContainerImg />
        </BarGrid>
      </Flex>
    </>
  );
}