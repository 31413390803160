export const clearExpression = ( data ) => {
  // Converte o texto para caixa baixa
  data = data.toLowerCase()
  // Remove qualquer caractere em branco do final do texto:
  data = data.replace( /^\s+|\s+$/g, '' );

  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç";
  const to = "aaaaaeeeeeiiiiooooouuuunc";

  for ( let i = 0, l = from.length; i < l; i++ ) {
    data = data.replace( new RegExp( from.charAt( i ), 'g' ), to.charAt( i ) );
  }
  // Remove qualquer caractere inválido que possa ter sobrado no texto:
  data = data.replace( /[^a-z0-9 -]/g, '' );
  return data
}
