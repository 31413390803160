import styled from 'styled-components';
import colors from '../../../assets/styles/colors';

export const Title = styled.h2`
  text-align: center;
  word-break: break-word;
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 70px 0px 0px 0px;
  }
`

export const Description = styled.p`
  text-align: center;
  font-size: 16px;
  color: ${props => props.color ? props.color : colors.gray2};
  margin: 5px 0px 20px 0px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 10px 0px 30px 0px;
  }
`

export const Row = styled.div`
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`

export const Column = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 40%;
  }
`

export const Container = styled.div`
  position: relative;
  margin: auto;
  text-align: justify;
`

export const Form = styled.div`
@media (min-width: 768px) {
  display: inline-flex;
  width: 100%
}
`

export const FormInput = styled.div`
@media (min-width: 768px) {
  width: 100%
}
`

export const Input = styled.input`
  border: 1px solid ${colors.gray1};
  border-radius: 50px;
  padding: 1rem 3rem;
  margin-bottom: 20px;
  width: -webkit-fill-available;
  position: relative;
  display: inline-block;
  font: inherit;
  font-size: 21px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.7);
  
  ::-webkit-input-placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  :-ms-input-placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  ::placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  @media (min-width: 768px) {
    max-width: -webkit-fill-available;
    border-radius: 50px;
  }
`

export const TextArea = styled.textarea`
  border: 1px solid ${colors.gray1};
  border-radius: 50px;
  padding: 1rem 3rem;
  margin-bottom: 20px;
  width: -webkit-fill-available;
  position: relative;
  display: inline-block;
  font: inherit;
  font-size: 21px;
  resize: vertical;
  min-height: 170px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.7);
  
  ::-webkit-input-placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  :-ms-input-placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  ::placeholder {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
  @media (min-width: 768px) {
    max-width: -webkit-fill-available;
    border-radius: 50px;
  }
`

export const InputCheckBox = styled.input`
  -webkit-appearance: none;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.7);
  width: 25px;
  height: 25px;
  /* padding: 10px; */
  border: 1px solid ${colors.gray1};
	border-radius: 50px;
	display: inline-block;
	position: relative;
  :active{
    background-color: ${colors.blue4};
  }
  :checked{
    background-color: ${colors.blue4};
  }
`

export const DivLabel = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
`

export const DivSize = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    width: 60%;
  }
`

export const TextLabel = styled.a`
   color: ${colors.blue6};
   font-size: 14px;
   font-weight: 600;
   margin-left: 5px;
`

export const TextLink = styled.a`
  cursor: pointer;
  color: ${colors.blue4};
  :hover {
    opacity: 0.7;
  }
`