import React from 'react';
import { Flex } from '../../assets/styles/global';
import {
  Title,
  Description,
  Observacoes
} from './lpSpecialties.styles';
import ContentChild from './lpListEspecialidades';
import ContentChildExams from './lpListExams';


export default function LpSpecialties( { data } ) {


  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>PRINCIPAIS ESPECIALIDADES</Title>
        <Description>Atendimento realizado nas melhores clínicas e por excelentes profissionais</Description>
      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "30px" }}>
        <ContentChild />

        <Observacoes>
          <span>
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=55${data.data.telefone_whats}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es.`}
              style={{ textDecoration: "none", color: "#007bff" }}
            > Consulte </a>
          </span>
          outras especialidades e disponibilidade de agenda em nosso atendimento.
        </Observacoes>
        {data.data.slug === "apucarana" ?
          <Observacoes style={{ color: "#ff5c55", marginTop: "5px" }}>*Consultar disponibilidade nas regiões</Observacoes>
          :
          <></>
        }

      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>PRINCIPAIS EXAMES</Title>
        <Description>Exames de Imagem e Exames Laboratoriais.</Description>
      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "30px" }}>
        <ContentChildExams />
      </Flex>
    </>
  )
}