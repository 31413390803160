import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description,
  Row,
  Column,
  Collapsible,
  TitleEsp,
  TitleLast
} from './listEspecialidades.styles';

import ContentChild from './descEspecialidades';
import Especialidades from '../../content/especialidades.json';

export default function ListEspecialidades() {

  const [showDetailsByTitulo, setShowDetailsByTitulo] = useState(null)
  const [result, setResult] = useState([])
  
  useEffect(() => {
    var array = []
    var obj = Especialidades
    for (var prop in obj) {
      var slug = prop
      var data = obj[prop]
      //var descricao = item[`text`]
      array.push({"slug": slug , "data": data})
    }
    setResult(array)
  }, [])

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>ESPECIALIDADES</Title>
        <Description>Agende agora mesmo com valores reduzidos</Description>
      </Flex>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >

        <Row>
          {result.map((item, index) => (
            <Column key={index}>
              <Collapsible onClick={() => setShowDetailsByTitulo(item.slug)}>
                <TitleEsp>
                  {item.data.title}
                </TitleEsp>
                <ContentChild
                  slug={item.slug}
                  desc={item.data.text.substring(14,120)+"..."}
                  showDetails={showDetailsByTitulo === item.slug}
                  onPressShowDetails={() => setShowDetailsByTitulo(item.slug)}
                  onPressCloseDetails={() => setShowDetailsByTitulo(null)}
                />
              </Collapsible>
            </Column>
          ))}
        </Row>

        <TitleLast>
          Não encontrou a especialidade que procura,
          <span>
            <Link to='/contato' style={{ textDecoration: "none", color: "#007ffb" }}>
              &nbsp;clique aqui!
            </Link>
          </span>
        </TitleLast>
      </Flex>
    </>
  )
}