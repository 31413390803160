import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Banner from './banner';
import BannerD from '../../components/BannerD/bannerD';
import HowTo from '../../components/HowTo/howTo';
import BannerC from '../../components/BannerC/bannerC';
import Blog from '../../components/Blog/blog';
import AgendeBar from '../../components/AgendeBar/agendeBar';

export function SobreNos() {

  const { cartao } = useParams()

  useEffect(() => {
    if(cartao){
      window.scrollTo(0, 1450)
    } else{
      window.scrollTo(0, 0)
    }
  }, [cartao]);

  return (
    <>
      <Banner />
      <BannerD />
      <BannerC />
      <HowTo />
      <Blog />
      <AgendeBar />
    </>
  );
}