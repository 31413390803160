import React, { useEffect, useState } from 'react';

import {
	Container,
	Modal,
	Title
} from './modalForm.styles';

import Form from './Form/form';
import AskFillForm from './AskFillForm/askFillForm';
import FormCard from './Card/bannerA';

export default function ModalForm({ data, setShow }) {

	const [askFillForm, setAskFillForm] = useState(true);
	const [showForm, setShowForm] = useState(false);
	const [changeOfferCard, setChangeOfferCard] = useState(false);


	const offerCard = () => {
		setAskFillForm(false)
		setShowForm(false)
		setChangeOfferCard(true)
	}

	const fillForm = () => {
		setAskFillForm(false)
		setShowForm(true)
		setChangeOfferCard(false)
	}

	return (
		<>
			<Container>
				<Modal>
					{askFillForm && <AskFillForm setShow={setShow} offerCard={offerCard} fillForm={fillForm} />}
					{showForm && <Form data={data} setShow={setShow} offerCard={offerCard} />}
					{/* {changeOfferCard && <FormCard data={data} setShow={setShow} offerCard={offerCard} />} */}
				</Modal>
			</Container>
		</>
	)
}