import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Leading,
  LeadingBigText,
  ButtonsDiv,
  CustomButton,
  PriceDivA,
  PriceDivB,
  Row,
  LeadingTextPriceA,
  LeadingTextPriceB,
  LeadingTextPriceC,
  LeadingTextPriceD,
  LeadingPrice
} from './banner.styles';

export default function Banner() {

  const history = useHistory()

  return (
    <>
      <Leading>
        <LeadingBigText>AGENDE AGORA, FÁCIL E RÁPIDO!</LeadingBigText>
        <ButtonsDiv>
          <CustomButton onClick={() => history.push( '/unidades/buscar' )}>EU QUERO</CustomButton>
        </ButtonsDiv>

        <PriceDivA>
          <LeadingTextPriceA>Consultas Médicas</LeadingTextPriceA>
          <LeadingTextPriceB>com valores reduzidos!</LeadingTextPriceB>
        </PriceDivA>

        <PriceDivB>

          <LeadingTextPriceC>Exames</LeadingTextPriceC>
          <Row>
            <LeadingTextPriceD>a partir de<span style={{ color: "#fdd460", fontWeight: "900" }}>&nbsp;R$</span></LeadingTextPriceD>
            <LeadingPrice>7</LeadingPrice>
          </Row>


        </PriceDivB>
      </Leading>
    </>
  );
}