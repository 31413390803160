import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Title = styled.h2`
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 70px 0px 0px 0px;
  }
`

export const Row = styled.div`
  justify-content: space-between;
  width: 90%;
  display: flex;
  flex-flow: row wrap;
`

export const Column = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 49%;
  }
`

export const Collapsible = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0px 25px 0px;
  outline: none;
  font-size: 21px;
  transition: all 0.2s ease-in;
  @media (min-width: 768px) {
    margin: 30px 20px 30px 20px;
  }
`

export const DivImg = styled.div`
  width: 35%;
  @media (min-width: 768px) {
    width: 25%;
  }
`

export const DivText = styled.div`
  width: 60%;
  @media (min-width: 768px) {
    width: 70%;
  }
`

export const ThumbImg = styled.div`
  cursor: pointer;
  float: unset;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background: url(${props => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: ${colors.blue4}; */
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
  :hover {
    opacity: 0.7;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.5);
  }
  @media (min-width: 768px) {
    float: right;
  }

`

export const TitleEsp = styled.h3`
  word-break: break-word;
  color: ${colors.blue6};
  font-weight: 300;
  font-size: 18px;
  margin: 0px;
  @media (min-width: 768px) {
    word-break: break-word;
  }
`

export const TextContent = styled.p`
  word-break: break-word;
  color: ${colors.gray2};
  font-weight: 300;
  font-size: 10px;
  margin: 0px;
  @media (min-width: 768px) {
    word-break: break-word;
  }
`

export const TextDate = styled.span`
  word-break: break-word;
  color: black;
  font-weight: 300;
  font-size: 10px;
  margin: 0px 5px 0px 0px;
  @media (min-width: 768px) {
    word-break: break-word;
  }
`

export const TitleLast = styled.h3`
  word-break: break-all;
  color: ${colors.red1};
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  margin: 20px 5px 0px 5px;
  @media (min-width: 768px) {
    margin: 20px 0px 0px 0px;
    word-break: break-word;
  }
  :hover{
    opacity: 0.7;
  }
`