export const checkAge = ( value ) => {
    if ( !value ) return -1

    let info = value.split( '-' ).join( '-' );
    console.log( 'info', info )
    let today = new Date()
    let birthDay = new Date( info )

    let age = Math.floor(
        ( today - birthDay ) / ( 365.25 * 24 * 60 * 60 * 1000 )
    );

    return age
}
