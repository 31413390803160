import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const Title = styled.h2`
  text-align: center;
  word-break: break-word;
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 10px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 70px 0px 0px 0px;
  }
`

export const SubTitle = styled.h3`
  word-break: break-word;
  font-size: 18px;
  color: ${colors.blue4};
  margin: 25px 10px 0px 0px;
  @media (min-width: 768px) {
    font-size: 30px;
    margin: 30px 0px 0px 0px;
  }
`

export const Row = styled.div`
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

export const ColumnImg = styled.div`
  background: url(${props => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
    @media (min-width: 768px) {
      width: 40%;
      height: 400px;
    }
`

export const Column = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 55%;
  }
`

export const Container = styled.div`
  position: relative;
  margin: auto;
  text-align: justify;
`

export const TextLink = styled.a`
  cursor: pointer;
  color: ${colors.blue4};
`