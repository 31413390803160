import React from 'react';
import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description,
} from './gridBlog.styles';

import Blog from '../../components/Blog/blog';

export default function GridBlog() {
  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{marginTop: "20px", marginBottom: "20px"}} >
        <Title>POSTAGENS RECENTES!</Title>
        <Description>Informações e dicas de saúde, bem-estar e muito mais</Description>
      </Flex>
      <Blog />
    </>
  );
}