import React from 'react';

import { Flex } from '../../../assets/styles/global';

import {
  MaxContainer,
  Container,
  Title,
  Description
} from './lpMapa.styles';

import { CustomButton } from '../../../components/CustomButton/customButton';

export default function LpMapa( { data } ) {

  const seeLocal = () => {
    window.open( "https://solumedi.com.br/sobrenos", "_blank" )
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginTop: "20px", marginBottom: "40px" }}>
        <MaxContainer>
          <Container>
            <Title>VENHA PARA A SOLUMEDI</Title>
            <Description>A Solumedi surgiu da ideia de conectar clínicas particulares com pessoas que buscam atendimento médico de qualidade. Não realizamos nenhum tipo de cobrança recorrente e o usuário efetua somente o pagamento do agendamento. O que faz com que a Solumedi tenha o melhor CUSTO x BENEFÍCIO no ramo da saúde.</Description>
            {/* <CustomButton
              onClick={seeLocal}
              margin={"10px 0px 10px 0px"}
              width={"60%"}>SAIBA MAIS</CustomButton> */}
          </Container>
        </MaxContainer>
      </Flex>
    </>
  );
}