import styled from 'styled-components';
import colors from '../WhatsAppButton/colors';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  width: auto%;
  min-height: 25%;
  height: auto;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;

 @media (max-width: 768px) {
    width: 85%;
    max-width: 90%;
    padding: 15px;
    overflow-x: hidden;
  }
`;


export const Description = styled.p`
  text-align: center;
  font-size: 22px;
  color: ${colors.gray2};
  margin: 5px 0px 10px 0px;
  @media (min-width: 768px) {
    font-size: 28px;
    margin: 10px 0px 10px 0px;
  }
`;

export const Form = styled.div`
  @media (min-width: 768px) {
    display: inline-flex;
    width: 100%
  }
`;

export const FormInput = styled.div`
  @media (min-width: 768px) {
    width: 100%
  }
`;

export const Input = styled.input`
  border: 2px solid ${colors.gray1};
  border-radius: 50px;
  padding: 1rem 3rem 1rem 3rem;
  margin-bottom: 20px;
  width: auto;
  min-width: 86%;
  position: relative;
  display: inline-block;
  font: inherit;
  font-size: 24px;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.7);
  
 @media (max-width: 768px) {
    width: 100%;
    min-width: auto;
    box-sizing: border-box;
  }

  ::placeholder {
    font-size: 20px;
    font-weight: 300;
    font-style: italic;
    color: ${colors.gray1};
  }
`;

export const InputCheckBox = styled.input`
  -webkit-appearance: none;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.7);
  width: 30px;
  height: 30px;
  border: 2px solid ${colors.gray1};
  border-radius: 50px;
  display: inline-block;
  position: relative;
  
  :active, :checked {
    background-color: ${colors.blue4};
  }
`;

export const DivLabel = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;

export const TextLabel = styled.span`
   color: ${colors.blue6};
   font-size: 18px;
   font-weight: 600;
   margin-left: 5px;
`;

export const TextLink = styled.a`
  cursor: pointer;
  color: ${colors.blue4};
  font-size: 18px;
  :hover {
    opacity: 0.7;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 768px){
  }
`;