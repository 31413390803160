import axios from 'axios';

export const requestForm = async ( phoneNumber ) => {
    if ( !phoneNumber ) return

    // N8N REQUEST
    var url = "https://n8n.solucode.com.br/webhook/4c16cbf3-34d1-4522-80ab-6b051f94e187";
    await axios.post( url, { phone: phoneNumber, date: new Date() } )
    return
}
