import React from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerVideo,
  BarContainerArea,
  Title,
  SubTitle,
  Description,
  Card

} from './examesBanner.styles';

import { CustomButton } from '../CustomButton/customButton';
import ExamesImg from '../../assets/image/especialidadeExemplo2.png';

export default function ExamesBanner() {

  const history = useHistory()

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginTop: "40px", marginBottom: "30px" }}>
        <BarGrid>

          <BarContainerArea>
            <Title>AGENDE SEUS EXAMES NA SOLUMEDI</Title>
            <SubTitle>Faça seus exames de imagem ou laboratoriais nas melhores clínicas particulares.</SubTitle>
            <Description>Fazer agendamento do seu exame nunca foi tão simples. A Solumedi oferece uma quantidade abrangente de exames, nas principais especialidades médicas.</Description>
            <Description>Marque seu exame de forma ágil e fácil, fazendo agendamento com excelentes profissionais de diversas especialidades.</Description>
            <CustomButton
              onClick={() => history.push('/unidades/buscar')}
              color={"#2a4f72"}
              background={"#fdd460"}
              margin={"10px 0px 0px 25px"}
              width={"60%"}
            >QUERO AGENDAR</CustomButton>
          </BarContainerArea>

          <BarContainerVideo>
            <Card url={ExamesImg}></Card>
          </BarContainerVideo>

        </BarGrid>
      </Flex>
    </>
  );
}