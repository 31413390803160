import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { Header } from '../../components/LpHeader/header';
import { Footer } from '../../components/LpFooter/footer';
import { FooterPersonality } from '../../components/LpFooter/footerPersonality';

import ModalForm from '../../components/ModalForm/modalForm';
import ModalFormSJC from '../../components/ModalFormSJC/modalFormSJC';
//import ModalFormCard from '../../components/ModalFormCard/modalForm';
import LpBannerA from '../../components/LpBannerA/lpBannerA';
import LpBannerAA from './LpBannerAA/lpBannerAA';
//import LpBannerBelem from './LpBannerBelem/lpBannerBelem';

import LpImage from '../../components/LpImage/lpImage';
import LpImageFull from '../../components/LpImageFull/lpImageFull';
import LpImages from '../../components/LpImages/lpImages';

//import LpCampaignOutubroJSC from '../../components/LpCampaignOutubroSJC/campaign';
//import LpCampaignNovembroJSC from '../../components/LpCampaignNovembroSJC/campaign';
//import LpCampaignNovembroLp from '../../components/LpCampaignNovembroLp/campaign';

import LpCardPromotion from '../../components/LpCardPromotion/lpCardPromotion';
import LpCardPromotionSJC from '../../components/LpCardPromotionSJC/lpCardPromotionSJC';
import LpMapa from '../../components/LpMapa/lpMapa';
import AgendeBoard from '../../components/AgendeBoard/agendeBoard';
import Testimonials from '../../components/Testimonials/testimonials';
//import TestimonialsORIGIN from '../../components/TestimonialsORIGIN/testimonials';
import GridEspecialidades from '../../components/GridEspecialidades/gridEspecialidades';
import GridAddress from '../../components/LpGridAddress/gridAddress';
import LpPriceBoard from '../../components/LpPriceBoard/lpPriceBoard';
import CarouselExames from '../../components/CarouselExames/carouselExames';

//import {AlertNotification} from '../../components/AlertNotification/alertNotification';

export default function ModelA( { data } ) {
  const { id } = useParams();

  const [ onTestimonials, setOnTestimonials ] = useState( true )
  const [ infoPrice, setInfoPrice ] = useState( "85" )

  useEffect( () => {
    const jivoScript = document.createElement( "script" );
    document.body.appendChild( jivoScript );
    jivoScript.src = "//code.jivosite.com/widget/5k5QOpGR9N";

    return () => {
      document.body.removeChild( jivoScript );
    }
  }, [] )

  useEffect( () => {
    switch ( true ) {
      case id === "pinhais":
        break;

      default:
    }
  }, [ data ] )

  return (
    <>
      <Header email={data.data.email_adm} />
      < LpBannerAA data={data} infoPrice={infoPrice} />

      < LpMapa data={data} />

      <AgendeBoard data={data} infoPrice={infoPrice} />

      {onTestimonials && < Testimonials />}
      < GridEspecialidades />
      <CarouselExames data={data} />

      <Footer data={data} />
    </>
  );
}