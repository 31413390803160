import React, { useEffect, useState } from 'react';

import { Flex } from '../../../assets/styles/global';
import {
  MainRow,
  Row,
  Column,
  ColumnImg,
  Container,
  Title,
  SubTitle,
  DescriptionA,
  DescriptionAA,
  DescriptionB,
  RowPrice,
  DescriptionPrice
} from './lpBannerAA.styles'

import UrlImagem from '../../../assets/image/s-bg.png';
import UrlImagem2 from '../../../assets/image/especialidadeExemplo1.webp';

import { CustomButton } from '../../../components/CustomButton/customButton';
import { ContactWAForm } from '../../../components/ContactForm/contactWAForm';
import { requestForm } from '../../../helpers/requestForm';
import { timeDate } from '../../../helpers/timeDate';

export default function LpBannerA( { data, infoPrice, setShow } ) {

  const [ telegram, setTelegram ] = useState( false );
  const [ onNoValue, setOnNoValue ] = useState( false )
  const [ textTitle, setTextTitle ] = useState( "SOLUMEDI" )
  const [ textMogiDasCruzes, setTextMogiDasCruzes ] = useState( false )
  const [ textA, setTextA ] = useState( "Atendemos mais de 30 especialidades com valores reduzidos!" )
  const [ textButton, setTextButton ] = useState( "AGENDAMENTO VIA WHATS" )

  const [ showContactForm, setShowContactForm ] = useState( false )

  useEffect( () => {

    if ( data.data.slug === "mogidascruzes" ) {
      setTextTitle( "Conheça a Solumedi!" )
      setTextMogiDasCruzes( true )
      setOnNoValue( true )
      setTextA( "Sem mensalidade, sem anuidade e sem taxa de adesão." )

    }

  }, [ data ] )

  const sendWhatsApp = () => {
    if ( telegram ) {
      window.open( `https://instagram.com/solumedimossoro`, "_blank" )
    } else {
      window.open( `https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace( /[^\d]+/g, '' )}&text=${data.data.mensagem_whats}`, "_blank" )
    }
  }

  const handleClick = ( params ) => {
    setShowContactForm( false )
    if ( !params?.accept ) return
    // SEND REQUEST
    requestForm( params.telefone_whats )
    sendWhatsApp()
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
        <MainRow>
          <Column>
            <Container background={UrlImagem2}></Container>
          </Column>

          <ColumnImg background={UrlImagem}>
            <Row>
              <Title>{textTitle}</Title>
            </Row>
            <Row>

              <Row>
                {textMogiDasCruzes &&
                  <DescriptionA>Consultas, Exames e Cirurgias com preços<span style={{ color: "#fdd460", fontWeight: "bold" }}> MAIS ACESSÍVEIS</span> em Clínicas Particulares de Mogi das Cruzes.</DescriptionA>
                }
              </Row>

              {onNoValue ?
                <>
                  <RowPrice>
                    <DescriptionA><small>Atendemos mais de 30 especialidades com valores reduzidos!</small></DescriptionA>
                  </RowPrice>
                </>
                :
                <>
                  <RowPrice>
                    <DescriptionA>a partir de</DescriptionA>
                    <DescriptionPrice>R${infoPrice}</DescriptionPrice>
                    {data.data.slug === 'belem' && <DescriptionPrice style={{ margin: '0px' }}><small><small>,00</small></small></DescriptionPrice>}

                  </RowPrice>
                </>
              }
            </Row>

            <Row>
              <Row>
                <DescriptionB>{textA}</DescriptionB>
              </Row>

              <Row>
                {data.data.slug === "joinville" ?
                  <>
                    <DescriptionB>Clique no botão abaixo e fale conosco pelo <strong>WhatsApp</strong>. Ou ligue <br></br><strong>{data.data.telefone_fixo}</strong></DescriptionB>
                  </>
                  :
                  <>
                    <DescriptionB>Clique no botão abaixo e fale conosco pelo <strong>WhatsApp</strong> ou ligue <br></br><strong>{data.data.telefone_fixo}</strong></DescriptionB>
                  </>
                }
              </Row>
            </Row>

            <Row>
              <CustomButton
                onClick={() => sendWhatsApp()}
                color={"#2a4f72"}
                background={"#fdd460"}
                width={"70%"}>{textButton}</CustomButton>
            </Row>
            {data.data.slug === "belem" &&
              <>
                <Row>
                  <CustomButton
                    onClick={() => setShow()}
                    color={"#fdd460"}
                    background={"#2a4f72"}
                    width={"70%"}>CADASTRO GRATUITO</CustomButton>
                </Row>
              </>
            }

          </ColumnImg>
        </MainRow>

        {showContactForm ? <ContactWAForm handleClick={handleClick} /> : <></>}

      </Flex>
    </>
  );
}
