import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description,
  BarGrid,
  BarCard,
  MainDiv,
  DivNumber,
  DivText,
  NumberText,
  MensagemText

} from './table.styles';

export default function Table() {
  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <BarGrid>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>TERMO</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText sytle={{ fontWeight: "900" }}>
                  CONCEITO
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Dado Pessoal</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                É uma informação relacionada a uma pessoa física e que seja capaz de identificar a pessoa ou tornar possível a sua identificação.
                São exemplos de dados pessoais que podem permitir a sua identificação: Nome, CPF, telefone, e-mail, placa do seu veículo etc.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Tratamento</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                É toda forma de uso que podemos fazer dos seus Dados Pessoais, incluindo, mas não se limitando às seguintes atividades: coleta, armazenamento, consulta, uso, compartilhamento, transmissão, classificação, reprodução, exclusão e avaliação. 
                Este termo (e outros derivadas dele) não serão utilizados com letras maiúsculas neste documento.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Titular</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                É você, a pessoa física a quem os dados pessoais se referem.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Empresa</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Somos nós, a Solumedi.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Política</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                É esta Política de Privacidade.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          
        </BarGrid>

      </Flex>

    </>
  );
}