import React, { useEffect, useState } from 'react';

import {
	Container,
	Modal,
	Title
} from './modalForm.styles';

import Form from './Form/form';
import AskFillForm from './AskFillForm/askFillForm';
import FormCard from './Card/bannerA';


export default function ModalForm({ data, setShow }) {

	const [askFillForm, setAskFillForm] = useState(false);
	const [showForm, setShowForm] = useState(true);
	const [changeOfferCard, setChangeOfferCard] = useState(false);
	
	const sendWhatsApp = () => {
		{
		  window.open( `https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace( /[^\d]+/g, '' )}&text=Ol%C3%A1,%20aqui%20%C3%A9%20a%20Solfia`, "_blank" )
		  return
		}
	}	

	const offerCard = () => {
		setShowForm(true);
		sendWhatsApp();
	}
	
	const fillForm = () => {
		setAskFillForm(false)
		setShowForm(true)
		setChangeOfferCard(false)
	}
	
	return (
		<>
			<Container>
				<Modal>
					{askFillForm && <AskFillForm setShow={setShow} offerCard={offerCard} fillForm={fillForm}/>}
					{showForm && <Form data={data} setShow={setShow} offerCard={offerCard} />}
					{changeOfferCard && <FormCard data={data} setShow={setShow} offerCard={offerCard} />}
				</Modal>
			</Container>
		</>
	)
}