import React, { useEffect, useState } from 'react';

import { Flex } from '../../../assets/styles/global';
import {
  Section,
  MainRow,
  Row,
  Column,
  ColumnImg,
  Container,
  ContainerTati,
  Title,
  SubTitle,
  DescriptionA,
  DescriptionAA,
  DescriptionAAA,
  DescriptionB,
  RowPrice,
  DescriptionPrice,
  Space
} from './lpBannerSalvador.styles'

import UrlImagem from '../../../assets/image/s-bg.png';
import UrlImagem3 from '../../../assets/image/home-page-bg-banner.jpg';
import { CustomButton } from './CustomButton/customButton';
import { ContactWAForm } from '../../../components/ContactForm/contactWAForm';
import { requestForm } from '../../../helpers/requestForm';


export default function LpBannerSalvador( { data, infoPrice, setShow } ) {


  const [ onNoValue, setOnNoValue ] = useState( false )
  const [ textOne, setTextOne ] = useState( "Consultas" )
  const [ textTwo, setTextTwo ] = useState( "& Exames" )
  const [ textB, setTextB ] = useState( "Atendemos mais de 30 especialidades com valores reduzidos!" )
  const [ textButton, setTextButton ] = useState( "AGENDAMENTO VIA WHATS" )

  const [ showContactForm, setShowContactForm ] = useState( false )

  useEffect( () => {
    if (
      data.data.slug === "salvador" ||
      data.data.slug === "saobentodosul" ||
      data.data.slug === "arapongas" ||
      data.data.slug === "cachoeira-almirantetamandare" ||
      data.data.slug === "cachoeira-curitiba"
    ) {
      setOnNoValue( true )
      setTextOne( '' )
      setTextTwo( '' )
      setTextB( 'Sem mensalidade, sem anuidade ou taxa de adesão.' )
      setTextButton( "AGENDE AGORA" )
    }
  }, [ data ] )

  const sendWhatsApp = () => {
    window.open( `https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace( /[^\d]+/g, '' )}&text=${data.data.mensagem_whats}`, "_blank" )
  }

  const handleClick = ( params ) => {
    setShowContactForm( false )
    if ( !params?.accept ) return
    // SEND REQUEST
    requestForm( params.telefone_whats )
    sendWhatsApp()
  }

  return (
    <Section id="contato">
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
        <MainRow>
          <Column>
            <ContainerTati background={UrlImagem3}></ContainerTati>
          </Column>

          <ColumnImg background={UrlImagem}>
            <Row>
              <Title>Agende Agora, Fácil e Rápido em {data.data.nomefantasia}</Title>
            </Row>
            <Row>

              <Row>
                <SubTitle>{textOne}</SubTitle>
              </Row>
              <Row>
                <SubTitle>{textTwo}</SubTitle>
              </Row>
              {/* {data.data.slug === 'salvador' && <DescriptionA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$7<small><small>,00</small></small></span> e Consultas Médicas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}> R$75<small><small>,00</small></small></span> com valores acessíveis e atendimento realizado em Clínicas Particulares Especializadas.</DescriptionA>} */}
              {data.data.slug === 'salvador' && <DescriptionAAA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$7<small><small>,00</small></small></span> e Consultas Médicas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}> R$97<small><small>,00</small></small></span> realizado em Clínicas Particulares Especializadas.</DescriptionAAA>}
              {data.data.slug === 'saobentodosul' && <DescriptionAAA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$5<small><small>,00</small></small></span> e Consultas Médicas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}> R$65<small><small>,00</small></small></span> realizado em Clínicas Particulares Especializadas.</DescriptionAAA>}
              {data.data.slug === 'arapongas' && <DescriptionAAA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$7<small><small>,00</small></small></span> e Consultas Médicas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}> R$60<small><small>,00</small></small></span> realizado em Clínicas Particulares de Arapongas.</DescriptionAAA>}
              {data.data.slug === 'cachoeira-almirantetamandare' && <DescriptionAAA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$7<small><small>,00</small></small></span> e Consultas Médicas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}> R$75<small><small>,00</small></small></span> realizado em Clínicas Particulares em Almirante Tamandaré.</DescriptionAAA>}
              {data.data.slug === 'cachoeira-curitiba' && <DescriptionAAA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$7<small><small>,00</small></small></span> e Consultas Médicas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}> R$75<small><small>,00</small></small></span> realizado em Clínicas Particulares em Curitiba.</DescriptionAAA>}
            </Row>

            <Row>
              <Row>
                <DescriptionB>{textB}</DescriptionB>
              </Row>
              <Row>
                <Space></Space>
                {data.data.slug === 'salvador' && <DescriptionB><span style={{ color: "#fdd460", fontWeight: "bold" }}>Não somos um plano de saúde, nem convênio, nem uma clínica. Não atendemos nenhum convênio ou cartão de descontos e benefícios.</span></DescriptionB>}
                {data.data.slug === 'arapongas' && <DescriptionB><span style={{ color: "#fdd460", fontWeight: "bold" }}>Não atendemos convênios e planos de saúde.</span></DescriptionB>}
              </Row>
              <Space></Space>
              <Row>
                {data.data.slug === 'salvador' && <DescriptionB>Clique no botão abaixo e agende pelo <strong>ZAP</strong> ou ligue <strong>{data.data.telefone_whats}</strong></DescriptionB>}
                {data.data.slug === 'saobentodosul' && <DescriptionB>Clique no botão abaixo e fale conosco pelo Whatsapp ou ligue <strong>{data.data.telefone_whats}</strong></DescriptionB>}
                {data.data.slug === 'arapongas' && <DescriptionB>Clique no botão abaixo e fale conosco pelo Whatsapp ou ligue <strong>{data.data.telefone_whats}</strong></DescriptionB>}
                {data.data.slug === 'cachoeira-almirantetamandare' && <DescriptionB>Clique no botão abaixo e fale conosco pelo Whatsapp ou ligue <strong>{data.data.telefone_whats}</strong></DescriptionB>}
                {data.data.slug === 'cachoeira-curitiba' && <DescriptionB>Clique no botão abaixo e fale conosco pelo Whatsapp ou ligue <strong>{data.data.telefone_whats}</strong></DescriptionB>}
              </Row>
            </Row>

            <Row>
              <CustomButton
                onClick={() => sendWhatsApp()}
                color={"#2a4f72"}
                background={"#fdd460"}
              >AGENDE AGORA</CustomButton>
              {/* <span itemProp="telephone">
                <CustomButton
                  onClick={() => window.location.href = `tel:${data.data.telefone_whats}`}
                  color={"#fdd460"}
                  background={"#2a4f72"}
                >LIGUE AGORA</CustomButton>
              </span> */}
            </Row>
          </ColumnImg>
        </MainRow>

        {showContactForm ? <ContactWAForm handleClick={handleClick} /> : <></>}

      </Flex>
    </Section>
  );
}
