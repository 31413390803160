import styled from 'styled-components';

export const Flex = styled.div`
    display: flex;
    align-items: ${props => props.AlignItems};
    justify-content: ${props => props.JustifyContent};
    flex-direction: ${props => props.FlexDirection};
    background: ${props => props.Background};
    width: 100%;
    height: 100%;

`

export const BoxContent = styled.div`
        height: calc(100vh - 132px);
    overflow-y: auto;
    margin-bottom: 16px;
}
`