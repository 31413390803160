import styled from 'styled-components';

export const BarContainerSlide = styled.div`
  grid-column: span 3;
  /* height: 300px; */
  /* display: block; */
  @media (min-width: 768px) {
    grid-column: span 3;
    /* display: block; */
    /* height: 200px; */
  }
`