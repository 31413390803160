import React from 'react';

export function DSetInnerHTML({ data }) {

  function createMarkup() {
    return { __html: data };
  }

  return (
    <div dangerouslySetInnerHTML={createMarkup()} />
  )
}