import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description,
  BarGrid,
  BarCard,
  MainDiv,
  DivNumber,
  DivText,
  NumberText,
  MensagemText

} from './table2.styles';
import colors from '../../assets/styles/colors';

export default function Table() {
  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <BarGrid>

          <BarCard>
            <MainDiv>
              <DivNumber style={{ backgroundColor: `${colors.gray0}` }}>
                <NumberText style={{ fontWeight: "bold" }}>Finalidades de Tratamento</NumberText>
              </DivNumber>
              <DivText style={{ backgroundColor: `${colors.gray0}` }}>
                <MensagemText style={{ fontWeight: "bold" }}>
                Tipos de Dados Pessoais Utilizados
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>EXECUÇÃO DO CONTRATO</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Nome, endereço, telefone, estado civil, CPF, RG, CNH, data de nascimento, e-mail, dado cônjuge (os mesmos), serasa, profissão, filiação (nome de pai e mãe), telefones de terceiros, renda, naturalidade, nacionalidade, regime de bens, escolaridade, endereço comercial, descrição de bens
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>CONSENTIMENTO</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Nome, endereço, telefone, CPF, RG, CNH, e-mail
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

        </BarGrid>

      </Flex>
    </>
  );
}