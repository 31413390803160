import React, { useEffect, useState } from 'react';

import { Flex } from '../../assets/styles/global';
import {
  MainRow,
  Column,
  Container
} from './lpImageFull.styles'


import urlSaojosedoscampos from '../../assets/image/lpImage/saojosedoscamposUltrassom.png';

export default function LpBannerA({ data }) {

  const [urlBG, setUrlBG] = useState();

  useEffect(() => {

    if (data.data.slug === "saojosedoscampos") {
      setUrlBG(urlSaojosedoscampos)
    }

  }, [data])

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <MainRow>
          <Column>
            <Container background={urlBG}></Container>
          </Column>
        </MainRow>
      </Flex>
    </>
  );
}