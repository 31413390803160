import React from 'react';
import { Link } from 'react-router-dom';

import {
  Leading,
  LeadingBigText,
  ButtonsDiv,
  CustomButton,
  PriceDivA,
  LeadingTextPriceA
} from './banner.styles';

export default function Banner() {

  return (
    <>
      <Leading>
        <LeadingBigText>Obrigado pelo seu interesse na SOLUMEDI</LeadingBigText>
        
        <PriceDivA>
          <LeadingTextPriceA>Em breve, um representante vai entrar em contato com você!</LeadingTextPriceA>
        </PriceDivA>

        <ButtonsDiv>
        <Link to='/' style={{ textDecoration: "none" }}>
          <CustomButton>Ir para o site completo</CustomButton>
          </Link>
        </ButtonsDiv>
      </Leading>
    </>
  );
}