export const dateString = (date) => {
    
  if (!date) return '';

  if (date.length > 10) date = date.substring(0, 10)

  let v = date.replace(/\D/g, '').slice(0, 10);
  v = `${v.slice(6)}/${v.slice(4, 6)}/${v.slice(0, 4)}`;

let info = v.split('/').reverse().join('/');

let newCurrentDate = new Date(info)

const day = newCurrentDate.getDate()
const month = newCurrentDate.getMonth()
const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
const year = newCurrentDate.getFullYear()

let thisString = `${day} de ${months[month]} de ${year}`
return thisString

}