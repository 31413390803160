import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const TitleA = styled.p`
  cursor: pointer;
  text-align: center;
  word-break: break-word;
  font-size: 24px;
  font-weight: 700;
  color: ${colors.gray2};
  margin: 25px 15px 25px 15px;
  @media (min-width: 768px) {
    margin: 35px 0px 35px 0px;
    font-size: 40px;
  }
  :hover{
    opacity: 0.7;
  }
`

export const TitleB = styled.p`
  text-align: right;
  cursor: pointer;  
  word-break: break-word;
  font-size: 21px;
  font-weight: 700;
  color: ${colors.blue6};
  margin: 50px 15px 0px 0px;
  @media (min-width: 768px) {
    text-align: left;
    margin: 50px 0px 25px 0px;
    font-size: 40px;
  }
  :hover{
    opacity: 0.7;
  }
`

export const MainDiv = styled.div`
  width: 100%;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
`

export const ColumnA = styled.div`
  float: left;
  width: 50%;
  padding: 0px;
  height: 200px;
  @media (min-width: 768px) {
    height: 420px;
  }
`

export const ColumnB = styled.div`
  float: left;
  width: 50%;
  padding: 0px;
  height: 200px;
  @media (min-width: 768px) {
    width: 70%;
    height: 420px;
  }
`

export const CartaoSolumediImg = styled.img`
  margin-left: 10px;
  height: 210px; 
  @media (min-width: 768px) {
    margin-left: 70px;
    height: 500px;
  }
`

export const DivOff = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
}
`

export const Description = styled.p`
  
  text-align: justify;
  font-size: 21px;
  color: ${colors.blue5};
  margin: 25px 50px 0px 0px;
  
`