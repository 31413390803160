import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import SBoxBlog from '../../assets/image/sBoxBlog.png';

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: -webkit-fill-available;
  background: url(${props => props.url ? props.url : SBoxBlog }) center no-repeat;
  background-size: cover;
`

export const CardAfter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(${colors.blue6}, rgba(0, 0, 0, .3));
  transition: all 0.2s ease-in;
  :hover{
    background: rgba(0, 0, 0, .1);
  }
`

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  padding: 2px 16px;
`

export const TitleH4 = styled.h4`
  font-size: 21px;
  font-weight: 700;
  color: white;
  margin: 5px 0px 0px 0px;
`

export const TextP = styled.p`
  font-size: 14px;
  color: white;
  margin: 5px 0px 10px 0px;
`

export const ButtonDiv = styled.div`
  text-align: center;
`