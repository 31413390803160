import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import emailjs from 'emailjs-com';


import {
	Description,
	InputCheckBox,
	DivLabel,
	TextLabel,
	TextLink
} from './form.styles'
import colors from '../../../assets/styles/colors';

export default function ContactUs({ data, setShow, offerCard }) {
	const form = useRef();
	const history = useHistory();

	const [lieAceito, setLieAceito] = useState(false)
	const [nome, setNome] = useState("")
	const [email, setEmail] = useState("")
	const [telefone, setTelefone] = useState("")
	const [mailTo, setMailTo] = useState()
	const [url, setUrl] = useState()
	const [sending, setSending] = useState(false)

	useEffect(() => {
		setUrl(`/politica-de-privacidade/${data.data.slug}`)
		setMailTo(data.data.email_adm)
		console.log(setShow)
	}, [])

	const sendEmail = (e) => {
		e.preventDefault();

		setSending(true)

		if (lieAceito === false) {
			alert("Você primeiro precisa aceitar os Termos de Uso para o envio")
			setSending(false)
			return
		}

		 if (  email === "" ) {
			alert("Por favor, preencha todos os campos para o envio")
			setSending(false)
			return
		} 

		
		
		/* Comentado o código real de envio
		emailjs.sendForm(
			'service_g5n3u9q',
			'template_x1zaqio',
			form.current,
			'user_phJII5OS7CBLFVUrmf54E'
		)
		.then((result) => {
			setSending(false);
			offerCard();
			// window.open(`https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace(/[^\d]+/g, '')}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Solumedi.`, "_blank");
		}, (error) => {
			setSending(false);
			setShow();
			alert("Houve um erro, por favor, tente novamente");
			console.log(error.text);
		});
		*/

		// Simulação de envio
		setTimeout(() => {
			setSending(false);
			offerCard();
			 // window.open(`https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace(/[^\d]+/g, '')}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Solumedi.`, "_blank");
		}, 1000);
	};

	return (
		<>
			{sending ?
				<>
					<Description>Enviando...</Description>
				</>
				:
				<>
				<Description>Por favor, preencha os seus dados!</Description>
				<form
            		ref={form}
            		onSubmit={sendEmail}
            		style={{
              		width: "90%",
              		height: "", // Ajuste a altura conforme necessário
              		minHeight: "250px" // Defina uma altura mínima
            	}}
          	  >
				<input hidden type="text" name="mailTo" value={mailTo} />

						<input
							type="email"
							name="email"
							placeholder='E-mail'
							onChange={(e) => setEmail(e.target.value)}
							style={{
								border: `1px solid ${colors.gray1}`,
								borderRadius: "50px",
								padding: "1rem 3rem",
								marginBottom: "20px",
								width: "-webkit-fill-available",
								position: "relative",
								display: "inline-block",
								font: "inherit",
								fontSize: "21px",
								boxShadow: "0 4px 8px 0 rgba(0,0,0,0.7)"
							}}
						/>
						<DivLabel>
							<InputCheckBox
								type="checkbox"
								id="lieAceito"
								name="lieAceito"
								onChange={() => setLieAceito(!lieAceito)}
							/>
							<TextLabel>Li e aceito a
								<span>
									<TextLink
										href={url}
										style={{ textDecoration: "none" }}
									>
										&nbsp;Termos de Uso&nbsp;
									</TextLink>
								</span>
								deste site.</TextLabel>
						</DivLabel>
						<input
							type="submit"
							value="CONTINUAR"
							id="conversaocuritibaruibarbosa"
							maxLength="11"
							style={{
								borderRadius: "50px",
								marginTop: "10px",
								padding: "1rem 3rem",
								marginBottom: "20px",
								width: "-webkit-fill-available",
								position: "relative",
								display: "inline-block",
								font: "inherit",
								fontSize: "21px",
							}}
						/>
					</form>
				</>
			}
		</>
	);
};