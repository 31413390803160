import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const BarGrid = styled.div`
  width: 90%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 30px;
  }
`

export const BarCard = styled.div`
  grid-column: span 1;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 1;
    display: block;
  }
`

export const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 25px;
`

export const DivNumber = styled.div`
  width: 40%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DivImage = styled.div`
  width: 110px;  
  height: 110px;
  border-radius: 50%;
  background: url(${props => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.7);
  @media (min-width: 768px) {
    width: 180px;
    height: 180px;
}
`

export const DivText = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const SubTitleText = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 21px;
  font-weight: 700;
  color: ${colors.blue5};
  margin: 0px 10px 0px 10px;
  @media (min-width: 768px) {
    font-size: 30px;
  }
`

export const MensagemText = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 15px;
  color: ${colors.blue6};
  margin: 10px 10px 0px 10px;
  @media (min-width: 768px) {
    font-size: 21px;
  }
`