import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import examesBanner from '../../assets/image/examesBanner.jpg';


export const BarGrid = styled.div`
  width: 100%;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const BarContainerVideo = styled.div`
  grid-column: span 3;
  height: 300px;
  display: flex;
  margin-top: 100px;
  @media (min-width: 768px) {
    margin-top: 0px;
    height: 400px;
  }
`

export const Card = styled.div`
  width: 100%;
  height: 300px;
  background: url(${examesBanner}) center no-repeat;
  background-size: 75%;
  align-self: end;
  @media (min-width: 768px) {
    background-size: 90%;
    height: 400px;
  }
`

export const BarContainerArea = styled.div`
  grid-column: span 3;  
  height: 400px;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 3;
    display: block;
  }
`

export const Title = styled.p`
  word-break: break-word;
  font-size: 40px;
  font-weight: 700;
  color: ${colors.blue6};
  margin: 0px 5px 15px 25px;
`

export const SubTitle = styled.p`
  word-break: break-word;
  font-size: 24px;
  font-weight: 700;
  color: ${colors.blue3};
  margin: 5px 5px 0px 25px;
  @media (min-width: 768px) {
    margin: 5px 5px 15px 25px;
  }
`

export const Description = styled.p`
  word-break: break-word;
  font-size: 14px;
  color: ${colors.blue6};
  margin: 10px 15px 15px 25px;
`