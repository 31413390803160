import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  Row,
  Column,
  Collapsible,
  TitleEsp,
  TitleLast
} from './faqRow.styles';

import ContentChild from './faqAnswer';


export default function FAQRow() {

  const [ showDetailsByTitulo, setShowDetailsByTitulo ] = useState( null )

  const result = [
    {
      id: 1,
      titulo: "O que é a Solumedi?",
      descricao: "A Solumedi nasceu para facilitar o agendamento de consultas e exames. Como não somos plano de saúde não cobramos mensalidades, anuidades ou taxa de adesão. A Solumedi traz até você tratamentos em várias especialidades e diversos exames, com atendimento rápido junto a parceiros particulares por valores reduzidos."
    },
    {
      id: 2,
      titulo: "Como funciona?",
      descricao: "O usuário faz um cadastro gratuito e realiza o agendamento conforme sua necessidade, pagando valores reduzidos."
    },
    {
      id: 3,
      titulo: "A Solumedi é um plano de saúde, convênio ou cartão desconto?",
      descricao: "Não somos plano de saúde, nem convênio ou cartão desconto. A Solumedi presta apenas serviços administrativos que consistem única e exclusivamente no agendamento de consultas e exames com médicos e clínicas particulares, não realizando neste contexto qualquer tipo de atendimento ou auxílio médico direcionado aos usuários."
    },
    {
      id: 4,
      titulo: "A Solumedi aceita convênio?",
      descricao: "A Solumedi não aceita convênios, nem atende a planos de saúde. Apenas realiza o agendamento de consultas e exames para seus usuários cadastrados, intermediando o processo de agendamento entre o médicos particulares e a população."
    },
    {
      id: 5,
      titulo: "Existe alguma unidade Solumedi em minha cidade?",
      descricao: "A Solumedi tem unidades de atendimento em atuação em vários Estados brasileiros e você pode encontrar a unidade mais próxima acessando a área UNIDADES."
    },
    {
      id: 6,
      titulo: "Como faço meu cadastro gratuito/cartão de usuário Solumedi?",
      descricao: "Você pode realizar seu cadastro online ou se preferir, pode encontrar a unidade mais próxima e ir até ela com um documento de identificação (CPF, RG ou CNH) em mãos."
    },
    {
      id: 7,
      titulo: "Como agendo uma consulta, exame ou odontologia?",
      descricao: "Basta entrar em contato por telefone ou whatsapp, ou ir pessoalmente até a unidade mais próxima."
    },
    {
      id: 8,
      titulo: "Posso realizar o agendamento por telefone ou whatsapp?",
      descricao: "Sim, basta você encontrar neste site os dados da unidade mais próxima de sua casa e entrar em contato para realizar o agendamento."
    },
    {
      id: 9,
      titulo: "Como obtenho informações sobre valores e especialidades?",
      descricao: "Para informações sobre valores e especialidades você deve entrar em contato por telefone, whatsapp ou ir pessoalmente até a unidade mais próxima."
    },
    {
      id: 10,
      titulo: "Como eu realizo o pagamento?",
      descricao: "A Solumedi tem abrangência nacional, por isso, consulte as formas de pagamento disponíveis na sua região, por telefone ou whatsapp da unidade em que está realizando o seu agendamento."
    },
    {
      id: 11,
      titulo: "Preciso da nota fiscal referente ao agendamento, quando ela será emitida?",
      descricao: "A nota fiscal será emitida pela clínica ou laboratório parceiro, após a realização da sua consulta ou exame, agendado pela Solumedi."
    },
    {
      id: 12,
      titulo: "Caso não consiga comparecer à consulta ou exame, que já foi pago, como proceder?",
      descricao: "Entre em contato, o quanto antes, com a unidade que emitiu o voucher para reagendar a consulta e/ou substituir o seu voucher."
    },
    {
      id: 13,
      titulo: "Como proceder em caso de extravio do voucher? Como faço para obter uma segunda via?",
      descricao: "Basta entrar em contato com a unidade que emitiu o seu voucher e solicitar a emissão da segunda via do documento."
    },
    {
      id: 14,
      titulo: "Quais são as regras em caso de desistência, cancelamento e reembolso de valores?",
      descricao: "Para os vouchers emitidos através dos canais digitais (WhatsApp ou Aplicativo) o prazo para desistência da consulta ou exame é de até 7 (sete) dias úteis após a data de emissão do voucher; Desistência após 7 dias úteis da data de emissão do voucher, será abatido 30% do valor pago a título de compensação das despesas administrativas; Compras efetuadas com cartão de crédito através do link, serão devolvidas via pix em favor do titular do cartão de crédito; Compras efetuadas com pix ou depósito bancário, serão devolvidos via pix em favor do titular da conta corrente do depósito de origem. Para os vouchers emitidos em loja física o prazo para desistência e pedido de reembolso é de até 120 (cento e vinte) dias; Em qualquer hipótese de desistência, a partir da emissão do voucher, será abatido 30% do valor do voucher a título de compensação das despesas administrativas; Os valores serão devolvidos em dinheiro, para o portador do voucher mediante assinatura de termo de recebimento de valores (recibo). Para maiores esclarecimentos sobre a política de cancelamento, converse com o gerente da sua unidade."
    }
  ]

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Row>
          {result.map( ( item, index ) => (
            <Column key={index}>
              <Collapsible onClick={() => setShowDetailsByTitulo( item.titulo )}>
                <TitleEsp>
                  {item.titulo}
                </TitleEsp>
                <ContentChild
                  desc={item.descricao}
                  showDetails={showDetailsByTitulo === item.titulo}
                />
              </Collapsible>
            </Column>
          ) )}
        </Row>
        <TitleLast>
          Ainda tem alguma dúvida? Entre em
          <span>
            <Link to='/contato' style={{ textDecoration: "none", color: "#007bff" }}>
              &nbsp;contato.
            </Link>
          </span>
        </TitleLast>
      </Flex>
    </>
  )
}