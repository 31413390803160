import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import api from '../../api';

import { Flex } from '../../assets/styles/global';
import {
  BarGrid,
  BarCard,
  Card,
  Container,
  TextP,
  CButton,
  Logo
} from './blog.styles';

import { Loading } from '../Loading/loading';
import BoxBlog from '../BoxBlog/boxBlog';
import Solumedi from '../../assets/image/s.png'

export default function Blog() {

  const history = useHistory()
  const [posts, setPosts] = useState()

  useEffect(() => {
    window.scrollTo(0, 0)
    getPosts()
  }, []);

  const getPosts = async () => {
    const result = await api.get("wp/v2/posts?per_page=3&_embed")
    setPosts(result.data)
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <BarGrid>

          <BarCard>
            {posts ?
              <BoxBlog post={posts[0]} />
              : 
              <>
              <Loading />
              </>
            }
          </BarCard>

          <BarCard>
            {posts ?
              <BoxBlog post={posts[1]} />
              :
              <>
              <Loading />
              </>
            }
          </BarCard>

          <BarCard>
            {posts ?
              <BoxBlog post={posts[2]} />
              : 
              <>
              <Loading />
              </>
            }
          </BarCard>

          {false ?
            <>
              <BarCard>
                <Card >
                  <Container>
                    <TextP>ACESSE NOSSO BLOG COMPLETO!</TextP>
                    <CButton
                      onClick={() => history.push('/blog')}
                      color={"#2a4f72"}
                      background={"white"}
                    >&gt;</CButton>
                  </Container>
                </Card>
              </BarCard>
            </>
            :
            <>
              <BarCard>
                <Card >
                  <Container>
                    <TextP>BLOG SOLUMEDI!</TextP>
                    <Link to='/blog' style={{textDecoration: "none"}}>
                    <Logo src={Solumedi} alt="Solumedi"/>
                    </Link>
                  </Container>
                </Card>
              </BarCard>
            </>
          }
        </BarGrid>
      </Flex>
    </>
  );
}