import React, { useEffect, useState } from 'react';

import { Flex } from '../../../assets/styles/global';
import {
  MainRow,
  Row,
  Column,
  ColumnImg,
  Container,
  Title,
  SubTitle,
  DescriptionA,
  DescriptionAA,
  DescriptionB,
  RowPrice,
  DescriptionPrice
} from './lpBannerAA.styles'

import UrlImagem from '../../../assets/image/s-bg.png';
import UrlImagem2 from '../../../assets/image/especialidadeExemplo1.webp';

import { CustomButton } from '../../../components/CustomButton/customButton';
import { timeDate } from '../../../helpers/timeDate';

export default function LpBannerA( { data, infoPrice, setShow } ) {

  const [ telegram, setTelegram ] = useState( false );
  const [ onNoValue, setOnNoValue ] = useState( false )
  const [ textOne, setTextOne ] = useState( "Consultas" )
  const [ textTwo, setTextTwo ] = useState( "& Exames" )
  const [ textA, setTextA ] = useState()
  const [ textB, setTextB ] = useState( "Atendemos mais de 30 especialidades com valores reduzidos!" )
  const [ textC, setTextC ] = useState( "" )
  const [ textButton, setTextButton ] = useState( "AGENDAMENTO VIA WHATS" )

  useEffect( () => {

    var mesAtual = timeDate()

    if ( data.data.slug === "joinville" ) {
      setOnNoValue( true )
    }

    if (
      data.data.slug === "salvador" ||
      data.data.slug === "grandeflorianopolis" ||
      data.data.slug === "sorocaba" ||
      data.data.slug === "piracicaba"
    ) {
      setOnNoValue( true )
      //setTextTwo('Médicas')
    }

    if (
      data.data.slug === "salvador-exames" ||
      data.data.slug === "londrina-exames" ||
      data.data.slug === "apucarana-exames" ||
      data.data.slug === "joinville-exames" ||
      data.data.slug === "grandeflorianopolis-exames" ||
      data.data.slug === "sorocaba-exames" ||
      data.data.slug === "piracicaba-exames"
    ) {
      setOnNoValue( true )
      setTextOne( 'Exames Laboratoriais' )
      setTextTwo( 'e de Imagem' )
    }

    if ( data.data.slug === "ribeiraopreto" ) {
      setOnNoValue( true )
      //setTextTwo('Médicas')
      setTextB( "Sem mensalidade, sem anuidade ou taxa de adesão." )
    }

    if ( data.data.slug === "londrina-exames" ) {
      setTextB( "Sem mensalidade, sem anuidade ou taxa de adesão." )
    }

    if (
      data.data.slug === "saojosedoscampos" ||
      data.data.slug === "jacarei"
    ) {
      setOnNoValue( true )
      setTextA( `valores especiais em ${mesAtual}` )
    }

    if (
      data.data.slug === "jaraguadosul" ||
      data.data.slug === "mogidascruzes"
    ) {
      setOnNoValue( true )
      setTextA( "Os melhores Especialistas" )
      setTextB( "Atendemos mais de 30 especialidades. Valor Social!" )
    }

    if ( data.data.slug === "criciuma" ) {
      setOnNoValue( true )
      setTextA( "Valores reduzidos nas melhores clínicas particulares de Criciúma." )
      setTextB( "" )
    }

    if ( data.data.slug === "chapeco" ) {
      setOnNoValue( true )
      setTextA( "Os Melhores Especialistas" )
    }

    if ( data.data.slug === "mafra" ) {
      setOnNoValue( true )
      setTextA( "Valores Acessíveis" )
    }

    if ( data.data.slug === "rionegro" ) {
      setOnNoValue( true )
      setTextA( "Valores Acessíveis" )
    }

    if ( data.data.slug === "mossoro" ) {
      setTextButton( "AGENDE AGORA" )
      //setTelegram(true)
    }

    if ( data.data.slug === "jaraguadosul-cardiologista" ) {
      setTextOne( "Consulta com" )
      setTextTwo( "Cardiologista" )
      setOnNoValue( true )
      setTextA( "Os melhores cardiologistas de Jaraguá do Sul" )
      setTextB( "Consulta + Eletrocardiograma. Valor Social." )

    }

  }, [ data ] )

  const sendWhatsApp = () => {
    if ( telegram ) {
      window.open( `https://instagram.com/solumedimossoro`, "_blank" )
    } else {
      window.open( `https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace( /[^\d]+/g, '' )}&text=${data.data.mensagem_whats}`, "_blank" )
    }
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
        <MainRow>
          <Column>
            <Container background={UrlImagem2}></Container>
          </Column>

          <ColumnImg background={UrlImagem}>
            <Row>
              <Title>{data.data.nomefantasia}</Title>
            </Row>
            <Row>

              <Row>
                <SubTitle>{textOne}</SubTitle>
              </Row>
              <Row>
                <SubTitle>{textTwo}</SubTitle>
              </Row>

              {onNoValue ?
                <>
                  <RowPrice>
                    {data.data.slug === 'salvador' ?
                      <>
                        <DescriptionA>Consultas a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$75<small><small>,00</small></small></span></DescriptionA>
                      </>
                      :
                      <>

                        {data.data.slug === 'salvador-exames' ?
                          <>
                            <DescriptionA>Preços Reduzidos</DescriptionA>
                          </>
                          :
                          <>
                            <DescriptionA>{textA}</DescriptionA>
                          </>
                        }

                      </>
                    }

                    {data.data.slug === 'joinville-exames' && <DescriptionA>Preços Reduzidos</DescriptionA>}
                    {data.data.slug === 'grandeflorianopolis-exames' && <DescriptionA>Preços Reduzidos</DescriptionA>}
                    {data.data.slug === 'sorocaba-exames' && <DescriptionA>Preços Reduzidos</DescriptionA>}
                    {data.data.slug === 'piracicaba-exames' && <DescriptionA>Preços Reduzidos</DescriptionA>}

                    {data.data.slug === 'ribeiraopreto' && <DescriptionAA><small>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$4,00</span> e Consultas Médicas com valores acessíveis e atendimento realizado em Clínicas Particulares.</small></DescriptionAA>}
                    {data.data.slug === 'joinville' && <DescriptionA>A partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R${infoPrice}</span></DescriptionA>}
                    {data.data.slug === 'grandeflorianopolis' && <DescriptionA>A partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R${infoPrice}</span> Melhor Custo Benefício</DescriptionA>}
                    {data.data.slug === 'sorocaba' && <DescriptionA>Consultas a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R${infoPrice}</span></DescriptionA>}
                    {data.data.slug === 'piracicaba' && <DescriptionA>Consultas a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$60<small><small>,00</small></small></span></DescriptionA>}
                  </RowPrice>
                </>
                :
                <>
                  <RowPrice>
                    <DescriptionA>a partir de</DescriptionA>
                    <DescriptionPrice>R${infoPrice}</DescriptionPrice>
                    {data.data.slug === 'belem' && <DescriptionPrice style={{ margin: '0px' }}><small><small>,00</small></small></DescriptionPrice>}

                  </RowPrice>
                </>
              }

              {data.data.slug === 'londrina-exames' && <DescriptionA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$4<small><small>,50</small></small></span></DescriptionA>}
              {data.data.slug === 'apucarana-exames' && <DescriptionA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$5<small><small>,00</small></small></span></DescriptionA>}
              {data.data.slug === 'salvador' && <DescriptionA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$7<small><small>,00</small></small></span></DescriptionA>}
              {data.data.slug === 'piracicaba' && <DescriptionA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$3<small><small>,57</small></small></span></DescriptionA>}

            </Row>

            <Row>
              <Row>
                <DescriptionB>{textB}</DescriptionB>
              </Row>

              <Row>
                {data.data.slug === "joinville" ?
                  <>
                    <DescriptionB>Clique no botão abaixo e fale conosco pelo <strong>WhatsApp</strong>. Ou ligue <br></br><strong>{data.data.telefone_fixo}</strong></DescriptionB>
                  </>
                  :
                  <>
                    <DescriptionB>Clique no botão abaixo e fale conosco pelo <strong>WhatsApp</strong> ou ligue <br></br><strong>{data.data.telefone_fixo}</strong></DescriptionB>
                  </>
                }
              </Row>
            </Row>

            <Row>
              <CustomButton
                onClick={sendWhatsApp}
                color={"#2a4f72"}
                background={"#fdd460"}
                width={"70%"}>{textButton}</CustomButton>
            </Row>
            {data.data.slug === "belem" &&
              <>
                <Row>
                  <CustomButton
                    onClick={() => setShow()}
                    color={"#fdd460"}
                    background={"#2a4f72"}
                    width={"70%"}>CADASTRO GRATUITO</CustomButton>
                </Row>
              </>
            }

          </ColumnImg>
        </MainRow>
      </Flex>
    </>
  );
}
