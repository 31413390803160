import React from 'react';

import {
	Container,
	Modal,
	HeadAndFooter,
	Title
} from './modalForm.styles';

import UnidadesModal from './unidadesModal';

export default function ModalForm({ setUnidadesModal }) {

	return (
		<>
			<Container>
				<Modal >
					<HeadAndFooter>
					<Title onClick={() => setUnidadesModal(false)} >Fechar</Title>
					</HeadAndFooter>
					<UnidadesModal setUnidadesModal={setUnidadesModal} />
					<HeadAndFooter></HeadAndFooter>
				</Modal>
			</Container>
		</>
	)
}