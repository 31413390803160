import styled from 'styled-components';
import colors from '../../../assets/styles/colors';

export const TitleA = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 24px;
  font-weight: 700;
  color: ${colors.gray2};
  margin: 25px 15px 25px 15px;
  @media (min-width: 768px) {
    margin: 35px 0px 35px 0px;
    font-size: 40px;
  }
`

export const TitleB = styled.h2`
  text-align: center;
  word-break: break-word;
  font-size: 24px;
  color: ${colors.blue4};
  margin: 15px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 20px 0px 0px 0px;
  }
`

export const Description = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 12px;
  color: ${colors.blue6};
  margin: 5px 10px 25px 10px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 5px 0px 40px 0px;
  }
`

export const BarGrid = styled.div`
  width: 90%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 30px;
  }
`

export const BarCard = styled.div`
  grid-column: span 4;
  display: block;
  margin-bottom: 50px;
  @media (min-width: 768px) {
    grid-column: span 1;
    display: block;
  }
`

export const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 25px;
`

export const DivText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const SubTitleText = styled.p`
  word-break: break-word;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.blue5};
  margin: 0px 10px 0px 10px;
  @media (min-width: 768px) {
    font-size: 21px;
  }
`

export const MensagemText = styled.p`
  word-break: break-word;
  text-align: justify;
  font-size: 15px;
  color: ${colors.blue6};
  margin: 10px 10px 0px 10px;
`

export const Icones = styled.img`
  height: 70px;
  margin-bottom: 15px;
`