import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Card,
  MaxContainerImg,
  Container,
  TitleH4,
  TextP,
  ButtonDiv,
  Span
} from './boxEspecialidade.styles';

import { CustomButton } from '../../components/CustomButton/customButton';

import UrlImagem1 from '../../assets/image/especialidadeHighlights1.jpg';
import UrlImagem2 from '../../assets/image/especialidadeHighlights2.jpg';
import UrlImagem3 from '../../assets/image/especialidadeHighlights3.jpg';
import UrlImagem4 from '../../assets/image/especialidadeHighlights4.jpg';

export default function BoxEspecialidade({data}) {

  const history = useHistory()
  const [UrlImagem, setUrlImagem] = useState()

  useEffect(() => {
    var i = data.data.url
    switch (true) {
      case i === 1:
        i = UrlImagem1 
        break;
      case i === 2:
        i = UrlImagem2 
        break;
      case i === 3:
        i = UrlImagem3 
        break;
      case i === 4:
        i = UrlImagem4
        break;
      default:
        console.log('FAIL')
    }
    setUrlImagem(i)
  }, [])

  function goToEspecialidade(params){
    window.open(`https://www.solumedi.com.br/especialidades/${params}`, "_blank")
  }
  
  return (
    <>
      <Card>
        <MaxContainerImg url={UrlImagem}></MaxContainerImg>

        <Container>
          <TitleH4>{data.data.title}</TitleH4>
          <TextP><Span data-nosnippet>{data.data.text.substring(14,90)+"..."}</Span></TextP>
        </Container>
        <ButtonDiv>
          <CustomButton
            onClick={() => goToEspecialidade(`${data.slug}`)}
            color={"#2a4f72"}
            background={"#fdd460"}
            margin={"0px 0px 10px 0px"}
            width={"75%"}>
            SAIBA MAIS
          </CustomButton>
        </ButtonDiv>
      </Card>
    </>
  );
}