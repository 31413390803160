import React, { useEffect, useState } from 'react';

import {
  Input,
  SuggestionP
} from './autocomplete.styles';

import Unidades from '../../content/unidades.json' 
//import Unidades from '../../content/IBGE_ALL.json' 

export default function Autocomplete({currentValue}) {

  const [activeSuggestion, setActiveSuggestion] = useState(0)
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [userInput, setUserInput] = useState("")
  const [cidades, setCidades] = useState([])


  currentValue(userInput)

  useEffect(() => {
    // NAO PODEM EXISTIR ENDERECOS NULL OU INCOMPLETOS
    var lista = []
    for (let index = 0; index < Unidades.length; index++) {
      var item = Unidades[index].enderecos[0].cidade;
      //var item = Unidades[index].nome;
      var check = lista.find(cidade => cidade === item)
      if(!check){
        lista.push(item)
      }
      lista.sort()
    }
    setCidades(lista)
  }, [])


  const onChange = (e) => {
    const suggestions = cidades;
    //var inputValue = e.currentTarget.value;
    var inputValue = e.target.value;
    if(inputValue.length === 0){
      setUserInput("")
    } else{
      setUserInput(inputValue)
    }
    
    var filtered = []  
    for (let i = 0; i < suggestions.length; i++) {
      if (suggestions[i].substr(0, inputValue.length).toUpperCase() === inputValue.toUpperCase()) {
        filtered.push(suggestions[i])
      }
    }
    setFilteredSuggestions(filtered)
    setShowSuggestions(true)
    setActiveSuggestion(0)
  };

  const onClick = (e) => {
    setActiveSuggestion(0)
    setFilteredSuggestions([])
    setShowSuggestions(false)
    //setUserInput(e.currentTarget.innerText)
    setUserInput(e.currentTarget.innerText)
  };

  const onKeyDown = (e) => {
    // ENTER KEY
    if (e.keyCode === 13) {
      //setActiveSuggestion(0)
      //setShowSuggestions(false)
      //setUserInput(filteredSuggestions[activeSuggestion])
    }
    // UP ARROW
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion({ activeSuggestion: activeSuggestion - 1 })
    }
    // DOWN ARROW
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestion({ activeSuggestion: activeSuggestion + 1 })
    }
  };

  return (
    <>
      <Input
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={userInput}
        placeholder=" Digite sua cidade..."
      />
    </>
  )
}