import React, { useEffect } from 'react';


export function GtagWhatsApp( { url, gtag } ) {


  var infoTag = `function gtag_report_conversion() {
    var callback = function () {
  
      window.location = "${url}";

    };
    gtag('event', 'conversion', {
        'send_to': "${gtag.gtag}/${gtag.address}",
        'event_callback': callback
    });
    return false;
  }
  gtag_report_conversion()
  `

  useEffect( () => {
    const script = document.createElement( "script" );
    document.body.appendChild( script );
    script.innerHTML = infoTag;

    return () => {
      document.body.removeChild( script );
    }
  }, [] )

  return ( <></> );
}
