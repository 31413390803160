import React, { useEffect, useState } from 'react';

import { Flex } from '../../../assets/styles/global';
import {
  Section,
  MainRow,
  Row,
  Column,
  ColumnImg,
  Container,
  ContainerTati,
  Title,
  SubTitle,
  DescriptionA,
  DescriptionAAA,
  DescriptionB,
  RowPrice,
  DescriptionPrice,
  Space
} from './lpBannerAA.styles'

import UrlImagem from '../../../assets/image/s-bg.png';
import UrlImagem2 from '../../../assets/image/especialidadeExemplo1.webp';
import UrlImagem3 from '../../../assets/image/home-page-bg-banner.jpg';

import { CustomButton } from '../../../components/CustomButton/customButton';
import { timeDate } from '../../../helpers/timeDate';
import { GtagWhatsApp } from '../../../components/WhatsAppButton/GtagWhatsApp';
import { ContactWAForm } from '../../../components/ContactForm/contactWAForm';
import { requestForm } from '../../../helpers/requestForm';

export default function LpBannerA({ data, infoPrice }) {

  const [solfia, setSolfia] = useState(false)
  const [callGtagWhatsApp, setCallGtagWhatsApp] = useState(false)
  const [tags, setTags] = useState({ gtag: '', address: '' })
  const [onNoValue, setOnNoValue] = useState(false)
  const [textOne, setTextOne] = useState("Consultas")
  const [textTwo, setTextTwo] = useState("& Exames")
  const [textA, setTextA] = useState()
  const [textB, setTextB] = useState("Atendemos mais de 30 especialidades com valores reduzidos!")
  const [partA, setPartA] = useState("Clique no botão abaixo e fale conosco pelo")
  const [partB, setPartB] = useState(" WhatsApp")
  const [partC, setPartC] = useState(". Ou ligue ")
  const [partD, setPartD] = useState(data.data.telefone_fixo)
  const [brokeLine, setBrokeLine] = useState(true)
  const [textButton, setTextButton] = useState("AGENDAMENTO VIA WHATS")

  const [showContactForm, setShowContactForm] = useState(false)

  useEffect(() => {

    var mesAtual = timeDate()

    if (data.data.slug === "blumenau-solfia") {
      setSolfia(true)
    }

    if (data.data.slug === "ararangua") {
      setOnNoValue(true)
    }

    if (data.data.slug === "tubarao") {
      setTextOne('')
      setTextTwo('')
      setOnNoValue(true)
      setTextB(`Atendimento particular com valores acessíveis. Sem mensalidade e sem taxa de adesão!`)
    }

    if (data.data.slug === "joinville") {
      setOnNoValue(true)
    }

    if (
      data.data.slug === "salvador" ||
      data.data.slug === "grandeflorianopolis" ||
      data.data.slug === "sorocaba" ||
      data.data.slug === "ribeiraopreto" ||
      data.data.slug === "piracicaba" ||
      data.data.slug === "saobentodosul"
    ) {
      setOnNoValue(true)
      setTextOne('')
      setTextTwo('')
      setTextB('Sem mensalidade, sem anuidade ou taxa de adesão.')
    }

    if (data.data.slug === "salvador") {
      setBrokeLine(false)
    }
    if (
      data.data.slug === "florianopolis" ||
      data.data.slug === "palhoca" ||
      data.data.slug === "saojose" ||
      // data.data.slug === "caraguatatuba" ||
      data.data.slug === "santos"
    ) {
      setOnNoValue(true)
      setTextOne('')
      setTextTwo('')
      setTextB('Sem mensalidade, sem anuidade ou taxa de adesão.')
      setPartA('Cuide da sua saúde agora mesmo! Ligue para ')
      setPartB(data.data.telefone_fixo)
      setPartC(' ou clique no botão abaixo e agende pelo ')
      setPartD('WhatsApp')
      setBrokeLine(false)
    }

    if (
      data.data.slug === "itapema"
    ) {
      setOnNoValue(true)
      setTextA('Os Melhores Especialistas')
    }

    if (
      data.data.slug === "apucarana"
    ) {
      setOnNoValue(true)
      setTextOne('')
      setTextTwo('')
      setTextB('Sem mensalidade, sem anuidade e sem taxa de adesão.')
      setPartA('Cuide da sua saúde agora mesmo! Ligue para ')
      setPartB(data?.data?.telefone_fixo)
      setPartC(' ou clique no botão abaixo e agende pelo WhatsApp.')
      setPartD()
    }

    if (
      data.data.slug === "salvador-exames" ||
      data.data.slug === "londrina-exames" ||
      data.data.slug === "apucarana-exames" ||
      data.data.slug === "joinville-exames" ||
      data.data.slug === "grandeflorianopolis-exames" ||
      data.data.slug === "sorocaba-exames" ||
      data.data.slug === "piracicaba-exames" ||
      data.data.slug === "curitiba-cajuru-exames"
    ) {
      setOnNoValue(true)
      setTextOne('Exames Laboratoriais')
      setTextTwo('e de Imagem')
    }

    if (data.data.slug === "ribeiraopreto-v2") {
      setOnNoValue(true)
      setTextOne('')
      setTextTwo('')
      setTextB("Sem mensalidade, sem anuidade ou taxa de adesão.")
    }

    if (data.data.slug === "londrina-exames") {
      setTextB("Sem mensalidade, sem anuidade ou taxa de adesão.")
    }

    if (
      data.data.slug === "saojosedoscampos" ||
      data.data.slug === "jacarei"
    ) {
      setOnNoValue(true)
      setTextA(`valores especiais em ${mesAtual}`)
    }

    if (
      data.data.slug === "jaraguadosul" ||
      data.data.slug === "saobentodosul-v2" ||
      data.data.slug === "mogidascruzes"
    ) {
      setOnNoValue(true)
      setTextA("Os melhores Especialistas")
      setTextB("Atendemos mais de 30 especialidades. Valor Social!")
    }

    if (data.data.slug === "criciuma") {
      setOnNoValue(true)
      setTextA("Valores reduzidos nas melhores clínicas particulares de Criciúma.")
      setTextB("")
    }

    if (data.data.slug === "chapeco") {
      setOnNoValue(true)
      setTextA("Os Melhores Especialistas")
    }

    if (data.data.slug === "mafra") {
      setOnNoValue(true)
      setTextA("Valores Acessíveis")
    }

    if (data.data.slug === "rionegro") {
      setOnNoValue(true)
      setTextA("Valores Acessíveis")
    }

    if (data.data.slug === "mossoro" ||
      data.data.slug === "salvador"
    ) {
      setTextButton("AGENDE AGORA")
      //setTelegram(true)
    }

    if (data.data.slug === "jaraguadosul-cardiologista") {
      setTextOne("Consulta com")
      setTextTwo("Cardiologista")
      setOnNoValue(true)
      setTextA("Os melhores cardiologistas de Jaraguá do Sul")
      setTextB("Consulta + Eletrocardiograma. Valor Social.")
    }

    if (data.data.slug === "barreiras") {
      setTextOne('')
      setTextTwo('')
      setOnNoValue(true)
    }

  }, [data])

  useEffect(() => {
    if (data.data.slug === 'londrina') {
      // LONDRINA
      setTags({ gtag: 'AW-613684913', address: 'Pnf_CIu9k4sYELGt0KQC' })
    }

    if (data.data.slug === "cambe") {
      // CAMBE
      setTags({ gtag: 'AW-11085614779', address: 'CxZgCLOTxJMYELudhKYp' })
    }

    if (data.data.slug === 'ibipora') {
      // IBIPORA
      setTags({ gtag: 'AW-613684913', address: 'Pnf_CIu9k4sYELGt0KQC' })
    }

    if (data.data.telefone_whats === '(41) 9 8889-2641') {
      // CURITIBA-BOAVISTA
      setTags({ gtag: 'AW-10926418453', address: '' })
    }
  }, [data.data.slug, data.data.telefone_whats])

  const sendWhatsApp = () => {
    if (solfia && data.data.slug !== 'curitiba-centro' && data.data.slug !== 'mogidascruzes') {
      window.open(`https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace(/[^\d]+/g, '')}&text=Ol%C3%A1,%20aqui%20%C3%A9%20a%20Solfia`, "_blank")
      return
    }

    if (
      data.data.telefone_whats === "(43) 3376-5801" ||
      data.data.telefone_whats === "(43) 3329-8000" || // HERE WAS (43) 3376-5805
      data.data.telefone_whats === "(43) 3329-8000" || // HERE WAS (43) 3376-5806
      data.data.telefone_whats === "(43) 3329-8000" || // HERE WAS (43) 3376-5808
      data.data.telefone_whats === "(43) 3329-8000" || // HERE WAS (43) 3376-5809
      data.data.telefone_whats === "(43) 3376-5815" ||
      data.data.telefone_whats === "(54) 3771-4100"
    ) {
      setCallGtagWhatsApp(true)
      return
    }

    if (data.data.slug !== 'curitiba-centro' && data.data.slug !== 'mogidascruzes') {

      window.open(`https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace(/[^\d]+/g, '')}&text=${data.data.mensagem_whats}`, "_blank")
    }

  }

  const handleClick = (params) => {
    setShowContactForm(false)
    if (!params?.accept) return
    // SEND REQUEST
    requestForm(params.telefone_whats)
    if (window?.dataLayer) {
      window.dataLayer.push(`{'event': 'formSubmited'}`)
    }
    sendWhatsApp()
  }

  return (
    <Section id="contato">
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
        <MainRow>
          <Column>
            {data.data.slug === 'salvador' || data.data.slug === 'joinville'
              ? <ContainerTati background={UrlImagem3}></ContainerTati>
              : <Container background={UrlImagem2}></Container>
            }
          </Column>

          <ColumnImg background={UrlImagem}>
            <Row>
              {data.data.slug === 'saobentodosul-v2' || data.data.slug === 'ribeiraopreto-v2'
                ? null
                :
                <Title>{data.data.slug === 'sorocaba' && 'Unidade '}{data.data.slug === 'tubarao' && 'Novidade em '}{data.data.slug === 'salvador' ? 'Agende Agora, Fácil e Rápido' : data.data.nomefantasia}</Title>
              }
              {data.data.slug === 'ribeiraopreto-v2' && <DescriptionA>Outubro Rosa Solumedi - Consultas e Exames particulares com VALORES REDUZIDOS!</DescriptionA>}
            </Row>
            <Row>

              <Row>
                <SubTitle>{textOne}</SubTitle>
              </Row>
              <Row>
                <SubTitle>{textTwo}</SubTitle>
              </Row>

              {onNoValue ?
                <>
                  <RowPrice>
                    {data.data.slug === 'salvador-exames' ?
                      <>
                        <DescriptionA>Preços Reduzidos</DescriptionA>
                      </>
                      :
                      <>
                        <DescriptionA>{textA}</DescriptionA>
                      </>
                    }

                    {data.data.slug === 'ribeiraopreto-v2' && <DescriptionA><small>Ginecologista + Papanicolau <span style={{ color: "#fdd460", fontWeight: "bold" }}> R$100,00</span></small></DescriptionA>}
                    {data.data.slug === 'joinville-exames' && <DescriptionA>Preços Reduzidos</DescriptionA>}
                    {data.data.slug === 'grandeflorianopolis-exames' && <DescriptionA>Preços Reduzidos</DescriptionA>}
                    {data.data.slug === 'sorocaba-exames' && <DescriptionA>Preços Reduzidos</DescriptionA>}
                    {data.data.slug === 'piracicaba-exames' && <DescriptionA>Preços Reduzidos</DescriptionA>}
                    {data.data.slug === 'tubarao' && <DescriptionA>Consultas, Exames Laboratoriais e de Imagem com agendamento rápido!</DescriptionA>}


                    {data.data.slug === 'joinville' && <DescriptionA>A partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R${infoPrice}</span></DescriptionA>}
                    {data.data.slug === 'grandeflorianopolis' && <DescriptionA>A partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R${infoPrice}</span> Melhor Custo Benefício</DescriptionA>}
                    {data.data.slug === 'florianopolis' && <DescriptionA>Exames a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$5,00</span> e Consultas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$95,00</span> em Clínicas Particulares Especializadas.</DescriptionA>}
                    {data.data.slug === 'palhoca' && <DescriptionA>Exames a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$5,00</span> e Consultas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$95,00</span> em Clínicas Particulares Especializadas.</DescriptionA>}
                    {data.data.slug === 'saojose' && <DescriptionA>Exames a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$5,00</span> e Consultas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$95,00</span> em Clínicas Particulares Especializadas.</DescriptionA>}
                    {data.data.slug === 'santos' && <DescriptionA>Exames a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$4,90</span> e Consultas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$65,00</span> em Clínicas Particulares Especializadas.</DescriptionA>}
                    {data.data.slug === 'caraguatatuba' && <DescriptionA>Exames a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$5,00</span> e Consultas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$50,00</span> em Clínicas Particulares Especializadas.</DescriptionA>}
                    {data.data.slug === 'sorocaba' && <DescriptionA>Exames a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$6,00</span> e Consultas Médicas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$80,00</span> em Clínicas Particulares Especializadas.</DescriptionA>}
                    {data.data.slug === 'ribeiraopreto' && <DescriptionA>Exames a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$4,00</span> e Consultas Médicas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$100,00</span> em Clínicas Particulares Especializadas.</DescriptionA>}
                    {data.data.slug === 'apucarana' && <DescriptionA>Exames a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$2,95</span> e Consultas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}>R$60,00</span> em Clínicas Particulares Especializadas.</DescriptionA>}
                    {data.data.slug === 'barreiras' && <DescriptionA>Consultas a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R${infoPrice}<small>,00</small></span></DescriptionA>}

                  </RowPrice>

                </>
                :
                <>
                  <RowPrice>
                    <DescriptionA>a partir de</DescriptionA>
                    <DescriptionPrice>R${infoPrice}</DescriptionPrice>
                    {data.data.slug === 'curitiba-centro-2' && <DescriptionPrice style={{ margin: '0px' }}><small><small>,00</small></small></DescriptionPrice>}

                  </RowPrice>
                </>
              }

              {data.data.slug === 'ribeiraopreto-v2' && <DescriptionA><small>Mamografia <span style={{ color: "#fdd460", fontWeight: "bold" }}> R$100,00</span></small></DescriptionA>}
              {data.data.slug === 'ribeiraopreto-v2' && <DescriptionA><small>Ultrassom de Mamas <span style={{ color: "#fdd460", fontWeight: "bold" }}> R$100,00</span></small></DescriptionA>}
              {data.data.slug === 'londrina-exames' && <DescriptionA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$4<small><small>,50</small></small></span></DescriptionA>}
              {data.data.slug === 'apucarana-exames' && <DescriptionA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$5<small><small>,00</small></small></span></DescriptionA>}
              {data.data.slug === 'salvador' && <DescriptionAAA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$7<small><small>,00</small></small></span> e Consultas Médicas a partir de <span style={{ color: "#fdd460", fontWeight: "bold" }}> R$97<small><small>,00</small></small></span> realizado em Clínicas Particulares Especializadas.</DescriptionAAA>}
              {data.data.slug === 'piracicaba' && <DescriptionA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$3<small><small>,57</small></small></span> e Consultas Médicas com valores acessíveis e atendimento realizado em Clínicas Particulares.</DescriptionA>}
              {data.data.slug === 'barreiras' && <DescriptionA>Exames a partir de<span style={{ color: "#fdd460", fontWeight: "bold" }}> R$3<small>,00</small></span></DescriptionA>}

            </Row>

            {data.data.slug === 'ribeiraopreto-v2' && <DescriptionB>A hora de cuidar da sua saúde é agora!</DescriptionB>}

            <Row>
              {data.data.slug === 'sorocaba' &&
                <Row>
                  <DescriptionB>Atendemos mais de 30 especialidades com valores reduzidos!</DescriptionB>
                </Row>
              }
              {data.data.slug === 'mogidascruzes' &&
                <Row>
                  <DescriptionB><span style={{ color: "#fdd460", fontWeight: "bold" }}>Não atendemos convênios e planos de saúde.</span></DescriptionB>
                </Row>
              }
              {data.data.slug === 'ribeiraopreto' &&
                <>
                  <Row>
                    <DescriptionB>Atendemos mais de 30 especialidades com valores reduzidos!</DescriptionB>
                  </Row>
                </>
              }
              <Row>
                <DescriptionB>{textB}</DescriptionB>
              </Row>
              {data.data.slug === 'itapema' &&
                <Row>
                  <DescriptionB>Sem mensalidade, sem anuidade e sem taxa de adesão.</DescriptionB>
                </Row>
              }

              {data.data.slug === 'piracicaba' || data.data.slug === 'salvador'
                ?
                <Row>
                  {data.data.slug === 'salvador' && <Space></Space>}
                  <DescriptionB><span style={{ color: "#fdd460", fontWeight: "bold" }}>Não atendemos convênios e planos de saúde.{data.data.slug === 'salvador' && ' Somos a maior central de agendamentos médicos do Brasil.'}</span></DescriptionB>
                </Row>
                : null
              }
              {data.data.slug === 'salvador' && <Space></Space>}
              <Row>
                <DescriptionB>{partA}<strong>{partB}</strong>{partC}{brokeLine ? <br></br> : null}<strong>{partD}</strong></DescriptionB>
              </Row>
            </Row>

            <Row>
              <CustomButton
                onClick={() => {
                  if (false) { // data.data.slug === 'mossoro'
                    setShowContactForm(true)
                  } else {
                    sendWhatsApp()
                  }
                }}
                className='agendamento_via_whats'
                id="agendamento_via_whats"
                color={"#2a4f72"}
                background={"#fdd460"}
                width={"70%"}>{textButton}</CustomButton>
            </Row>

          </ColumnImg>
        </MainRow>
      </Flex>
      {callGtagWhatsApp && <GtagWhatsApp url={`https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace(/[^\d]+/g, '')}&text=${data.data.mensagem_whats}`} gtag={tags} />}

      {showContactForm ? <ContactWAForm handleClick={handleClick} /> : <></>}

    </Section>
  );
}
