import React, {useEffect, useState} from 'react';

import {
  Parallax,
  Flex,
  Title,
  Description
} from './lpThemeA.styles';

export default function LpThemeBCINC() {
 
  const [isIOS, setIsIOS] = useState(false)
  useEffect(()=>{
    if (!navigator.userAgent.match(/(iPhone|iPod)/g)) {
      setIsIOS(true)
    }
  },[])

  return (
    <>
      <Parallax parallax={isIOS}>
        <Flex>
          <Title>Solumedi Santa Catarina Litoral Centro</Title>
          <Description>Unidade Balneário Camboriú</Description>
          <Description>Unidade Itajaí</Description>
          <Description>Unidade Navegantes</Description>
          <Description>Unidade Camboriú</Description>
        </Flex>
      </Parallax>
    </>
  )
}