import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Title = styled.h2`
  word-break: break-word;
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 10px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 30px 0px 0px 0px;
  }
`

export const Description = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 12px;
  color: ${colors.gray2};
  margin: 5px 0px 20px 0px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 10px 0px 30px 0px;
  }
`

export const BarGrid = styled.div`
  width: 90%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 15px;
  }
`

export const BarCard = styled.div`
  grid-column: span 4;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 4;
    display: block;
  }
`

export const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
`

export const DivNumber = styled.div`
  border: 1px solid ${colors.gray1};
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DivText = styled.div`
  border: 1px solid ${colors.gray1};
  width: 65%;
  display: flex;
  justify-content: start;
  align-items: center;
`

export const NumberText = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 15px;
  margin: 3px;
`

export const MensagemText = styled.p`
  word-break: break-word;
  font-size: 15px;
  margin: 3px 10px 3px 10px;
`
