import styled from "styled-components"
import colors from '../../assets/styles/colors';

export const Bottom = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${colors.blue6};
  text-align: center;
  padding: 5px 0px 5px 0px;
`

export const BottomText = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin: 0;
`

//

export const ContainerArea = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${colors.blue5};
  padding: 5px 0px 5px 0px;
  text-align: center;
`

export const LogoABF = styled.img`
  width: 63%;
  margin: 0px;
  @media (min-width: 768px) {
    width: 63%;
    margin: 0px;
  }
`

export const LogoSocial = styled.img`
  cursor: pointer;
  width: 10%;
  margin: 7px 0px 7px 0px;
  :hover{
    opacity: 0.7;
  }
  @media (min-width: 768px) {
    margin: 10px 0px 10px 0px;
    width: 7%;
  }
`

export const Description = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: white;
  margin: 5px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0px 0px 0px;
  }
`

export const TextA = styled.a`
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin: 5px 0px 5px 0px;
  :hover{
    opacity: 0.7;
  }
  @media (min-width: 768px) {
    font-size: 12px;
    font-weight: 700;
    margin: 5px 0px 5px 0px;
  }
`

export const TextALink = styled.p`
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin: 5px 0px 5px 0px;
  :hover{
    opacity: 0.7;
  }
  @media (min-width: 768px) {
    font-size: 12px;
    font-weight: 700;
    margin: 5px 0px 5px 0px;
  }
`

export const TextB = styled.p`
  font-size: 10px;
  color: white;
  margin: 5px 0px 5px 0px;
  @media (min-width: 768px) {
    font-size: 12px;
    margin: 5px 0px 5px 0px;
  }
`

export const BarGrid = styled.div`
  width: 100%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const BarCard = styled.div`
  grid-column: span 3;
  height: 300px;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 1;
    display: block;
  }
`

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: -webkit-fill-available;
  background: url(${props => props.url ? props.url : "" }) center no-repeat;
  background-size: 70%;
  @media (min-width: 768px) {
    background-size: 50%;
}
`

export const Container = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const ContainerORIGIN = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
