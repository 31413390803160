import React from 'react';

import {
  Leading,
  LeadingBigText
} from './banner.styles';

export default function Banner() {
  return (
    <>
      <Leading >
        <LeadingBigText>Blog</LeadingBigText>
      </Leading>
    </>
  );
}