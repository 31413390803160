import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const BarGrid = styled.div`
  width: 90%;
  padding: 25px 0px;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const BarContainerTitle = styled.div`
  width: 100%
  grid-column: span 3;
  margin-bottom: 5px;
  @media (min-width: 768px) {
    display: block;
    grid-column: span 3;
    margin-bottom: 0;
  }
`

export const BarContainerInput = styled.div`
  text-align: center;
  width: 100%
  grid-column: span 3;
  @media (min-width: 768px) {
    display: block;
    grid-column: span 3;
  }
`

export const Title = styled.p`
  font-size: 21px;
  font-weight: 700;
  color: white;
  margin: 0;
`

export const SubTitle = styled.p`
  font-size: 18px;
  font-weight: 300;
  color: white;
  margin: 5px 0px 10px 0px;
`

export const Form = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const FormInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 70%;
  }
`

export const FormButton = styled.div`
  @media (min-width: 768px) {
    display: flex;
    width: 30%;
}
`

export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: ${colors.yellow2};
  border: none;
  border-radius: 50px;
  padding: 10px;
  font-size: 21px;
  font-weight: 700;
  margin: 0;
  color: ${colors.blue6};
  width: 100%;
  height: 50px;
  position: relative;
  display: inline-block;
  @media (min-width: 768px) {
    max-width: -webkit-fill-available;
    margin: 0;
    border-radius: 0px 50px 50px 0px;
    font-size: 18px;
    height: 47px;
  };
  :hover{
    opacity: 0.7;
  }
`