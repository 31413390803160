import React, { useEffect, useState } from 'react';

import {
  Parallax,
  Flex,
  Title,
  Description
} from './lpThemeA.styles';

export default function LpThemeI() {

  const [isIOS, setIsIOS] = useState(false)
  useEffect(()=>{
    if (!navigator.userAgent.match(/(iPhone|iPod)/g)) {
      setIsIOS(true)
    }
  },[])

  return (
    <>
      <Parallax parallax={isIOS}>
        <Flex>
          <Title>Solumedi Santa Catarina Litoral Centro</Title>
          <Description>Unidade Itapema</Description>
        </Flex>
      </Parallax>
    </>
  )
}