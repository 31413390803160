import React from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerImg,
  BarContainerArea,
  Title,
  SubTitle,
  Description

} from './bannerB.styles';

import { CustomButton } from '../CustomButton/customButton';

export default function BannerTwo() {

  const history = useHistory()

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} Background={"#2a4f72"} >
        <BarGrid>
          <BarContainerImg />
          <BarContainerArea>
            <Title>MAIS DE 50 ESPECIALIDADES</Title>
            <SubTitle>A Solumedi possui mais de 5.000 parceiros por todo o Brasil!</SubTitle>
            <Description>A Solumedi surgiu da ideia de conectar clínicas particulares com pessoas que buscam atendimento médico de qualidade. Não realizamos nenhum tipo de cobrança recorrente e o usuário efetua somente o pagamento do agendamento. O que faz com que a Solumedi tenha o melhor CUSTO x BENEFÍCIO no ramo da saúde.</Description>
            <CustomButton
              onClick={() => history.push('/especialidades')}
              color={"#2a4f72"}
              background={"#fdd460"}
              margin={"10px 0px 0px 25px"}
              width={"60%"}>
              SAIBA MAIS
            </CustomButton>
          </BarContainerArea>
        </BarGrid>
      </Flex>
    </>
  );
}