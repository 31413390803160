import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description,
  BarGrid,
  BarCard,
  MainDiv,
  DivNumber,
  DivText,
  NumberText,
  MensagemText

} from './table2.styles';
import colors from '../../assets/styles/colors';

export default function Table() {
  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <BarGrid>

          <BarCard>
            <MainDiv>
              <DivNumber style={{ backgroundColor: `${colors.gray0}` }}>
                <NumberText style={{ fontWeight: "bold" }}>Finalidades de Tratamento</NumberText>
              </DivNumber>
              <DivText style={{ backgroundColor: `${colors.gray0}` }}>
                <MensagemText style={{ fontWeight: "bold" }}>
                Tipos de Dados Pessoais Utilizados
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>EXECUÇÃO DO CONTRATO</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Nome, endereço, telefone, CPF, data de nascimento, e-mail
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>CONSENTIMENTO</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Dados de menores – nome, data de nascimento, e CPF
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>INTERESSE LEGÍTIMO DO CONTROLADOR</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Nome, endereço, CPF, data de nascimento, gênero, telefone, e-mail
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
        </BarGrid>

      </Flex>
    </>
  );
}