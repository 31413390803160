import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Title = styled.h2`
  text-align: center;
  word-break: break-word;
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 70px 0px 0px 0px;
  }
`

export const Description = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 12px;
  color: ${colors.gray2};
  margin: 5px 0px 20px 0px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 10px 0px 30px 0px;
  }
`

export const Bottom = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${colors.blue6};
  text-align: center;
  padding: 5px 0px 5px 0px;
`

export const BottomText = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin: 0;
`

//

export const ContainerArea = styled.div`
  border-radius: 50px;
  position: relative;
  left: 0;
  bottom: 0;
  width: 90%;
  background-color: ${colors.gray0};
  padding: 5px 0px 5px 0px;
  margin: 10px 0px 10px 0px;
  text-align: center;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  transition: all 0.2s ease-in;
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
  }

`

export const LogoMapMarker = styled.img`
  cursor: pointer;
  width: 7%;
   :hover{
    opacity: 0.7;
  }
`

export const LogoWhatsApp = styled.img`
  cursor: pointer;
  width: 6%;
  margin: 0px 0px -7px 0px;
  padding: 0px 5px 0px 0px;
  @media (min-width: 768px) {
    margin: 0px 0px -9px 0px;
    padding: 0px 5px 0px 0px;
  }
`

export const LogoTelefone = styled.img`
  cursor: pointer;
  width: 5%;
  margin: 0px 0px -3px 0px;
  padding: 0px 5px 0px 0px;
  @media (min-width: 768px) {
    margin: 0px 0px -5px 0px;
    padding: 0px 5px 0px 0px;
  }
`

export const TitleUnidade = styled.p`
  cursor: pointer;
  word-break: break-word;
  font-size: 21px;
  font-weight: 700;
  color: ${colors.blue6};
  margin: 20px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 27px;
    font-weight: 700;
    margin: 0;
  }
  :hover {
    opacity: 0.7;
  }
`

export const TitleUnidadeButton = styled.p`
  cursor: pointer;
  border: 3px solid ${colors.blue6};
  border-radius: 50px;
  word-break: break-word;
  font-size: 21px;
  font-weight: 700;
  color: ${colors.blue6};
  padding: 5px 15px 5px 15px; 
  margin: 20px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 27px;
    font-weight: 700;
    margin: 20px 0px 0px 0px;
  }
  :hover {
    opacity: 0.7;
  }
`

export const TextA = styled.a`
  word-break: break-word;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.blue6};
  margin: 12px 0px 12px 0px;
  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 700;
  }
`

export const TextWA = styled.a`
  word-break: break-word;
  font-size: 14px;
  font-weight: 600;
  /* color: ${colors.blue6}; */
  color: ${colors.greenWA};
  margin: 12px 0px 0px 0px;
  :hover {
    opacity: 0.7;
  }
  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 700;
  }
`

export const BarGrid = styled.div`
  width: 100%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const BarCardOK = styled.div`
  grid-column: span 3;
  height: 100px;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 1;
    display: block;
    height: 150px;
  }
`

export const BarCard = styled.div`
  grid-column: span 3;
  @media (min-width: 768px) {
    grid-column: span 1;
  }
`

export const Card = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (min-width: 768px) {
    height: 150px;
}
`

export const CardOK = styled.div`
  position: relative;
  width: 100%;
  height: -webkit-fill-available;
  background: url(${props => props.url ? props.url : ""}) center no-repeat;
  background-size: 70%;
  @media (min-width: 768px) {
    background-size: 50%;    
}
`

export const Container = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const NoResultsText = styled.p`
  text-align: center;
  color: ${colors.red1};
  font-weight: 700;
  font-size: 18px;
  margin: 20px 5px 0px 5px;
  @media (min-width: 768px) {
    margin: 20px 0px 0px 0px;
  }
`