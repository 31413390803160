import React from 'react';

import { Flex } from '../../../assets/styles/global';
import {
  Bottom,
  BottomText,
  ContainerArea,
  Description,
  Enderecos,
  BarGrid,
  BarCard,
  Card,
  LogoSocial,
  Row,
  RowEnderecos,
  Column,
  LogoImg,
  HashTag
} from './footer.styles'

import Solumedi from '../../../assets/image/s.png'
import FaceBook from '../../../assets/image/facebook.png'
import Instagram from '../../../assets/image/instagram.png'
import YouTube from '../../../assets/image/youtube.png'

export function Footer( { data } ) {


  const callFB = () => {
    if ( data.data.facebook === 0 ) {
      window.open( `https://web.facebook.com/SolumediOficial`, "_blank" )
    } else {
      window.open( `https://web.facebook.com/${data.data.facebook}`, "_blank" )
    }
  }

  const callInsta = () => {
    if ( data.data.instagram === 0 ) {
      window.open( `https://www.instagram.com/solumedioficial`, "_blank" )
    } else {
      window.open( `https://www.instagram.com/${data.data.instagram}`, "_blank" )
    }
  }

  const callYou = () => {
    window.open( 'https://www.youtube.com/channel/UCMSBIH64rB1OfRkWAGBbODA', "_blank" )
  }

  return (
    <>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <ContainerArea>
          <BarGrid>
            <BarCard>
              <Card>
                <Row>
                  <LogoImg background={Solumedi}></LogoImg>
                  <Column>
                    <RowEnderecos>
                      <Enderecos>
                        {data.enderecos[ 0 ].logradouro},
                        &nbsp;{data.enderecos[ 0 ].numlogradouro},
                        {data.enderecos[ 0 ].complemento ? ` ${data.enderecos[ 0 ].complemento},` : ""}
                        &nbsp;{data.enderecos[ 0 ].bairro}<br></br>
                        &nbsp;{data.enderecos[ 0 ].cidade} /
                        &nbsp;{data.enderecos[ 0 ].uf}
                      </Enderecos>
                    </RowEnderecos>
                    <RowEnderecos>
                      <Enderecos>&nbsp;{data.data.telefone_fixo}</Enderecos>
                      <Enderecos>&nbsp;{data.data.telefone_fixo === data.data.telefone_whats ? "" : data.data.telefone_whats}</Enderecos>
                    </RowEnderecos>
                    <Row>
                      <Description>SIGA NOSSAS REDES</Description>
                    </Row>
                    <Row>
                      <LogoSocial src={FaceBook} onClick={callFB} />
                      <LogoSocial src={Instagram} onClick={callInsta} />
                      <LogoSocial src={YouTube} onClick={callYou} />
                    </Row>
                  </Column>
                </Row>
                <Row>
                  <HashTag>
                    <span style={{ color: "#fdd460" }}>#</span>
                    voce
                    <span style={{ color: "#fdd460" }}>merece</span>
                    ter
                    <span style={{ color: "#fdd460" }}>saude</span>
                  </HashTag>
                </Row>
              </Card>
            </BarCard>
          </BarGrid>
        </ContainerArea>
      </Flex>

      <Bottom>
        <BottomText>
          &copy; 2024 | Desenvolvido por Solumedi
        </BottomText>
      </Bottom>
    </>
  );
}
