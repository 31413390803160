import React from 'react';
import {
  Row,
  ColumnOne,
  CollapsibleOne,
  TitleEspOne
} from './lpPriceBoardGroupCard.styles';

export default function ListMore() {

  const result = [
    { id: 3, title: "Centro Médico" },
    { id: 6, title: "Clínico Geral" },
    { id: 17, title: "Endocrinologista Infantil" },
    { id: 5, title: "Geriatra" },
    { id: 19, title: "Gastro Infantil" },
    { id: 18, title: "Médico Gastro" },
    { id: 2, title: "Hepatologista" },
    { id: 13, title: "Mastologista" },
    { id: 4, title: "Médicos" },
    { id: 1, title: "Médico do Trabalho" },
    { id: 16, title: "Ortopedista" },
    { id: 9, title: "Proctologista" },
    { id: 15, title: "Pneumologista Infantil" },
    { id: 7, title: "Psicologia Infantil" },
    { id: 14, title: "Psiquiatra Infantil" },
    { id: 8, title: "Urologista Infantil" },
  ]

  return (
    <Row>
      {result.map( ( item, index ) => (
        <ColumnOne key={index}>
          <CollapsibleOne>
            <TitleEspOne >
              {item.title}
            </TitleEspOne>
          </CollapsibleOne>
        </ColumnOne>
      ) )}
    </Row>
  )
}
