import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Title = styled.h2`
  word-break: break-word;
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 10px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 30px 0px 0px 0px;
  }
`

export const Description = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 12px;
  color: ${colors.blue6};
  margin: 5px 10px 10px 10px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 5px 0px 10px 0px;
  }
`

export const DescriptionBold = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: ${colors.blue6};
  margin: 0px 0px 10px 10px;
  @media (min-width: 768px) {
    font-size: 30px;
    margin: 0px 0px 10px 0px;
  }
`

export const DescriptionPrice = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: ${colors.blue5};
  margin: 5px 10px 50px 10px;
  @media (min-width: 768px) {
    font-size: 50px;
    margin: 10px 0px 50px 0px;
  }
`

export const DescriptionObs = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 12px;
  color: ${colors.blue5};
  margin: 0px 10px 0px 10px;
  @media (min-width: 768px) {
    font-size: 14px;
    margin: 0px 0px 0px 0px;
  }
`

export const BarGrid = styled.div`
  width: 90%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
  }
`

export const BarCard = styled.div`
  grid-column: span 2;
  display: block;
  margin-bottom: 50px;
  @media (min-width: 768px) {
    grid-column: span 1;
    display: block;
  }
`

export const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  background-color: white;
`

export const DivNumber = styled.div`
  width: 40%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;

`

export const DivImage = styled.div`
  
  width: 110px;  
  height: 110px;
  border-radius: 50%;
  background: url(${props => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.7);
  @media (min-width: 768px) {
    width: 140px;
    height: 140px;
}
`

export const DivText = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const SubTitleText = styled.p`
  word-break: break-word;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.blue5};
  margin: 0px 10px 0px 10px;
  @media (min-width: 768px) {
    font-size: 21px;
  }
`

export const MensagemText = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 15px;
  color: ${colors.blue6};
  margin: 10px 10px 0px 10px;
`

export const MensagemPrice = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  color: ${colors.blue6};
  margin: 10px 10px 0px 10px;
`