import React, { useEffect } from 'react';

import { Header } from './LpHeader/header';
import { Footer } from './LpFooter/footer';

import BannerMain from './BannerMain/banner';

export default function ModelA({ data }) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Header email={data.data.email_adm} />
      <BannerMain />
      <Footer data={data} />
    </>
  );
}