import React, { useEffect } from 'react';

import Banner from './banner';
import Form from './form';

export function Parceiro() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <Form />
    </>
  );
}