import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerTitle,
  Title,
  SubTitle,
  BarContainerInput,
  CButton
} from './agendeBar.styles';

export default function AgendeBar() {
  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} Background={"#78cfe0"} style={{ marginTop: "40px" }}>
        <BarGrid>
          <BarContainerTitle>
            <Title>FAÇA SEU AGENDAMENTO COM A SOLUMEDI!</Title>
            <SubTitle>Entre em contato conosco pelo WhatsApp.</SubTitle>
          </BarContainerTitle>

          <BarContainerInput>
            <CButton>
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=554140036534&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Solumedi.`}
                style={{ textDecoration: "none", color: "white" }}
              >WhatsApp Solumedi</a></CButton>
          </BarContainerInput>
        </BarGrid>
      </Flex>
    </>
  );
}