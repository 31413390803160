import React from 'react';

import {
  CloseX,
  Title,
  Title2,
  TitlePre,
  Row,
  CButton
} from './askFillForm.styles';

export default function AskFillForm({ setShow, offerCard, fillForm }) {

  return (
    <>
      <Row style={{ justifyContent: "end" }}>
        <CloseX onClick={() => setShow()} >X</CloseX>
      </Row>

      <Title>Você já fez o seu cadastro na Solumedi?</Title>
      <Title2>Faça o seu cadastro e peça o seu Cartão agora mesmo!</Title2>

      <CButton
        onClick={() => fillForm()}
        width={"90%"}
        margin={"0px 0px 0px 0px"}
        color={"#2a4f72"}
        background={"#fdd460"}
      >CADASTRAR</CButton>
      <CButton
        onClick={() => offerCard()}
        width={"90%"}
        margin={"0px 0px 0px 0px"} >JÁ TENHO CADASTRO</CButton>

      <TitlePre >Pré-Inauguração Unidade Belém</TitlePre>

    </>
  );
}