import React, { useEffect } from 'react';

import {Flex} from '../../assets/styles/global';
import {
  Title,
  Description
} from './faq.styles';

import Banner from './banner';
import FAQRow from '../../components/FaqRow/faqRow';

export function FAQ() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginTop: "20px", marginBottom: "20px" }} >
        <Title>DÚVIDAS FREQUENTES</Title>
        <Description>Saiba mais sobre a Solumedi e encontre algumas respostas aqui</Description>

        <FAQRow />      
      
      </Flex>
    </>
  );
}