import React from 'react';

import {
  Topbar,
  TopDiv,
  TopLink,
  Separador,
  Nav,
  Logo
} from './header.styled';

import Logomarca from '../../../assets/solumedi.png'

export function Header({email}) {
  const isOpen = false;
  return (
    <>
      {isOpen ?
        <></>
        :
        <>
          <Topbar>
          <TopLink href="mailto:sac@solumedi.com.br">
                  saf@solumedi.com.br
                </TopLink>
                <TopDiv>
                  <TopLink
                    href="https://solumedi.com.br/"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    SITE COMPLETO
                  </TopLink>
                  <Separador></Separador>
                  <TopLink
                    href="https://app.centraldofranqueado.com.br/login"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    CENTRAL DO FRANQUEADO
                  </TopLink>
                </TopDiv>
          </Topbar>
        </>
      }
      <Nav>
        <Logo src={Logomarca} />
      </Nav>
    </>
  );
}
