import styled from 'styled-components';
import colors from '../../../assets/styles/colors';

//import bannerLt from '../../../assets/image/unidades-bg-bannerLt.jpg';
import bannerLt from '../../../assets/image/backgroundsejafranqueado.jpg';
import banner from '../../../assets/image/unidades-bg-banner.jpg';
// IMAGEM 1920 X 1200
//import bannerLt from '../../../assets/image/unidades-bg-bannerLt.jpg';
// IMAGEM 512 X 290

export const Leading = styled.section`
  margin-top: 70px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${bannerLt}) center #2A4F72 no-repeat;
  background-size: 120%;
  color: white;
  text-shadow: 0px 0px 10px #000;  
    @media (min-width: 700px) {
      background: url(${banner}) center #2A4F72 no-repeat;
      background-attachment: fixed;
   }
`


export const TitleA = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 24px;
  font-weight: 700;
  color: ${colors.blue1};
  margin: 25px 15px 5px 15px;
  @media (min-width: 768px) {
    margin: 70px 0px 25px 0px;
    font-size: 40px;
  }
`


export const Description = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 12px;
  color: white;
  margin: 5px 10px 25px 10px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 5px 0px 50px 0px;
  }
`

export const Description2 = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 10px;
  color: white;
  margin: 0px 10px 3px 10px;
  @media (min-width: 768px) {
    font-size: 12px;
    margin: 0px 0px 3px 0px;
  }
`

export const BarGrid = styled.div`
  width: 90%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 30px;
  }
`

export const BarCard = styled.div`
  grid-column: span 4;
  display: block;
  margin-bottom: 25px;
  @media (min-width: 768px) {
    grid-column: span 1;
    display: block;
    margin-bottom: 100px;
  }
`

export const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 25px;
`

export const DivText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const SubTitleText = styled.p`
  word-break: break-word;
  font-size: 30px;
  font-weight: 900;
  color: white;
  margin: 0px 10px 0px 10px;
  @media (min-width: 768px) {
    font-size: 49px;
  }
`

export const MensagemText = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 15px;
  color: ${colors.blue1};
  margin: 0px 10px 0px 10px;
`

export const Icones = styled.img`
  height: 70px;
  margin-bottom: 15px;
`