import React from 'react';

import {
  BarContainerSlide,
} from './carouselTestimonials.styles';

import Slider from './slider';

import Francisco from '../../../src/assets/image/Francisco.jpeg';
import Rebeca from '../../../src/assets/image/Rebeca.jpeg';
import Patricia from '../../../src/assets/image/Patricia.jpeg';

const defaultSlides = [
  {
    id: 1,
    title: "FRANCISCO",
    textTitle: "USUÁRIO SOLUMEDI",
    text: `"Fiz a minha carteirinha e foi muito rápido, não paguei nada e gostei porque não tem mensalidade."`,
    image: Francisco
  },
  {
    id: 2,
    title: "REBECA SILVA",
    textTitle: "USUÁRIA SOLUMEDI",
    text: `"Me ajudou muito pois em um momento super delicado consegui realizar um ultrassom obstétrico de forma rápida e com valor baixo."`,
    image: Rebeca
  },
  {
    id: 3,
    title: "PATRÍCIA",
    textTitle: "USUÁRIA SOLUMEDI",
    text: `"Com a Solumedi é mais fácil manter sua saúde. Preços justos e convênios com clínicas e médicos de excelência."`,
    image: Patricia
  }
]

export default function CarouselExames() {
  return (
    <>
      <BarContainerSlide>
        <Slider slides={defaultSlides} />
      </BarContainerSlide>
    </>
  )
}