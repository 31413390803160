import React, { useEffect, useState } from 'react';

import { Flex } from '../../assets/styles/global';
import {
  MainRow,
  Column,
  Container
} from './lpImage.styles'

import urlApucarana from '../../assets/image/lpImage/apucaranaOutubroRosa.jpeg';
import urlCaxiasdosul from '../../assets/image/lpImage/caxiasdosulOutubroRosa.jpeg';
//import urlLondrina from '../../assets/image/lpImage/londrinaOutubroRosa.jpeg';
import urlLondrina from '../../assets/image/lpImage/londrinaCirurgia.jpeg';
import urlSaojosedoscampos from '../../assets/image/lpImage/saojosedoscamposUltrassom.png';

export default function LpBannerA( { data } ) {

  const [ urlBG, setUrlBG ] = useState();

  useEffect( () => {
    if ( data.data.slug === "apucarana" ) {
      setUrlBG( urlApucarana )
    }

    if ( data.data.slug === "caxiasdosul" ) {
      setUrlBG( urlCaxiasdosul )
    }

    if ( data.data.slug === "londrina" ) {
      setUrlBG( urlLondrina )
    }

    if ( data.data.slug === "saojosedoscampos" ) {
      setUrlBG( urlSaojosedoscampos )
    }

  }, [ data ] )

  const sendWhatsApp = () => {
    if ( data.data.slug === 'londrina' ) {
      window.open( `https://api.whatsapp.com/send?phone=5543991808007&text=${data.data.mensagem_whats}`, "_blank" )
      return
    }
    window.open( `https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace( /[^\d]+/g, '' )}&text=${data.data.mensagem_whats}`, "_blank" )
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <MainRow>
          <Column>
            <Container onClick={sendWhatsApp} background={urlBG}></Container>
          </Column>
        </MainRow>
      </Flex>
    </>
  );
}