import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import Litoral from '../../assets/image/themeLitoral.jpg';

export const Parallax = styled.div`
  background-image: url(${Litoral});
  min-height: 400px; 
  background-attachment: ${props => props.parallax ? 'fixed': 'none'};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 768px) {
    background-position: center;
  }
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const Title = styled.h2`
  text-align: center;
  font-size: 24px;
  color: ${colors.blue4};
  margin: 50px 0px 15px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
  }
`

export const Description = styled.p`
  text-align: center;
  font-size: 21px;
  font-weight: 700;
  color: white;
  margin: 5px 0px 5px 0px;
  @media (min-width: 768px) {
    font-size: 30px;
  }
`