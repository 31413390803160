import React, { useEffect, useState } from 'react';
import {
  Row,
  ColumnOne,
  CollapsibleOne,
  TitleEspOne
} from './lpPriceBoardGroupCard.styles';

const result = [
  { id: 1, title: "Acupuntura" },
  { id: 2, title: "Alergologista" },
  { id: 3, title: "Angiologista" },
  { id: 4, title: "Cardiologista" },
  /* { id: 5, title: "Cirurgião Geral" }, */
  { id: 6, title: "Cirurgião Plástico" },
  { id: 7, title: "Cirurgião Vascular" },
  { id: 8, title: "Clínico Geral" },
  { id: 9, title: "Dermatologista" },
  { id: 10, title: "Endocrinologista" },
  { id: 11, title: "Endocrinologista Infantil" },
  { id: 12, title: "Fisioterapia" },
  { id: 13, title: "Fonoaudiólogo" },
  { id: 14, title: "Gastroenterologia" },
  { id: 15, title: "Geriatria" },
  { id: 16, title: "Ginecologista" },
  { id: 17, title: "Hematologia" },
  { id: 18, title: "Homeopatia" },
  { id: 19, title: "Infectologia" },
  { id: 20, title: "Imunoalergologista" },
  { id: 21, title: "Medicina do Trabalho" },
  { id: 22, title: "Nefrologia" },
  { id: 23, title: "Neurocirurgia" },
  { id: 24, title: "Neurologista" },
  { id: 25, title: "Neurologista Infantil" },
  { id: 26, title: "Nutricionista" },
  { id: 27, title: "Obstetra" },
  { id: 28, title: "Odontologia" },
  { id: 29, title: "Oftalmologista" },
  { id: 30, title: "Oftalmologista Infantil" },
  { id: 31, title: "Oncologista" },
  { id: 32, title: "Ortopedista" },
  { id: 33, title: "Otorrinolaringologista" },
  { id: 34, title: "Pediatra" },
  { id: 35, title: "Pilates" },
  { id: 36, title: "Pneumologista" },
  { id: 37, title: "Proctologista" },
  { id: 38, title: "Psicólogo" },
  { id: 39, title: "Psiquiatra" },
  { id: 40, title: "Reumatologista" },
  { id: 41, title: "Urologista" }
]

const florianopolis = [
  { id: 1, title: "Acupuntura" },
  { id: 2, title: "Alergologista" },
  { id: 3, title: "Angiologista" },
  { id: 4, title: "Cardiologista" },
  { id: 5, title: "Cirurgião Plástico" },
  { id: 6, title: "Cirurgião Vascular" },
  { id: 7, title: "Clínico Geral" },
  { id: 8, title: "Dermatologista" },
  { id: 9, title: "Endocrinologista" },
  { id: 10, title: "Fisioterapia" },
  { id: 11, title: "Fonoaudiólogo" },
  { id: 12, title: "Gastroenterologia" },
  { id: 13, title: "Geriatria" },
  { id: 14, title: "Ginecologista" },
  { id: 15, title: "Hematologia" },
  { id: 16, title: "Homeopatia" },
  { id: 17, title: "Infectologia" },
  { id: 18, title: "Imunoalergologista" },
  { id: 19, title: "Medicina do Trabalho" },
  { id: 20, title: "Nefrologia" },
  { id: 21, title: "Neurocirurgia" },
  { id: 22, title: "Neurologista" },
  { id: 23, title: "Nutricionista" },
  { id: 24, title: "Obstetra" },
  { id: 25, title: "Odontologia" },
  { id: 26, title: "Oftalmologista" },
  { id: 27, title: "Oftalmologista Infantil" },
  { id: 28, title: "Oncologista" },
  { id: 29, title: "Ortopedista" },
  { id: 30, title: "Otorrinolaringologista" },
  { id: 31, title: "Pediatra" },
  { id: 32, title: "Pilates" },
  { id: 33, title: "Pneumologista" },
  { id: 34, title: "Proctologista" },
  { id: 35, title: "Psicólogo" },
  { id: 36, title: "Psiquiatra" },
  { id: 37, title: "Reumatologista" },
  { id: 38, title: "Urologista" }
]

const umuarama = [
  { id: 1, title: "Alergologista" },
  { id: 2, title: "Angiologista" },
  { id: 3, title: "Cardiologista" },
  { id: 4, title: "Cirurgião Vascular" },
  { id: 5, title: "Clínico Geral" },
  { id: 6, title: "Dermatologista" },
  { id: 7, title: "Endocrinologista" },
  { id: 8, title: "Fisioterapia" },
  { id: 9, title: "Fonoaudiólogo" },
  { id: 10, title: "Gastroenterologia" },
  { id: 11, title: "Geriatria" },
  { id: 12, title: "Ginecologista" },
  { id: 13, title: "Hematologia" },
  { id: 14, title: "Infectologia" },
  { id: 15, title: "Massoterapia" },
  { id: 16, title: "Mastologista" },
  { id: 17, title: "Nefrologia" },
  { id: 18, title: "Neurocirurgia" },
  { id: 19, title: "Neurologista" },
  { id: 20, title: "Neurologista Infantil" },
  { id: 21, title: "Nutricionista" },
  { id: 22, title: "Obstetra" },
  { id: 23, title: "Odontologia" },
  { id: 24, title: "Oftalmologista" },
  { id: 25, title: "Oftalmologista Infantil" },
  { id: 26, title: "Oncologista" },
  { id: 27, title: "Ortopedista" },
  { id: 28, title: "Otorrinolaringologista" },
  { id: 29, title: "Pediatra" },
  { id: 30, title: "Pilates" },
  { id: 31, title: "Pneumologista" },
  { id: 32, title: "Proctologista" },
  { id: 33, title: "Psicólogo" },
  { id: 34, title: "Psicopedagogia" },
  { id: 35, title: "Psiquiatra" },
  { id: 36, title: "Reumatologista" },
  { id: 37, title: "Urologista" }
]

const caraguatatuba = [
  { id: 1, title: "Exames cardiológicos" },
  { id: 2, title: "Odontologia" },
  { id: 3, title: "Pneumologista (a partir de 12 anos)" },
  { id: 4, title: "Procedimentos Estéticos" },
  { id: 5, title: "Ultrassom" }
]

export default function ContentChild( { data, showList } ) {

  const [ list, setList ] = useState( result )

  useEffect( () => {
    if ( data.data.slug === 'caraguatatuba' ) {
      setList( caraguatatuba )
    }

    if ( data.data.slug === 'umuarama' ) {
      setList( umuarama )
    }

    if (
      data.data.slug === 'florianopolis' ||
      data.data.slug === 'grandeflorianopolis' ||
      data.data.slug === 'grandeflorianopolis-exames' ||
      data.data.slug === 'palhoca' ||
      data.data.slug === 'saojose'
    ) {
      // ERROR WHEN WE TRY TO PUBLISH CODE BELLOW
      //const remove = [ 'Endocrinologista Infantil', 'Neurologista Infantil' ]
      //let list = result.filter( item => !remove.includes( item.title ) )
      //setList( list )
      setList( florianopolis )
    }
  }, [ data ] )


  return (
    <Row>
      {list.map( ( item, index ) => (
        <ColumnOne key={index}>
          <CollapsibleOne>
            <TitleEspOne >
              {item.title}
            </TitleEspOne>
          </CollapsibleOne>
        </ColumnOne>
      ) )}
    </Row>
  )
}