import React from 'react';

import { CButton } from './customButton.styles';

export const CustomButton = ({ children, ...props }) => {
  return (
    <>
      <CButton { ...props }>{children}</CButton>
    </>
  )
};
