import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description,
  TitleUnidade,
  TitleUnidadeButton,
  ContainerArea,
  TextA,
  TextWA,
  BarGrid,
  BarCard,
  Card,
  Container,
  LogoMapMarker,
  LogoWhatsApp,
  LogoTelefone,
  NoResultsText
} from './busca.styles';

import MapMarker from '../../assets/image/mapMarker.png'
import Globe from '../../assets/image/globe.png'
//import WhatsApp from '../../assets/image/whatsAppLt.png'
import WhatsApp from '../../assets/image/whatsAppLtGreen.png'
import Telefone from '../../assets/image/phoneLt.png'

export default function Busca({ dataNoResult, dataNoResultAll, dataResultUnidades, dataResultAllCities, buscarAll }) {

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>UNIDADES DE ATENDIMENTO</Title>
        <Description>Ambientes seguros e preparados para sua melhor experiência</Description>
      </Flex>

      {dataNoResult ? <><NoResultsText>Não encontramos resultados, por favor, verifique a cidade digitada!</NoResultsText></> : <></>}
      {dataNoResultAll ? <><NoResultsText style={{ color: "#007ffb" }}>Não encontramos resultados, por favor, clique no botão do WhatsApp para conhecer a unidade mais próxima de você!</NoResultsText></> : <></>}

      {dataResultUnidades ?
        <>
          {Object
            .keys(dataResultUnidades)
            .map((item, index) => (
              <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} key={index}>
                <TitleUnidade>Resultado da pesquisa</TitleUnidade>
                {Object
                  .keys(dataResultUnidades[0])
                  .map((it, id) => (
                    <ContainerArea key={id}>
                      <BarGrid>
                        <BarCard>
                          <Container>
                            <Link to={`/${dataResultUnidades[0][id].data.slug}`} style={{ textDecoration: "none" }}>
                              <TitleUnidade>{dataResultUnidades[0][id].data.nomefantasia}</TitleUnidade>
                            </Link>
                          </Container>
                        </BarCard>

                        <BarCard>
                          <Card>
                            <Container>
                              {dataResultUnidades[0][id].enderecos[0].logradouro === "online" ?
                                <>
                                </>
                                :
                                <>
                                  <TextA>
                                    {dataResultUnidades[0][id].enderecos[0].logradouro},
                                    &nbsp;{dataResultUnidades[0][id].enderecos[0].numlogradouro},
                                    {dataResultUnidades[0][id].enderecos[0].complemento ? ` ${dataResultUnidades[0][id].enderecos[0].complemento},` : ""}
                                    &nbsp;{dataResultUnidades[0][id].enderecos[0].bairro}
                                  </TextA>
                                  <span itemProp="telephone" >
                                    <TextA href={`tel:${dataResultUnidades[0][id].data.telefone_fixo.replace(/[^\d]+/g, '')}`} style={{ textDecoration: "none" }}><span><LogoTelefone src={Telefone} /></span>{dataResultUnidades[0][id].data.telefone_fixo}</TextA>
                                  </span>
                                </>
                              }
                              <TextWA
                                rel="noreferrer"
                                target="_blank"
                                href={`https://api.whatsapp.com/send?phone=55${dataResultUnidades[0][id].data.telefone_whats.replace(/[^\d]+/g, '')}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Solumedi.`}
                                style={{ textDecoration: "none" }}
                              ><span><LogoWhatsApp src={WhatsApp} /></span>{dataResultUnidades[0][id].data.telefone_whats}</TextWA>
                            </Container>
                          </Card>
                        </BarCard>

                        <BarCard>
                          <Card>
                            {dataResultUnidades[0][id].enderecos[0].logradouro === "online" ?
                              <>
                                <Container>
                                  <a
                                    href={`/${dataResultUnidades[0][id].data.slug}`}
                                    style={{ textDecoration: "none" }}
                                  ><LogoMapMarker src={Globe} />
                                  </a>
                                  <TextA>On-line</TextA>
                                </Container>
                              </>
                              :
                              <>
                                <Container>
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={`https://maps.google.com/?q=${dataResultUnidades[0][id].enderecos[0].logradouro},${dataResultUnidades[0][id].enderecos[0].numlogradouro},${dataResultUnidades[0][id].enderecos[0].bairro},${dataResultUnidades[0][id].enderecos[0].cidade},${dataResultUnidades[0][id].enderecos[0].uf}`}
                                    style={{ textDecoration: "none" }}
                                  ><LogoMapMarker src={MapMarker} />
                                  </a>
                                  <TextA>{dataResultUnidades[0][id].distancia > 0 ? `${dataResultUnidades[0][id].distancia} KM` : "Local"}</TextA>
                                </Container>
                              </>}
                          </Card>
                        </BarCard>

                      </BarGrid>
                    </ContainerArea>
                  ))}
              </Flex>
            ))}
        </>
        :
        <>
        </>
      }

      {
        dataResultAllCities ?
          <>
            {Object
              .keys(dataResultAllCities)
              .map((item, index) => (
                <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} key={index}>
                  <TextA>Você gostaria de continuar pesquisando por...</TextA>
                  {Object
                    .keys(dataResultAllCities[0])
                    .map((it, id) => (
                      <div key={id}>
                        <TitleUnidadeButton onClick={() => buscarAll(dataResultAllCities[0][id])}>{dataResultAllCities[0][id].nome} / {dataResultAllCities[0][id].codigo_uf}</TitleUnidadeButton>
                      </div>
                    ))}
                </Flex>
              ))}
          </>
          :
          <>
          </>
      }
    </>
  )
}