import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Topbar = styled.div`
  cursor: pointer;
  height: 3px;
  background: white;
  width: 100%;
  @media (min-width: 768px) {
    background: ${colors.blue3};
  }
`;
