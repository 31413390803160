import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import bannerD from '../../assets/image/bannerD.png';


export const BarGrid = styled.div`
  width: 100%;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const BarContainerVideo = styled.div`
  grid-column: span 3;
  height: 400px;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 3;
    display: block;
  }
`

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: -webkit-fill-available;
`

export const Container = styled.div`
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const ContainerIframe = styled.iframe`
  width: 280px;
  height: 280px;
  @media (min-width: 768px) {
    width: 560px;
    height: 315px;
}
`

export const BarContainerArea = styled.div`
  grid-column: span 3;  
  height: 400px;
  display: block;
  background: url(${bannerD}) right no-repeat;
  background-size: 400px;
  @media (min-width: 768px) {
    grid-column: span 3;
    display: block;
  }
`

export const Title = styled.p`
  font-size: 40px;
  font-weight: 700;
  color: ${colors.blue6};
  margin: 25px 0px 0px 25px;
  @media (min-width: 768px) {
    margin: 30px 0px 15px 25px;
  }
`

export const SubTitle = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${colors.blue3};
  margin: 5px 0px 0px 25px;
  @media (min-width: 768px) {
    margin: 5px 0px 15px 25px;
  }
`

export const Description = styled.p`
  font-size: 14px;
  color: ${colors.blue6};
  margin: 10px 15px 15px 25px;
`