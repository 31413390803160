import { Switch, useLocation } from "react-router-dom";
import SoluRoute from "./helpers/SoluRoute";

import { useEffect, useState } from "react";

import { useShow } from "./context/show";

import {
  Home,
  SobreNos,
  Exames,
  Especialidades,
  EspecialidadesDetails,
  Unidades,
  MapaUnidades,
  Blog,
  BlogDetails,
  Contato,
  ControleDados,
  FAQ,
  Termos,
  Parceiro,
  Cadastro
} from "./pages";
import {
  Header,
  Footer,
  CookiesNotification,
  WhatsAppButton,
} from "./components";

import { Model } from "./landingPages";
import { Politica } from "./landingPolitica";
import { PoliticaCancel } from "./landingPoliticaCancel";

function App() {
  const { show, showWA } = useShow();

  const location = useLocation();
  const { pathname } = location;

  const whatsappDefault = {
    text: "Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Solumedi.",
    number: "4140036534",
  };
  const [ whatsapp, setWhatsapp ] = useState( whatsappDefault );

  function ChangeWhatsapp( data ) {
    let text = data.text ? data.text : whatsappDefault.text;
    let number = data.number ? data.number : whatsappDefault.number;
    setWhatsapp( { text, number } );
  }

  return (
    <>
      <Header show={show} />

      <Switch>
        <SoluRoute exact path="/" component={Home} />
        <SoluRoute exact path="/sobrenos" component={SobreNos} />
        <SoluRoute exact path="/sobrenos/:cartao" component={SobreNos} />
        <SoluRoute exact path="/exames" component={Exames} />
        <SoluRoute exact path="/especialidades" component={Especialidades} />
        <SoluRoute exact path="/especialidades/:slug" component={EspecialidadesDetails} />
        <SoluRoute exact path="/unidades" component={Unidades} disableWA />
        <SoluRoute exact path="/cadastro/:slug" component={Cadastro} />
        <SoluRoute exact path="/mapa-unidades" component={MapaUnidades} />
        <SoluRoute exact path="/unidades/:rota" component={Unidades} disableWA />
        <SoluRoute exact path="/blog" component={Blog} />
        <SoluRoute exact path="/blog/:slug" component={BlogDetails} />
        <SoluRoute exact path="/contato" component={Contato} />
        <SoluRoute exact path="/controle-de-dados" component={ControleDados} />
        <SoluRoute exact path="/parceiro" component={Parceiro} />
        <SoluRoute exact path="/faq" component={FAQ} />
        <SoluRoute exact path="/politica-de-privacidade/" component={Termos} />

        <SoluRoute exact path="/politica-de-privacidade/:id" disableHeader>
          <Politica whatsapp={ChangeWhatsapp} />
        </SoluRoute>

        <SoluRoute exact path="/politica-de-cancelamento/:id" disableHeader>
          <PoliticaCancel whatsapp={ChangeWhatsapp} />
        </SoluRoute>

        <SoluRoute exact path="/:id" disableHeader>
          <Model whatsapp={ChangeWhatsapp} />
        </SoluRoute>
      </Switch>

      {showWA && ( <WhatsAppButton number={whatsapp.number} text={whatsapp.text} pathname={pathname} /> )}
      <CookiesNotification />
      <Footer show={show} />
    </>
  );
}

export default App;
