import React, { useEffect } from 'react';

import Banner from './banner';
import ListEspecialidades from '../../components/ListEspecialidades/listEspecialidades';
import AgendeBar from '../../components/AgendeBar/agendeBar';

export function Especialidades() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <ListEspecialidades />
      <AgendeBar />
    </>
  );
}