import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Title = styled.h2`
  text-align: center;
  word-break: break-word;
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 70px 0px 0px 0px;
  }
`

export const Description = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 12px;
  color: ${colors.gray2};
  margin: 5px 0px 20px 0px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 10px 0px 30px 0px;
  }
`

export const ContainerIframe = styled.iframe`
  width: 350px;
  height: 480px;
  border: 0;
  @media (min-width: 768px) {
    width: 768px;
    height: 480px;
  } 
`