import React from 'react';
import { Flex } from '../../assets/styles/global';

import {
  TitleA,
} from './bannerTagDescription.styles';

export default function BannerA() {

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <TitleA>
          <span style={{ color: "#007bff", fontWeight: "700" }}>
            Agende consultas médicas particulares, exames de imagem e exames laboratoriais nas melhores clínicas e laboratórios com valores reduzidos
          </span>
        </TitleA>
      </Flex>
    </>
  );
}