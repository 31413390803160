import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';


import {
  CloseX,
  Title,
  Title2,
  TitlePre,
  SubTitle,
  SubTitlePrice,
  LinkText,
  MainDiv,
  Row,
  ColumnA,
  ColumnB,
  CartaoSolumediImg,
  CButton,
  QRCodeImg
} from './bannerA.styles';

import CartaoSolumedi from '../../../assets/image/cartaoSolumedi.png';
import QRCodeImagem from '../../../assets/image/QRCodeFacilBelem.jpeg';



export default function BannerA({ data, setShow }) {

  const history = useHistory()

  const [showQRCode, setShowQRCode] = useState(false);
  const textToCopy = "00020126850014br.gov.bcb.pix0121belem@solumedi.com.br0238Parabéns você adquiriu Cartão Solumedi52040000530398654049.905802BR5909MEDISAUDE6008BRASILIA62230519CartaoSolumediBelem6304A7C1"

  function linkCopiado() {
    alert("Link copiado")
  }


  return (
    <>
      <Row style={{ justifyContent: "end" }}>
        <CloseX onClick={() => setShow()} >X</CloseX>
      </Row>

      {showQRCode ?
        <>
          <SubTitle><small>Mostre esse código QR para quem vai te pagar</small></SubTitle>
          <QRCodeImg src={QRCodeImagem} alt="cartao_solumedi" />
          <CopyToClipboard text={textToCopy}
            onCopy={linkCopiado}>
            <span style={{ color: "#007bff" }} >Clique aqui e copie o link do PIX</span>
          </CopyToClipboard>
          <SubTitlePrice>R$9,90</SubTitlePrice>
          <SubTitlePrice style={{ fontWeight: 'normal' }}><small>(taxa única)</small></SubTitlePrice>
          <SubTitle style={{ marginBottom: "0px" }}>Nosso PIX: <strong>belem@solumedi.com.br</strong></SubTitle>
          
          {/* <SubTitle>Pague também pelo <span><a href={`https://pag.getnet.com.br/cedw1BBLO`} style={{ textDecoration: 'none', color: '#007bff' }}>GETPAY</a></span></SubTitle> */}

          <SubTitle style={{ marginTop: "0px" }}><strong>Envie o comprovante WhatsApp:<br></br>
          <span><a href={`https://api.whatsapp.com/send?phone=5591980321038`} style={{ textDecoration: 'none', color: '#007bff' }}>(91) 98032-1038</a></span>
          </strong></SubTitle>

          
          <SubTitle>CartaoSolumediBelem</SubTitle>
        </>
        :
        <>
          <SubTitle>Seus dados foram enviados com SUCESSO e você já pode solicitar o seu CARTÃO SOLUMEDI</SubTitle>
          <Title>Tenha todas as vantagens do Cartão Solumedi e peça o seu agora!</Title>
          {/* <Title2>10% de redução no valor da primeira consulta e exame no mês, durante três meses.</Title2> */}
          <Title2>5% de redução no valor das suas consultas, durante seis meses.</Title2>

          <MainDiv>
            <Row>
              <ColumnA>
                <CartaoSolumediImg src={CartaoSolumedi} alt="cartao_solumedi" />
              </ColumnA>
              <ColumnB>
                <CButton
                  onClick={() => setShowQRCode(!showQRCode)}
                  width={"90%"}
                  margin={"0px 0px 0px 0px"} >EU QUERO</CButton>

              </ColumnB>
            </Row>
          </MainDiv>
          <TitlePre >Pré-Inauguração Unidade Belém</TitlePre>

        </>
      }
    </>
  );
}