import React, { useEffect } from 'react';

import {
  Flex,
  Title,
  TextDiv,
  Text,
  QuestionText
} from './termos.styles.js';

export default function CancelTerms( { data } ) {

  useEffect( () => {
    window.scrollTo( 0, 0 )
  }, [] );

  return (
    <>
      <Flex JustifyContent={"center"} FlexDirection={"column"} >
        <Title>POLÍTICA DE CANCELAMENTO E DEVOLUÇÃO DE VOUCHERS</Title>
        <TextDiv>
          <Text>
            Prezado usuário, conheça abaixo as regras para desistência, devolução e troca de vouchers:
          </Text>
          <QuestionText>
            Voucher emitido através dos canais digitais (WhatsApp OU aplicativo):
          </QuestionText>
          <Text>
            - O prazo para desistência da consulta ou exame é de até 7 dias uteis após a data de emissão do voucher;
          </Text>
          <Text>
            - Desistência após 7 dias úteis da data de emissão do voucher, será abatido 30% do valor pago a título de compensação das despesas administrativas;
          </Text>
          <Text>
            - Compras efetuadas com cartão de crédito através de link, serão devolvidos via pix em favor do títular do cartão de crédito;
          </Text>
          <Text>
            - Compras efetuadas com pix ou depósito bancário, serão devolvidos via pix em favor do títular da conta corrente do depósito de origem;
          </Text>

          <QuestionText>
            Voucher emitido em loja física:
          </QuestionText>
          <Text>
            - Prazo para desistência e pedido de reembolso é de até 120 (cento e vinte) dias. Em qualquer hipótese de desistência, a partir da emissão do voucher, será abatido 30% do valor do voucher a título de compensação das despesas administrativas;
          </Text>
          <Text>
            - Os valores serão devolvidos em dinheiro, para o portador do voucher mediante assinatura de termo de recebimento de valores (recibo);
          </Text>

          <QuestionText>
            Outras hipóteses de desistência, cancelamento, troca de voucher:
          </QuestionText>
          <Text>
            - O valor do voucher será devolvido integralmente nas hipóteses em que houver o cancelamento da consulta ou exame por iniciativa da clínica e/ou laboratório;
          </Text>
          <Text>
            - Não haverá cobrança da taxa administrativa quando o usuário decidir trocar o voucher por outro procedimento médico ou clínica, na mesma especialidade ou em qualquer outra especialidade disponível para agendamento;
          </Text>

          <Text>
            Para maiores esclarecimentos sobre esclarecimentos política de cancelamento, converse com o gerente da sua unidade Solumedi.
            Para sugestões, reclamações, esclarecimentos envie um e-mail para sac@solumedi.com.br
          </Text>

        </TextDiv>
      </Flex>
    </>
  );
}