import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import emailjs from 'emailjs-com';

import {
	DivSize,
	Description,
	DivLabel,
	TextLabel,
	TextLink
} from './form.styles'
import colors from '../../../assets/styles/colors';

export default function ContactUs() {
	const form = useRef();
	const history = useHistory();

	const [ nome, setNome ] = useState( "" )
	const [ email, setEmail ] = useState( "" )
	const [ telefone, setTelefone ] = useState( "" )
	const [ mensagem, setMensagem ] = useState( "" )
	const [ url, setUrl ] = useState()
	const [ sending, setSending ] = useState( false )
	const [ success, setSuccess ] = useState( false )
	const [ protocolNumber, setProtocolNumber ] = useState( '' )

	const sendEmail = ( e ) => {
		e.preventDefault();

		setSending( true )

		if ( nome === "" || email === "" || telefone === "" ) {
			alert( "Por favor, preencha os campos do formulário para o envio" )
			setSending( false )
			return
		}

		let protocol = createProtocol()
		setProtocolNumber( protocol )
		form.current.mensagem.value = `Solicitação de Exclusão de Cadastro Protocolo: ${protocol} - Mensagem: ${mensagem}`

		emailjs.sendForm(
			'service_g5n3u9q',
			'template_tf01qpi',
			form.current,
			'user_phJII5OS7CBLFVUrmf54E'
		)
			.then( ( result ) => {
				setSuccess( true )
			}, ( error ) => {
				setSending( false )
				alert( "Houve um erro, por favor, tente novamente" )
				console.log( error.text );
			} );
	};

	const createProtocol = () => {
		let value = new Date()
		value = value.toISOString()
		let time = ''
		if ( value.length > 10 ) time = value.substring( 11, 13 )
		if ( value.length > 10 ) time += value.substring( 14, 16 )

		if ( value.length > 10 ) value = value.substring( 0, 10 )

		let v = value.replace( /\D/g, '' ).slice( 0, 10 );
		v = `${v.slice( 0, 4 )}${v.slice( 4, 6 )}${v.slice( 6 )}`;
		return v + time
	}

	return (
		<>
			{sending ?
				<>
					{success
						? <Description color={'black'}>Solicitação realizada com sucesso! Seu protocolo de atendimento é {protocolNumber}</Description>
						: <Description>Enviando...</Description>
					}
				</>
				:
				<>
					<DivSize>


						<form
							ref={form}
							onSubmit={sendEmail}
							style={{
								width: "90%"
							}}
						>
							<input hidden type="text" name="mailTo" value="consultoria3@solumedi.com.br" />

							<input
								type="text"
								name="name"
								placeholder='Nome completo'
								onChange={( e ) => setNome( e.target.value )}
								style={{
									border: `1px solid ${colors.gray1}`,
									borderRadius: "50px",
									padding: "1rem 3rem",
									marginBottom: "20px",
									width: "-webkit-fill-available",
									position: "relative",
									display: "inline-block",
									font: "inherit",
									fontSize: "21px",
									boxShadow: "0 4px 8px 0 rgba(0,0,0,0.7)"
								}}
							/>

							<input
								type="email"
								name="email"
								placeholder='E-mail'
								onChange={( e ) => setEmail( e.target.value )}
								style={{
									border: `1px solid ${colors.gray1}`,
									borderRadius: "50px",
									padding: "1rem 3rem",
									marginBottom: "20px",
									width: "-webkit-fill-available",
									position: "relative",
									display: "inline-block",
									font: "inherit",
									fontSize: "21px",
									boxShadow: "0 4px 8px 0 rgba(0,0,0,0.7)"
								}}
							/>

							<input
								type="number"
								name="telefone"
								placeholder='CPF'
								onChange={( e ) => setTelefone( e.target.value )}
								style={{
									border: `1px solid ${colors.gray1}`,
									borderRadius: "50px",
									padding: "1rem 3rem",
									marginBottom: "20px",
									width: "-webkit-fill-available",
									position: "relative",
									display: "inline-block",
									font: "inherit",
									fontSize: "21px",
									boxShadow: "0 4px 8px 0 rgba(0,0,0,0.7)"
								}}
							/>

							<textarea
								type="text"
								id="mensagem"
								name="mensagem"
								rows="4"
								placeholder="Digite aqui o motivo para exclusão..."
								onChange={( e ) => setMensagem( e.target.value )}
								style={{
									border: `1px solid ${colors.gray1}`,
									borderRadius: "25px",
									padding: "1rem 3rem",
									marginBottom: "20px",
									width: "-webkit-fill-available",
									position: "relative",
									display: "inline-block",
									font: "inherit",
									fontSize: "21px",
									boxShadow: "0 4px 8px 0 rgba(0,0,0,0.7)",
								}}
							/>

							<DivLabel>
								<TextLabel>A exclusão dos dados ocorre de acordo com procedimento estabelecido nos
									<span>
										<TextLink
											href={url}
											style={{ textDecoration: "none" }}
										>
											&nbsp;Termos de Uso&nbsp;
										</TextLink>
									</span>
									deste site.</TextLabel>
							</DivLabel>
							<input
								type="submit"
								value="CONTINUAR"
								maxLength="11"
								style={{
									borderRadius: "50px",
									marginTop: "10px",
									padding: "1rem 3rem",
									marginBottom: "20px",
									width: "-webkit-fill-available",
									position: "relative",
									display: "inline-block",
									font: "inherit",
									fontSize: "21px",
									cursor: "pointer"
								}}
							/>
						</form>
					</DivSize>
				</>
			}
		</>
	);
};