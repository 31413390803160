import React from 'react';
import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description,
  ContainerIframe
} from './mapaUnidades.styles';

import Banner from './banner';
import AgendeBar from '../../components/AgendeBar/agendeBar';

export function MapaUnidades() {
  const titleBanner = 'Brasil'

  return (
    <>
      <Banner titleBanner={titleBanner} />
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>MAPA DAS UNIDADES</Title>
        <Description>Localização das unidades da rede Solumedi no Brasil</Description>
        <ContainerIframe src={"https://www.google.com/maps/d/u/1/embed?mid=1VF2rhw-4GcqeqGSsdOczkqiUb22I1XH_"}
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></ContainerIframe>
      </Flex>
      <AgendeBar />
    </>
  );
}