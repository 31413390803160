import styled from 'styled-components';
import banner from '../../assets/image/unidades-bg-banner.jpg';
// IMAGEM 1920 X 1200
import bannerLt from '../../assets/image/unidades-bg-bannerLt.jpg';
// IMAGEM 512 X 290

export const Leading = styled.section`
  flex-direction: column;
  height: 100px;
  padding: 30px;
  background: url(${bannerLt}) top #2A4F72 no-repeat;
  background-size: 120%;
  color: white;
  text-shadow: 0px 0px 5px #000;
  background-attachment: fixed;
    @media (min-width: 700px) {
      height: 320px;
      display: flex;
      align-items: center;
      background: url(${banner}) center #2A4F72 no-repeat;
      background-attachment: fixed;
   }
`

export const LeadingBigText = styled.p`
  margin-right: 30%;
  font-weight: bold;
  font-size: 9vw;
  color: white;
  @media (min-width: 700px) {
    margin-right: 40%;
    font-size: 90px;
  }
`