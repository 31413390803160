import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";

import Banner from './banner';
import Form from './form';

export function Cadastro() {

  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <Form data={id}/>
    </>
  );
}