import React from 'react';

import {
  Topbar,
  TopDiv,
  TopLink,
  TopLink2,
  Nav,
  Logo
} from './header.styled';

import Logomarca from '../../assets/solumedi.png'

export function Header( { email } ) {
  const isOpen = false;
  return (
    <>
      {isOpen ?
        <></>
        :
        <>
          <Topbar>
            <TopLink>{email}</TopLink>
            <TopDiv>
              <TopLink2>UNIDADE FRANQUEADA{email === 'salvador@solumedi.com.br' && ' SALVADOR'}</TopLink2>
            </TopDiv>
          </Topbar>
        </>
      }
      <Nav>
        <Logo src={Logomarca} />
      </Nav>
    </>
  );
}
