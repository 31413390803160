import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerTitle,
  Title,
  SubTitle,
  BarContainerInput,
  Form,
  FormInput,
  FormButton,
  CButton
} from './unidadesModal.styles';

import Autocomplete from './autocomplete';

export default function UnidadesModal({ callCheckCity, setUnidadesModal }) {

  const history = useHistory()
  const [dataValue, setDataValue] = useState("")

  const buscar = () => {
    if(dataValue === undefined || dataValue === null || dataValue === "") return
    setUnidadesModal(false)
    if (!callCheckCity) {
      history.push(`/unidades/${dataValue}`)
    } else {
      callCheckCity(dataValue)
    }
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} Background={"#2a4f72"}>
        <BarGrid>
          <BarContainerTitle>
            <Title>Por favor, informe a sua unidade Solumedi de atendimento!</Title>
            <SubTitle>A Política de Privacidade da Solumedi está localizada no rodapé da landing-page da sua unidade de atendimento. Por favor, informe o nome da cidade e clique em "Buscar", você será redirecionado para área das unidades e em seguida, basta selecionar a unidade desejada e pronto!</SubTitle>
          </BarContainerTitle>
          <BarContainerInput>
            <Form>
              <FormInput>
                <Autocomplete currentValue={setDataValue} />
              </FormInput>
              <FormButton>
                <CButton onClick={buscar}>Buscar</CButton>
              </FormButton>
            </Form>
          </BarContainerInput>
        </BarGrid>
      </Flex>
    </>
  );
}