import React from 'react';

import {
  Card,
  MaxContainerImg,
  Container,
  TitleH4,
  TextP,
  ButtonDiv,
  ContainerIframe
} from './LpBoxAddress.styles';

import { CustomButton } from '../../components/CustomButton/customButton';

export default function BoxEspecialidade({ data }) {

  const seeLocal = () => {
    window.open(`${data.enderecos[0].url}`, "_blank")
  }

  return (
    <>
      <Card>
        <MaxContainerImg>
          <ContainerIframe src={data.enderecos[0].url}
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></ContainerIframe>
        </MaxContainerImg>

        <Container>
          <TitleH4>{data.data.nomefantasia}</TitleH4>
          <TextP>
            {data.enderecos[0].logradouro},
            &nbsp;{data.enderecos[0].numlogradouro}
            {data.enderecos[0].complemento ? `, ${data.enderecos[0].complemento}` : ""}<br></br>
            {data.enderecos[0].bairro}
            </TextP>
        </Container>
        <ButtonDiv>
          <CustomButton
            onClick={seeLocal}
            color={"#2a4f72"}
            background={"#fdd460"}
            margin={"0px 0px 10px 0px"}
            width={"75%"}>
            LOCALIZAÇÃO
          </CustomButton>
        </ButtonDiv>
      </Card>
    </>
  );
}