import React from "react";
import { Route } from "react-router-dom";
import { useShow } from "../context/show";

export default function SoluRoute({ children, disableHeader, disableWA, ...props }) {
  
  const { setShow, setShowWA } = useShow();

  if (disableHeader) {
    setShow(false);
  } else {
    setShow(true);
  }
  
  if (disableWA) {

    setShowWA(false);
  } else {

    setShowWA(true);
  }

  return <Route {...props}>{children}</Route>;
}
