import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Section = styled.section``

export const Title = styled.h2`
  word-break: break-word;
  font-size: 24px;
  color: ${colors.blue4};
  margin: 25px 10px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 30px 0px 0px 0px;
  }
`

export const Description = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 12px;
  color: ${colors.gray2};
  margin: 5px 10px 50px 10px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 10px 0px 50px 0px;
  }
`

export const DescriptionA = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 15px;
  color: ${colors.gray2};
  margin: 5px 10px 50px 10px;
  @media (min-width: 768px) {
    font-size: 24px;
    margin: 10px 0px 50px 0px;
  }
`

export const BarGrid = styled.div`
  width: 90%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
  }
`

export const BarCard = styled.div`
  grid-column: span 3;
  display: block;
  margin-bottom: 50px;
  @media (min-width: 768px) {
    grid-column: span 1;
    display: block;
  }
`

export const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
`

export const DivNumber = styled.div`
  width: 35%;
  display: flex;
  justify-content: center;
`

export const DivText = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
`

export const Icones = styled.img`
  height: 70px;
`

export const SubTitleText = styled.p`
  word-break: break-word;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.blue5};
  margin: 0px 10px 0px 10px;
  @media (min-width: 768px) {
    font-size: 21px;
  }
`

export const MensagemText = styled.p`
  word-break: break-word;
  /* text-align: justify; */
  text-align: ${props => props.align};
  font-size: 15px;
  color: ${colors.blue6};
  margin: 10px 10px 0px 10px;
`

export const Row = styled.div`
  width: 90%;
  display: flex;
  flex-flow: row wrap;
`

export const CustomButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: ${props => props.background ? props.background : colors.blue6};
  border: none;
  border-radius: 50px;
  padding: 1rem 1.5rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin: 0px 0px 0px 0px;
  color: ${props => props.color ? props.color : 'white'};
  width: 50%;
  position: relative;
  display: inline-block;
  @media (min-width: 768px) {
    width: 30%;
  }
  :hover{
    opacity: 0.7;
  }
`