import styled from 'styled-components';
import colors from '../../../assets/styles/colors';
import banner from '../../../assets/image/home-page-bg-bannerVendas.jpg';


export const Leading = styled.section`
  flex-direction: column;
  height: 270px;
  padding: 10px 20px 30px 20px;
  background: url(${banner}) center #2A4F72 no-repeat;
  background-size: cover;
  color: white;
  text-shadow: 0px 0px 5px #000;
  /* background-attachment: fixed; */
    @media (min-width: 700px) {
      padding: 0px 30px 30px 30px;
      height: 700px;
      display: flex;
      align-items: center;
      background: url(${banner}) top #2A4F72 no-repeat;
      /* background-attachment: fixed; */
   }
`

export const LeadingBigText = styled.p`
  margin: 7% 35% 0% 0%;
  font-weight: bold;
  font-size: 5vw;
  @media (min-width: 700px){
    margin: 7% 50% 0% 0%;
    font-size: 4vw;
  } 
  @media (min-width: 1500px){
    margin: 5% 50% 0% 0%;
    font-size: 4vw;
  }
  
`

export const ButtonsDiv = styled.div`
  align-self: start;
  width: 180px;
  margin: 1% 0% 1% 10%;
  @media (min-width: 700px) {
    width: 240px;
    margin: 1% 0% 1% 20%;
  }
`

export const CustomButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: ${colors.blue6 };
  border: none;
  border-radius: 50px;
  padding: 10px;
  font-size: 12px;
  font-weight: 900;
  margin: 10px 0px 0px 0px;
  color: white;
  width: 100%;
  position: relative;
  display: inline-block;
  :hover{
    opacity: 0.7;
  }
  @media (min-width: 700px) {
    padding: 20px;
    font-size: 16px;
  }
`

export const PriceDivA = styled.div`
  width: 60%;
  @media (min-width: 700px) {
    width: 50%;
    align-self: start;
  }
` 

export const LeadingText = styled.p`
  color: ${colors.yellow2};
  font-weight: bold;
  font-size: 4vw;
  margin: 5% 25% 0% 0%;
  @media (min-width: 700px) {
    margin: 5% 5% 0% 0%;
    font-size: 3vw;
  }
`

export const DivBottom = styled.div`
  bottom: 0;
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
` 

export const TextName = styled.p`
  text-align: right;
  color: white;
  font-weight: bold;
  font-style: italic;
  font-size: 10px;
  @media (min-width: 700px) {
    font-size: 16px;
  }
`

export const DivSelo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: ${colors.yellow2 };
  @media (min-width: 700px) {
    width: 180px;
    height: 180px;
  }
`

export const TextSelo = styled.p`
  text-shadow: none;
  text-align: center;
  transform: rotate(340deg);
  color: ${colors.blue6};
  font-weight: bold;
  font-size: 9px;
  @media (min-width: 700px) {
    font-size: 18px;
  }
`