import styled from 'styled-components';
import colors from '../../../assets/styles/colors';

export const Title = styled.h2`
  text-align: center;
  word-break: break-word;
  font-size: 24px;
  color: ${colors.blue4 };
  margin: 0px 10px 5px 10px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 0px 10px 5px 10px;
  }
`

export const Title2 = styled.h3`
  text-align: center;
  word-break: break-word;
  font-size: 14px;
  color: ${colors.blue6 };
  margin: 0px 25px 0px 25px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 0px 10px 0px 10px;
  }
`

export const TitlePre = styled.h2`
text-align: center;
word-break: break-word;
font-size: 16px;
color: ${colors.blue6 };
margin: 0px 10px 5px 10px;
@media (min-width: 768px) {
  font-size: 24px;
  margin: 0px 10px 5px 10px;
}
`

export const SubTitle = styled.p`
  text-align: center;
  font-size: 15px;
  
  margin: 5px 10px 5px 10px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 5px 10px 5px 10px;
  }
`
export const SubTitlePrice = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  
  margin: 0px 10px 0px 10px;
  @media (min-width: 768px) {
    font-size: 24px;
    margin: 0px 10px 0px 10px;
  }
`

export const LinkText = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 12px;
  color: ${colors.blue4};
  margin: 15px 30px 20px 30px;
  @media (min-width: 768px) {
    font-size: 16px;
    margin: 15px 30px 30px 30px;
  }
`



export const CloseX = styled.p`
  /* text-align: right; */
  /* sefl-align: end; */
  font-weight: bold;
  font-size: 16px;
  color: ${colors.gray2};
  cursor: pointer;
  margin: 0px 10px 0px 10px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 0px 10px 0px 10px;
  }
`

export const TitleA = styled.p`
  cursor: pointer;
  text-align: center;
  word-break: break-word;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.gray2};
  margin: 25px 15px 25px 15px;
  @media (min-width: 768px) {
    margin: 35px 0px 35px 0px;
    font-size: 24px;
  }
  :hover{
    opacity: 0.7;
  }
`

export const TitleB = styled.p`
  text-align: right;
  cursor: pointer;  
  word-break: break-word;
  font-size: 14px;
  font-weight: 700;
  color: ${colors.blue6};
  margin: 50px 15px 0px 0px;
  @media (min-width: 768px) {
    text-align: left;
    margin: 50px 0px 25px 0px;
    font-size: 24px;
  }
  :hover{
    opacity: 0.7;
  }
`

export const MainDiv = styled.div`
  width: 100%;
  background-image: linear-gradient(bottom, ${colors.yellow2} 20%, white 0%);
  background-image: -o-linear-gradient(bottom, ${colors.yellow2} 20%, white 0%);
  background-image: -moz-linear-gradient(bottom, ${colors.yellow2} 20%, white 0%);
  background-image: -webkit-linear-gradient(bottom, ${colors.yellow2} 20%, white 0%);
  background-image: -ms-linear-gradient(bottom, ${colors.yellow2} 20%, white 0%);
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  
`

export const ColumnA = styled.div`
  float: left;
  width: 50%;
  padding: 0px;
  height: 120px;
  @media (min-width: 768px) {
    height: 210px;
  }
`

export const ColumnB = styled.div`
  float: left;
  width: 50%;
  padding: 0px;
  height: 120px;
  @media (min-width: 768px) {
    height: 210px;
  }
`

export const CartaoSolumediImg = styled.img`
  margin-top: 10px;
  height: 110px;
  @media (min-width: 768px) {
    margin-top: 10px;
    height: 200px;
  }
`

export const QRCodeImg = styled.img`
  margin-top: 0px;
  height: 180px;
  @media (min-width: 768px) {
    margin-top: 0px;
    height: 280px;
  }
`

export const DivOff = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
}
`

export const Description = styled.p`
  
  text-align: justify;
  font-size: 21px;
  color: ${colors.blue5};
  margin: 25px 50px 0px 0px;
  
`

export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: ${props => props.background ? props.background : colors.blue6 };
  border: none;
  border-radius: 50px;
  padding: 1rem 3rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin: 0px;
  color: ${props => props.color ? props.color : 'white' };
  width: ${props => props.width ? props.width : '100%' };
  position: relative;
  display: inline-block;
  @media (min-width: 768px) {
    margin-top: 30px;
  }
  :hover{
    opacity: 0.7;
  }
`
