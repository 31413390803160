import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import SBoxBlog from '../../assets/image/sBoxBlog.png';

export const Card = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
  }
`

export const MaxContainerImg = styled.div`
  position: relative;
  width: 100%;
  height: 290px;
  /* height: -webkit-fill-available; */
  /* background: url(${props => props.url ? props.url : SBoxBlog }) center no-repeat; */
  background-size: cover;
  @media (min-width: 768px) {
    height: 250px;
  }
  :hover {
    opacity: 0.7;
  }
`

export const Container = styled.div`
  padding: 2px 16px;
`

export const TitleH4 = styled.h4`
  font-size: 21px;
  font-weight: 700;
  color: ${colors.blue4};
  margin: 5px 0px 0px 0px;
`

export const TextP = styled.p`
  font-size: 12px;
  color: ${colors.blue6};
  margin: 5px 0px 5px 0px;
`

export const ButtonDiv = styled.div`
  text-align: center;
`

export const ContainerIframe = styled.iframe`
  width: 100%;
  height: 290px;
  border: 0;
  @media (min-width: 768px) {
    height: 250px;
  } 
`