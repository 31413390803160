import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description,
  BarGrid,
  BarCard,
  MainDiv,
  DivNumber,
  DivText,
  NumberText,
  MensagemText

} from './howTo.styles';

export default function HowTo() {
  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} Background={"#eaeaea"} style={{padding: "25px 0px 25px 0px"}} >
        <Title>CARTÃO SOLUMEDI</Title>
        <Description>Veja como usar, é rápido e fácil agendar com o cartão Solumedi</Description>
      </Flex>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} Background={"#eaeaea"} style={{paddingBottom: "25px"}} >
        <BarGrid>
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>1</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                  Faça o seu <strong>cadastro</strong> e da sua família <strong>gratuitamente</strong>.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>2</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                  Retire o seu cartão <strong>Solumedi</strong> sem <strong>mensalidades</strong> ou <strong>anuidades</strong>.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>3</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                  <strong>Agende</strong> consultas, exames e dentistas, com <strong>valores reduzidos</strong>.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>4</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                  Aí é só ir no endereço do médico ou laboratório <strong>agendado!</strong>
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

        </BarGrid>

      </Flex>

    </>
  );
}