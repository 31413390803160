import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardAfter,
  Container,
  TitleH4,
  TextP
} from './boxBlog.styles';

import { CustomButton } from '../../components/CustomButton/customButton';
import BlogExemplo from '../../assets/image/blogExemplo.jpg';

export default function BoxBlog({post}) {

  const history = useHistory()

  return (
    <>
      <Card url={post._embedded["wp:featuredmedia"][0].source_url ? post._embedded["wp:featuredmedia"][0].source_url : BlogExemplo}>
        <CardAfter>
        <Container>
          <TitleH4>{post.title.rendered ? post.title.rendered : "..."}</TitleH4>
          <TextP>{post.excerpt.rendered ? post.excerpt.rendered.substring(3,140)+"..." : "..."}</TextP>
            <CustomButton
            onClick={() => history.push(`/blog/${post.slug}`)}
            color={"white"}
            background={"#2a4f72"}
            margin={"0px 0px 10px 0px"}
            width={"100%"}>
              CONTINUAR
              </CustomButton>
        </Container>
        </CardAfter>
      </Card>
    </>
  );
}