import React, { useState, useEffect, useRef } from 'react';

import { Header } from './LpHeader/header';
import { Footer } from './LpFooter/footer';

import BannerMain from './BannerMain/banner';
import BannerVideo from './BannerVideo/bannerVideo';
import BannerNumeros from './BannerNumeros/bannerNumeros';
import Form from './Form/form';
import BannerA from './BannerA/bannerA';
import Modal from './Modal/modal';

import { Topbar } from './modelVendas.styles';


export default function ModelA( { data } ) {

  const [ showed, setShowed ] = useState()
  const [ showModal, setShowModal ] = useState()


  useEffect( () => {
    window.scroll( 0, 0 );
  }, [] );

  const cRef = useRef( null );
  useEffect( () => cRef.current && cRef.current.focus() );

  const callPopUp = () => {
    if ( !showed ) {
      setShowed( true )
      setShowModal( true )
    }
  }

  const callSetShowModal = () => {
    setShowModal( !showModal )
  }

  return (
    <>
      {showModal ? <Modal callSetShowModal={callSetShowModal} /> : <></>}

      <Topbar
        onMouseOver={callPopUp}
      />
      <Header email={data.data.email_adm} />

      <BannerMain ref={cRef} />

      <BannerVideo />
      <BannerNumeros />
      <Form />
      <BannerA />
      <Footer data={data} />
    </>
  );
}