export const timeDate = (data) => {
    var meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ];

      var hoje = new Date()
      console.log(hoje)
      var mes = meses[hoje.getMonth()];
    return mes
  }