import styled from "styled-components"
import colors from '../../assets/styles/colors';

export const Bottom = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${colors.blue6};
  text-align: center;
  padding: 5px 0px 5px 0px;
`

export const BottomText = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin: 0;
`

export const ButtonDiv = styled.div`
  width: 90%;
  @media (min-width: 768px) {
    width: 20%;
  }
`

export const ContainerArea = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${colors.blue5};
  padding: 5px 0px 30px 0px;
  text-align: center;
  @media (min-width: 768px) {
    padding: 5px 0px 0px 0px;
  }
`

export const LogoSocial = styled.img`
  cursor: pointer;
  width: 7%;
  margin: 5px 10px 0px 10px;
  @media (min-width: 768px) {
    margin: 5px 10px 0px 10px;
    width: 20%;
  }
  :hover{
    opacity: 0.7;
  }
`

export const Description = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: white;
  margin: 5px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0px 0px 0px;
  }
`

export const Enderecos = styled.p`
  font-size: 10px;
  color: white;
  margin: 0px;
  @media (min-width: 768px) {
    font-size: 12px;
    margin: 0px;
  }
`

export const HashTag = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: white;
  margin: 5px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 700;
    margin: 0px;
  }
`

export const PoliticaP = styled.p`
  font-size: 10px;
  color: white;
  margin: 5px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 12px;
    margin: 5px 0px 0px 0px;
  }
  :hover{
    cursor: pointer;
    opacity: 0.7;
  }
`

export const BarGrid = styled.div`
  width: 100%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`

export const BarCard = styled.div`
  /* grid-column: span 3; */
  height: 300px;
  display: block;
  @media (min-width: 768px) {
    /* grid-column: span 3; */
    display: block;
  }
`

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: -webkit-fill-available;
  background: url(${props => props.url ? props.url : ""}) center no-repeat;
  background-size: 70%;
  @media (min-width: 768px) {
    background-size: 45%;
}
`

export const Row = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  
`

export const RowEnderecos = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 5px;
  @media (min-width: 768px) {
    margin-bottom: 0px;
}
`

export const Column = styled.div`
  @media (min-width: 768px) {
    width: 13%;
  }
`

export const LogoImg = styled.div`
  width: 140px;
  height: 140px;
  background: url(${props => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (min-width: 768px) {
    width: 250px;
    height: 250px;
  }
`