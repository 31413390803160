import React, { useEffect, useState } from 'react';

import BannerStand from './lpBannerStand';
import BannerOdonto from './lpBannerOdonto';
import BannerSorocabaOdonto from './lpBannerSorocabaOdonto';
import BannerCuritiba from './lpBannerCuritibaOftalmo';
import {LpBannerAraucariaOftalmo} from './lpBannerAraucariaOftalmo';

export default function LpBannerA({ data }) {

  const [showBannerStand, setShowBannerStand] = useState()
  const [showBannerOdonto, setShowBannerOdonto] = useState()
  const [showBannerCuritiba, setShowBannerCuritiba] = useState()
  const [showBannerAraucaria, setShowBannerAraucaria] = useState()
  const [showBannerSorocabaOdonto, setShowBannerSorocabaOdonto] = useState()


  useEffect(() => {
    if (data.data.slug === "picarras") {
      setShowBannerStand(true)
    }
    if (data.data.slug === "picarras-odonto") {
      setShowBannerOdonto(true)
    }
    if (data.data.slug === "sorocaba-odonto") {
      setShowBannerSorocabaOdonto(true)
    }
    if (data.data.slug === "araucaria-oftalmologia") {
      setShowBannerAraucaria(true)
    }
    if (
      data.data.slug === "curitiba-centro-oftalmologia" ||
      data.data.slug === "curitiba-cajuru-oftalmologia"
    ) {
      setShowBannerCuritiba(true)
    }
  }, [data])

  return (
    <>
      {showBannerAraucaria ? <LpBannerAraucariaOftalmo data={data} /> : <></>}
      {showBannerCuritiba ? <BannerCuritiba data={data} /> : <></>}
      {showBannerStand ? <BannerStand data={data} /> : <></>}
      {showBannerOdonto ? <BannerOdonto data={data} /> : <></>}
      {showBannerSorocabaOdonto ? <BannerSorocabaOdonto data={data} /> : <></>}
    </>
  );
}