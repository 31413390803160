import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Flex } from '../../assets/styles/global';
import { ButtonDiv } from './politica.styles';

import { CustomButton } from "../../components/CustomButton/customButton";

import { Header } from '../../components/LpHeader/header';
import { Footer } from '../../components/LpFooter/footer';

import Banner from "../../components/CancelTerms/banner";

import Termos from "../../components/CancelTerms/cancelTerms";

import unidades from "../../content/unidades.json";

export function PoliticaCancel( { whatsapp } ) {
  const { id } = useParams();
  const history = useHistory();

  const [ result, setResult ] = useState();

  useEffect( () => {
    var data = unidades.find( ( unidade ) => unidade.data.slug === id );
    if ( !data ) {
      history.push( "/" );
      return;
    }

    if ( data.data.telefone_whats ) {
      whatsapp( {
        text: data.data.mensagem_whats,
        number: data.data.telefone_whats,
      } );
    }
    setResult( data );

  }, [ id ] );

  function backToLp() {
    history.push( `/${result.data.slug}` );
  }

  return (
    <>
      {result ? (
        <>
          <Header email={result.data.email_adm} />
          <Banner />
          <Termos data={result} />
          <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
            <ButtonDiv>
              <CustomButton
                margin={"0px 0px 20px 0px"}
                color={"#2a4f72"}
                background={"#fdd460"}
                onClick={backToLp}
              >
                Voltar para Página
              </CustomButton>
            </ButtonDiv>
          </Flex>
          <Footer data={result} />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
