import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: ${props => props.background ? props.background : colors.blue6};
  border: none;
  border-radius: 50px;
  padding: 1rem 1.5rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin: ${props => props.margin ? props.margin : '0'};
  color: ${props => props.color ? props.color : 'white'};
  width: ${props => props.width ? props.width : '100%'};
  position: relative;
  display: inline-block;
  :hover{
    opacity: 0.7;
  }
`