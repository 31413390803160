import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import ModelA from "../ModelA/modelA";

import ModelC from "../ModelC/modelC";
import ModelD from "../ModelD/modelD";
import ModelF from "../ModelF/modelF";
import ModelG from "../ModelG/modelG";
import ModelJIVO from "../ModelJIVO/modelJIVO";
import ModelEmBreve from "../ModelEmBreve/modelEmBreve";
import ModelOnline from "../ModelOnline/modelOnline";
import ModelVendas from "../ModelVendas/modelVendas";
import ModelObrigado from "../ModelObrigado/modelObrigado";

import unidades from "../../content/unidades.json";

export function Model( { whatsapp } ) {
  const { id } = useParams();
  const history = useHistory();

  const [ result, setResult ] = useState();
  const [ callModelA, setCallModelA ] = useState( false );

  const [ callModelC, setCallModelC ] = useState( false );
  const [ callModelD, setCallModelD ] = useState( false );

  const [ callModelF, setCallModelF ] = useState( false );
  const [ callModelG, setCallModelG ] = useState( false );
  const [ callModelJIVO, setCallModelJIVO ] = useState( false );
  const [ callModelEmBreve, setCallModelEmBreve ] = useState( false );

  const [ callModelOnline, setCallModelOnline ] = useState( false );
  const [ callModelVendas, setCallModelVendas ] = useState( false );
  const [ callModelObrigado, setCallModelObrigado ] = useState( false );

  const [ callModelForm, setcallModelForm ] = useState( false )


  useEffect( () => {
    var data = unidades.find( ( unidade ) => unidade.data.slug === id );
    if ( !data ) {
      history.push( "/" );
      return;
    }

    if ( data.data.telefone_whats ) {
      whatsapp( {
        text: data.data.mensagem_whats,
        number: data.data.telefone_whats,
      } );
    }
    setResult( data );

    switch ( true ) {
      case id === "sclitoralcentro-i":
        //SC LITORAL CENTRO
        setCallModelC( true );
        break;

      case id === "sclitoralcentro-bcinc":
        //SC LITORAL CENTRO
        setCallModelG( true );
        break;

      case id === "balneariocamboriu":
      case id === "itajai":
      case id === "navegantes":
      case id === "camboriu":

        //SC LITORAL CENTRO
        history.push( "/sclitoralcentro-bcinc" );
        break;

      case id === "colombo-jardimosasco":
      case id === "colombo-maracana":
        //SOMENTE COLOMBO
        history.push( "/colombo" );
        break;

      case id === "prnortepioneiro":
        //PR NORTE PIONEIRO
        setCallModelD( true );
        break;

      case id === "batatais":
      case id === "blumenau":
      case id === "suzano":
      case id === "curitiba-augusta":
      case id === "quatro-barras":
      case id === "campomagro":
      case id === "tubarao":
      case id === "ararangua":
      case id === "riobrancodosul":
      case id === "curitiba-orleans":
        //ONLINE
        setCallModelOnline( true );
        break;

      case id === "picarras":
      case id === "picarras-odonto":
        setCallModelF( true );
        break;

      case id === "sorocaba-odonto":
        setCallModelF( true );
        break;

      case id === "araucaria-oftalmologia":
        setCallModelF( true );
        break;

      case id === "curitiba-centro-oftalmologia":
      case id === "curitiba-cajuru-oftalmologia":
        setCallModelF( true );
        break;

      case id === "cadastro/curitiba-tiradentes":
        //EM BREVE
        setCallModelEmBreve( true );
        break;

      case id === "pinhais":
        //JIVO
        setCallModelJIVO( true );
        break;

      case id === "seja-franqueado":
        //ONLINE
        setCallModelVendas( true );
        break;

      case id === "obrigado":
        //ONLINE
        setCallModelVendas( false );
        setCallModelObrigado( true );
        break;

      case id === "belem":
      case id === "curitiba-centro-2":
        setcallModelForm( true )
        break;


      default:
        setCallModelA( true );
    }
  }, [ id ] );

  return (
    <>
      {result ? (
        <>
          {callModelA ? <ModelA data={result} /> : <></>}

          {callModelC ? <ModelC data={result} /> : <></>}
          {callModelD ? <ModelD data={result} /> : <></>}

          {callModelF ? <ModelF data={result} /> : <></>}
          {callModelG ? <ModelG data={result} /> : <></>}
          {callModelJIVO ? <ModelJIVO data={result} /> : <></>}

          {callModelEmBreve ? <ModelEmBreve data={result} /> : <></>}
          {callModelOnline ? <ModelOnline data={result} /> : <></>}
          {callModelVendas ? <ModelVendas data={result} /> : <></>}
          {callModelObrigado ? <ModelObrigado data={result} /> : <></>}
          {callModelForm ? <ModelA data={result} /> : <></>}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
