import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const TitleA = styled.h1`
  text-align: center;
  word-break: break-word;
  font-size: 24px;
  font-weight: 700;
  color: ${colors.gray2};
  margin: 25px 15px 25px 15px;
  @media (min-width: 768px) {
    margin: 35px 0px 35px 0px;
    font-size: 40px;
  }
`
