import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import Banner from './banner';
import UnidadesBar from '../../components/unidadesBar/unidadesBar';
import { Loading } from '../../components/Loading/loading';
import Busca from './busca';
import AgendeBar from '../../components/AgendeBar/agendeBar';

import { foundCity, foundInAllCities } from '../../helpers/getPosition';


export function Unidades() {

  const { rota } = useParams()
  const [titleBanner, setTitleBanner] = useState('Unidades')
  const [resultUnidades, setResultUnidades] = useState(false)
  const [resultAllCities, setResultAllCities] = useState(false)
  const [noResult, setNoResult] = useState(false)
  const [noResultAll, setNoResultAll] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (rota && rota === "buscar") {
      setTitleBanner('Solumedi')
    } else {
      checkCity(rota)
    }
  }, [rota]);

  async function checkCity(rota) {
    setLoading(true)
    var result = await foundCity(rota)

    if (result === null) {
      // NENHUM RESULTADO
      setNoResult(true)
      setNoResultAll(false)
      setResultUnidades(false)
      setResultAllCities(false)
      setLoading(false)
      return
    }

    // RESULTADO COMO UNIDADES
    try {
      if (result[0][0].data) {
        setResultUnidades(result)
        setResultAllCities(false)
        setNoResult(false)
        setNoResultAll(false)
        setLoading(false)
        return
      }
    } catch {
    }

    // RESULTADO COMO CIDADES
    try {
      if (result[0][0].nome) {
        setResultAllCities(result)
        setResultUnidades(false)
        setNoResult(false)
        setNoResultAll(false)
        setLoading(false)
        return
      }
    } catch {
    }
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  };

  async function buscarAll(params) {
    var result = await foundInAllCities(params)
    try {
      if (result[0][0].data) {
        setResultUnidades(result)
        setResultAllCities(false)
        setNoResult(false)
        setNoResultAll(false)
        setLoading(false)
        return
      }
    } catch {
      setResultUnidades(false)
      setResultAllCities(false)
      setNoResult(false)
      setNoResultAll(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Banner titleBanner={titleBanner} />
      <UnidadesBar callCheckCity={checkCity} />
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        {loading ? <Loading /> : <></>}
      </Flex>
      <Busca dataNoResult={noResult} dataNoResultAll={noResultAll} dataResultUnidades={resultUnidades} dataResultAllCities={resultAllCities} buscarAll={buscarAll} />
      <AgendeBar />
    </>
  );
}