import React from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerVideo,
  BarContainerArea,
  Title,
  SubTitle,
  Description,
  Card,
  Container,
  ContainerIframe

} from './bannerD.styles';

import { CustomButton } from '../CustomButton/customButton';

export default function BannerTwo() {

  const history = useHistory()

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginTop: "30px", marginBottom: "30px" }}>
        <BarGrid>
          <BarContainerVideo>
            <Card>
              <Container>
                <ContainerIframe src="https://www.youtube.com/embed/JUrZbNvlOWA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></ContainerIframe>
              </Container>
            </Card>
          </BarContainerVideo>

          <BarContainerArea>
            <Title>CONHEÇA A SOLUMEDI</Title>
            <SubTitle>A Solumedi possui unidades de atendimento por todo Brasil!</SubTitle>
            <Description>A Solumedi surgiu da ideia de conectar clínicas particulares com pessoas que buscam atendimento médico de qualidade. Não realizamos nenhum tipo de cobrança recorrente e o usuário efetua somente o pagamento do agendamento. O que faz com que a Solumedi tenha o melhor CUSTO x BENEFÍCIO no ramo da saúde.</Description>
            <CustomButton
              onClick={() => history.push('/unidades/buscar')}
              color={"#2a4f72"}
              background={"#fdd460"}
              margin={"10px 0px 0px 25px"}
              width={"60%"}
            >QUERO AGENDAR</CustomButton>
          </BarContainerArea>
        </BarGrid>
      </Flex>
    </>
  );
}