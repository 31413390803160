import React from 'react';

import { Header } from '../../components/LpHeader/header';
import { FooterEmBreve } from '../../components/LpFooter/footerEmBreve';

import LpEmBreve from '../../components/LpEmBreve/lpEmBreve';

export default function ModelE({ data }) {

  return (
    <>
      <Header email={data.data.email_adm} />
      <LpEmBreve data={data} />
      <FooterEmBreve data={data} />
    </>
  );
}