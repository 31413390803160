import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description,
  BarGrid,
  BarCard,
  MainDiv,
  DivNumber,
  DivText,
  NumberText,
  MensagemText

} from './table4.styles';

export default function Table() {
  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <BarGrid>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Seus Direitos</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText sytle={{ fontWeight: "900" }}>
                  Explicação
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Confirmação e Acesso</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Você pode solicitar à Solumedi a confirmação sobre a existência de tratamento dos seus dados pessoais para que, em caso positivo, você possa acessá-los, inclusive por meio de solicitação de cópias dos registros que temos sobre você.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Correção</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Você pode solicitar a correção dos seus dados pessoais caso estes estejam incompletos, inexatos ou desatualizados.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Anonimização, bloqueio ou eliminação</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Você pode solicitar (a) a anonimização dos seus dados pessoais, de forma que eles não possam mais ser relacionados a você e, portanto, deixem de ser dados pessoais; (b) o bloqueio dos seus dados pessoais, suspendendo temporariamente a sua possibilidade de os tratarmos para certas finalidades; e (c) a eliminação dos seus dados pessoais, caso em que deveremos apagar todos os seus dados pessoais sem possibilidade de reversão.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Portabilidade</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Você pode solicitar que a Solumedi forneça os seus dados pessoais em formato estruturado e interoperável visando à sua transferência para um terceiro, desde que essa transferência não viole a propriedade intelectual ou segredo de negócios da Empresa.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Informação sobre o compartilhamento</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Você tem o direito de saber quais são as entidades públicas e privadas com as quais a Solumedi realiza uso compartilhado dos seus dados pessoais. Manteremos, no item 3 dessa Política, uma indicação das nossas relações com terceiros que podem envolver o compartilhamento de dados pessoais. Em todo caso, se você tiver dúvidas ou quiser mais detalhes, você tem o direito de nos solicitar essas informações. A depender do caso, podemos limitar as informações fornecidas a você caso a sua divulgação possa violar a propriedade intelectual ou segredo de negócios da Solumedi.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Informação sobre a possibilidade de não consentir</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Você tem o direito de receber informações claras e completas sobre a possibilidade e as consequências de não fornecer consentimento, quando ele for solicitado pela Solumedi. O seu consentimento, quando necessário, deve ser livre e informado. Portanto, sempre que pedirmos seu consentimento, você será livre para negá-lo – nesses casos, é possível que alguns serviços não possam ser prestados.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Revogação do consentimento</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                Caso você tenha consentido com alguma finalidade de tratamento dos seus dados pessoais, você pode sempre optar por retirar o seu consentimento. No entanto, isso não afetará a legalidade de qualquer tratamento realizado anteriormente à revogação. Se você retirar o seu consentimento, é possível que fiquemos impossibilitados de lhe prestar certos serviços, mas iremos avisá-lo quando isso ocorrer.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
          <BarCard>
            <MainDiv>
              <DivNumber>
                <NumberText>Oposição</NumberText>
              </DivNumber>
              <DivText>
                <MensagemText>
                A lei autoriza o tratamento de Dados Pessoais mesmo sem o seu consentimento ou um contrato conosco. Nessas situações, somente trataremos seus Dados Pessoais se tivermos motivos legítimos para tanto, como, por exemplo, para histórico de atendimentos, envio de benefícios e novos serviços que você poderá se utilizar. Caso você não concorde com alguma finalidade de tratamento dos seus Dados Pessoais, você poderá apresentar oposição, solicitando a sua interrupção.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
          
        </BarGrid>

      </Flex>

    </>
  );
}