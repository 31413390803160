import React, { useState } from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Container,
  MySlides,
  BigImg,
  ButtonNext,
  ButtonPrev,
  CaptionContainer,
  Caption
} from './slider.styles';

export default function Slider({ slides }) {
  const [curr, setCurr] = useState(0);
  const { length } = slides;

  const goToNext = () => {
    setCurr(curr === length - 1 ? 0 : curr + 1);
  }

  const goToPrev = () => {
    setCurr(curr === 0 ? length - 1 : curr - 1);
  }

  if (!Array.isArray(slides) || length <= 0) {
    return null;
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >

        {slides.map((s, i) => (
          <div key={s.id}>
            {i === curr && (
              <Container>
                <MySlides>
                  <ButtonPrev onClick={goToPrev} >❮</ButtonPrev>
                  <BigImg src={s.image} />
                  <ButtonNext onClick={goToNext} >❯</ButtonNext>
                </MySlides>
                <CaptionContainer>
                  <Caption>{s.title}</Caption>
                </CaptionContainer>
              </Container>
            )}
          </div>
        ))}

      </Flex>
    </>
  );
}