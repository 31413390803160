import React, { useEffect, useState, useRef } from 'react';

import {
  Container,
  MySlides,
  BigImg,
  ButtonNext,
  ButtonPrev,
  CaptionContainer,
  Caption,
  TextTitle,
  Text
} from './slider.styles';

export default function Slider({ slides }) {
  const [curr, setCurr] = useState(0);
  const { length } = slides;

  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() =>
      setCurr((curr) =>
        curr === length - 1 ? 0 : curr + 1
      ), 5000);
    return () => {
      resetTimeout();
    };
  }, [curr]);

  const goToNext = () => {
    setCurr(curr === length - 1 ? 0 : curr + 1);
  }

  const goToPrev = () => {
    setCurr(curr === 0 ? length - 1 : curr - 1);
  }

  if (!Array.isArray(slides) || length <= 0) {
    return null;
  }

  return (
    <>
      {slides.map((s, i) => (
        <div key={s.id}>
          {i === curr && (
            <MySlides>
              <ButtonPrev onClick={goToPrev} >❮</ButtonPrev>
              <Container>
                <CaptionContainer>
                  <BigImg src={s.image} />
                  <Caption>{s.title}</Caption>
                  <TextTitle>{s.textTitle}</TextTitle>
                  <Text>{s.text}</Text>
                </CaptionContainer>
              </Container>
              <ButtonNext onClick={goToNext} >❯</ButtonNext>
            </MySlides>
          )}
        </div>
      ))}
    </>
  );
}