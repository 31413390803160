import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarCard,
  MainDiv,
  DivNumber,
  DivImage,
  DivText,

  SubTitleText,
  MensagemText
} from './campaign.styles';

import AnuncioOftalmologista from '../../assets/image/anuncioOftalmologista.png';

export default function HowTo() {

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} Background={"#fdd460"} style={{ padding: "25px 0px 25px 0px", marginTop: "30px", marginBottom: "30px" }} >
        <BarGrid>
          <BarCard>
            <MainDiv>
              <DivNumber>
                <DivImage background={AnuncioOftalmologista}></DivImage>
              </DivNumber>
              <DivText>
                <SubTitleText>
                  AQUI VOCÊ ENCONTRA
                </SubTitleText>
                <MensagemText>
                  Oftalmologistas Especializados, equipamentos de última geração, atendimento personalizado, serviço de qualidade, segurança no atendimento, facilidade de pagamento e muito mais....
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>
        </BarGrid>
      </Flex>
    </>
  );
}