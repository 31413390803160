import React, { useEffect } from 'react';

export default function ContactUs() {

	const iframe = `<iframe id="iframe-grpro-integration" style="width: 100%;height: 600px;border: 0;" src="https://grpro.solumedi.com.br//crm/form.asp?id=2"></iframe><script type="text/javascript">window.addEventListener("message", (event) => {if(event.data.type == "HEIGHT") { document.getElementById("iframe-grpro-integration").height = event.data.value;}if(event.data.type == "URL") { document.getElementById('iframe-grpro-integration').contentWindow.postMessage({type: "URL-RECEIVE",url: window.location.href}, '*');}if(event.data.type == "URL-RETORNO") { window.location.href = event.data.value;}});</script>`
	const iframeTrack = `<iframe id="tracker-neg-grpro-v-0-0-1" class="d-none" style="display: none;" src="https://grpro.solumedi.com.br//traqueamento/"></iframe>`

	const IFrame = ( props ) => {
		return ( <div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} /> )
	}
	const IFrameTrack = ( props ) => {
		return ( <div dangerouslySetInnerHTML={{ __html: props.iframeTrack ? props.iframeTrack : "" }} /> )
	}

	const infoTag = `window.addEventListener("message", (event) => {let iframe_tracker = document.getElementById("tracker-neg-grpro-v-0-0-1");let iframe_tracker_form = document.getElementById("iframe-grpro-integration");let iframe_tracker_wpp = document.getElementById("i-bformw-grpro");if (event.data.type == "TRACKER-GRPRO-METRICA-URL"){type = "TRACKER-GRPRO-METRICA-URL-RET";url = window.location.href;cookie = document.cookie;iframe_tracker.contentWindow.postMessage({type, url, cookie}, '');}if (event.data.type == "TRACKER-GRPRO-METRICA-TOKEN-GENERATE"){document.cookie = 'GRPRO-TRACKER-NEG='+event.data.token+'; expires=' + event.data.data + '; path=/';}if (event.data.type == "TRACKER-GRPRO-METRICA-SEND-TOKEN"){type = event.data.type;token = event.data.token;if(iframe_tracker_form){setTimeout(function(){iframe_tracker_form.contentWindow.postMessage({type,token}, '');}, 1000);}if (iframe_tracker_wpp){setTimeout(function(){iframe_tracker_wpp.contentWindow.postMessage({type,token}, '*');}, 1000);}}});`

	useEffect( () => {
		setTimeout( () => {

			const GRPROscript = document.createElement( "script" );
			document.body.appendChild( GRPROscript );
			GRPROscript.type = "text/javascript"
			GRPROscript.innerHTML = infoTag;

			return () => {
				document.removeChild( GRPROscript );
			}
		}, 2000 );
	}, [] )

	return (
		<>
			<IFrame iframe={iframe} />
			<IFrameTrack iframe={iframeTrack} />
		</>
	);
};
