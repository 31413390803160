import Unidades from '../content/unidades.json';
import IBGE from '../content/IBGE_ALL.json';

import { clearExpression } from './clearExpression';
import getDistance from './distance';

export const foundCity = (cidade) => {
  if (cidade === undefined || cidade === null || cidade === "" || cidade === "buscar") {
    return []
  }
  cidade = clearExpression(cidade)
  var result = []

  var cidadeLat = 0
  var cidadeLng = 0

  for (let index = 0; index < Unidades.length; index++) {
    var item = Unidades[index].enderecos[0].cidade;
    item = clearExpression(item)
    /* if (cidade === item) {
      result.push(Unidades[index])
      result.sort()
    } */

    if (cidade === item) {
      // ENCONTRADA CIDADE COM UNIDADE PELA EXPRESSAO  
      // PEGAR COORDENADAS DE REFERENCIA
      cidadeLat = Unidades[index].enderecos[0].latitude
      cidadeLng = Unidades[index].enderecos[0].longitude
      break
    }
  }

  if (cidadeLat === 0 && cidadeLng === 0) {
    // NAO FOI ENCONTRADA CIDADE PELA EXPRESSAO, LISTAR ALTERNATIVAS PARA ESCOLHA DO USUARIO
    var Lista = searchAllCities(cidade)
    if (Lista[0]) {
      result.push(Lista)
      // CONVERTENDO EM OBJETO
      result = Object.assign({}, result)
    } else {
      result = null
    }
  } else {
    // COMPARAR DISTANCIAS ENTRE CIDADE E UNIDADES
    var noFilter = listUnidadesByDistance(cidadeLat, cidadeLng)
    // LISTAR SOMENTE AS UNIDADE < 50km
    function isCloseEnough(unidade) {
      return unidade.distancia < 50;
    }
    var filtered = noFilter.filter(isCloseEnough);
    result.push(filtered)
    // CONVERTENDO EM OBJETO
    result = Object.assign({}, result)
  }

  return result
};


function listUnidadesByDistance(cidadeLat, cidadeLng) {
  var newList = []
  for (let index = 0; index < Unidades.length; index++) {
    var objeto = Unidades[index]
    var unidadeLat = Unidades[index].enderecos[0].latitude
    var unidadeLng = Unidades[index].enderecos[0].longitude

    var resultado = getDistance(cidadeLat, cidadeLng, unidadeLat, unidadeLng)
    resultado = resultado.toFixed(2)

    objeto["distancia"] = resultado
    newList.push(objeto)
  }
  newList.sort(function (a, b) {
    return a.distancia - b.distancia;
  });
  return newList
};


function searchAllCities(cidade) {
  var lista = []
  for (let index = 0; index < IBGE.length; index++) {
    var nome = IBGE[index].nome;
    nome = clearExpression(nome)
    if (nome.substr(0, cidade.length) === cidade) {
      lista.push(IBGE[index])
    }
  }
  return lista
};


export const foundInAllCities = (cidade) => {
  var cidadeLat = cidade.latitude
  var cidadeLng = cidade.longitude
 
  var result = []
  // COMPARAR DISTANCIAS ENTRE CIDADE E UNIDADES
  var noFilter = listUnidadesByDistance(cidadeLat, cidadeLng)
  // LISTAR SOMENTE AS UNIDADE < 50km
  function isCloseEnough(unidade) {
    return unidade.distancia < 100;
  }
  var filtered = noFilter.filter(isCloseEnough);
  result.push(filtered)
  // CONVERTENDO EM OBJETO
  result = Object.assign({}, result)
  return result
};
