import React from 'react';

import { Flex } from '../../../assets/styles/global';

import {
  BarGrid,
  BarContainerVideo,
  BarContainerArea,
  Title,
  SubTitle,
  Description,
  Card,
  Container,
  ContainerIframe

} from './bannerVideo.styles';

import { CustomButton } from '../../../components/CustomButton/customButton';


export default function BannerVideo() {


  const sendWhatsApp = () => {
    window.open( `https://api.whatsapp.com/send?phone=5541998150123&text=Olá, gostaria de receber uma apresentação da Solumedi Franquias`, "_blank" )
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginTop: "30px", marginBottom: "30px" }}>
        <BarGrid>
          <BarContainerVideo>
            <Card>
              <Container>
                <ContainerIframe src="https://www.youtube.com/embed/PIrUJdBGVzY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></ContainerIframe>
              </Container>
            </Card>
          </BarContainerVideo>

          <BarContainerArea>
            <Title>SEJA UM FRANQUEADO</Title>
            <SubTitle>Tenha o seu próprio negócio e faça parte de uma rede consolidada no mercado nacional!</SubTitle>
            <Description>A Solumedi é um dos melhores modelos de negócio no segmento de agendamento da saúde, e você não precisa ser um profissional da área para ser um franqueado.
              Através de um sistema inovador de prestação de serviço, a sua franquia vai contar com a experiência e suporte diferenciado, tudo para fazer desta sua oportunidade um sucesso!</Description>
            <CustomButton
              onClick={() => sendWhatsApp()}
              color={"#2a4f72"}
              background={"#fdd460"}
              margin={"10px 0px 0px 25px"}
              width={"60%"}
            >SEJA FRANQUEADO</CustomButton>
          </BarContainerArea>
        </BarGrid>
      </Flex>
    </>
  );
}