import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  Title,
  Description,
  BarGrid,
  BarCard
} from './gridEspecialidades.styles';

import BoxEspecialidade from '../BoxEspecialidade/boxEspecialidade';
import { CustomButton } from '../CustomButton/customButton';

import EspecialidadesGrid from '../../content/especialidadesGrid.json';

export default function GridEspecialidades() {

  const history = useHistory()
  const [result, setResult] = useState([])
  
  useEffect(() => {
    var array = []
    var obj = EspecialidadesGrid
    for (var prop in obj) {
      var slug = prop
      var data = obj[prop]
      //var descricao = item[`text`]
      array.push({"slug": slug , "data": data})
    }
    setResult(array)
  }, [])

  function goToEspecialidade(){
    window.open(`https://www.solumedi.com.br/especialidades`, "_blank")
  }


  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>ESPECIALIDADES</Title>
        <Description>Agende agora mesmo com valores reduzidos</Description>
      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <BarGrid>

        {result.map((item, index) => (
            <BarCard key={index}>
              <BoxEspecialidade data={item} />
            </BarCard>
          ))}
        </BarGrid>

      </Flex>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginBottom: "50px" }} >
        <CustomButton width={"60%"} onClick={() => goToEspecialidade()}>TODAS AS ESPECIALIDADES</CustomButton>
      </Flex>
    </>
  );
}