import React, { useEffect } from 'react';

import {
  Flex,
  Title,
  TextDiv,
  BoxText,
  Text,
  QuestionText
} from './termos.styles.js';

import Banner from './banner';
import Table from './table';
import Table2 from './table2';
import Table3 from './table3';
import Table4 from './table4';

export function Termos() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <Flex JustifyContent={"center"} FlexDirection={"column"} >
        <Title>POLÍTICA DE PRIVACIDADE</Title>
        <TextDiv>
          <Text>
            Nós, da SOLUMEDI FRANQUIAS E CONSULTORIA LTDA (SOLUMEDI), pessoa jurídica de direito privado, inscrita no CNPJ 28.187.612/0001-54, com sede na Rua Fagundes Varela, nº 900, Cj 04, Curitiba, Paraná, levamos a sua privacidade e a proteção dos seus dados a sério. Este documento explica como tratamos seus dados pessoais, quais são os seus direitos e como você pode exercê-los.
          </Text>
          <BoxText>
            <Text>
              A finalidade do tratamento de seus dados pessoais é para que possamos executar o contrato, otimizar sua interação conosco, divulgar nossos serviços, direcionamento à unidade de saúde, análise e pesquisa de mercado, histórico de atendimentos e executar publicidade on line direcionada.
            </Text>
          </BoxText>
          <Text>
            A Solumedi utiliza bases legais, que podem variar de acordo com a finalidade da coleta, para tratar seus dados pessoais. O prazo de armazenamento pode mudar conforme a base legal aplicável a cada situação e a finalidade.
          </Text>
          <QuestionText>
            1. CONCEITOS BÁSICOS: O QUE VOCÊ PRECISA SABER PARA COMPREENDER ESTA POLÍTICA?
          </QuestionText>
          <Text>
            A fim de descomplicar a leitura deste documento, apresentamos algumas definições úteis para a sua interpretação. Estes termos serão utilizados em letra maiúscula:
          </Text>

          <Table />

          <QuestionText>
            2. QUE TIPOS DE DADOS PESSOAIS A EMPRESA TRATA?
          </QuestionText>

          <Text>
            Os tipos de dados pessoais e a forma como a Empresa os coleta dependem de <span style={{ fontWeight: "bold" }}>como você se relaciona com a Solumedi e por quê.</span> Por exemplo, os dados pessoais coletados serão diferentes caso você seja um usuário do nosso site, cliente, parceiro, funcionário.
          </Text>
          <Text>
            Listamos abaixo algumas situações em que nós podemos tratar seus dados pessoais no contexto da sua relação com a Solumedi:
          </Text>

          <Table2 />

          <QuestionText>
            3. COM QUEM A EMPRESA COMPARTILHA OS SEUS DADOS PESSOAIS?
          </QuestionText>

          <Text>
            Nós operamos em parceria com algumas outras organizações para viabilizar as nossas atividades. Descrevemos aqui algumas situações em que podemos compartilhar Dados Pessoais:
          </Text>

          <Table3 />

          <QuestionText>
            4. QUAIS SÃO OS SEUS DIREITOS COMO TITULAR DE DADOS PESSOAIS?
          </QuestionText>
          <Text>
            <span style={{ fontWeight: "bold" }}>Os dados pessoais são seus</span> e a lei brasileira lhe garante uma série de direitos relacionados a eles. Nós estamos comprometidos com o cumprimento desses direitos e, nessa seção, vamos explicar quais são esses direitos e como você pode exercê-los. Veja a seguir:
          </Text>

          <Table4 />

          <QuestionText>
            Avisos Importantes
          </QuestionText>

          <BoxText>
            <Text>
              Para sua segurança, sempre que você apresentar uma requisição para exercer seus direitos, a Solumedi poderá solicitar algumas informações e/ou documentos complementares para que possamos comprovar a sua identidade, buscando impedir fraudes. Fazemos isso para garantir a segurança e a privacidade de todos.
            </Text>
            <Text>
              Em alguns casos, a Solumedi pode ter motivos legítimos para deixar de atender a uma solicitação de exercício de direitos. Essas situações incluem, por exemplo, casos em que uma revelação de informações específicas poderia violar direitos de propriedade intelectual ou segredos de negócio da Solumedi ou de terceiros, bem como casos em que pedidos de exclusão de dados não possam ser atendidos em razão da existência de obrigação da Solumedi de reter dados, seja para cumprir obrigações legais, regulatórias ou para possibilitar a defesa da Solumedi ou de terceiros em disputas de qualquer natureza.
            </Text>
            <Text>
              Ainda, algumas solicitações podem não ser respondidas de forma imediata, mas a Solumedi se compromete a responder todas as requisições em um prazo razoável e sempre em conformidade com a legislação aplicável.
            </Text>
          </BoxText>

          <Text>
            Caso você tenha alguma dúvida sobre essas questões ou sobre como você pode exercer seus direitos, fique à vontade para entrar em contato conosco por meio dos canais informados ao final desta Política.
          </Text>

          <QuestionText>
            5. POR QUANTO TEMPO OS DADOS PESSOAIS SERÃO ARMAZENADOS?
          </QuestionText>

          <Text>
            A Solumedi possui uma política de retenção de dados pessoais alinhada com a lei aplicável. Dados pessoais são armazenados somente pelo tempo que forem necessários para cumprir com as finalidades para as quais foram coletados, salvo se houver qualquer outra razão para sua manutenção como, por exemplo, cumprimento de quaisquer obrigações legais, regulatórias, contratuais, entre outras permitidas sob a lei.
          </Text>
          <Text>
            Sempre fazemos uma análise técnica para determinar o período de retenção adequado para cada tipo de dado pessoal coletado, considerando a sua natureza, necessidade de coleta e finalidade para a qual ele será tratado, bem como eventuais necessidades de retenção para o cumprimento de obrigações ou o resguardo de direitos.
          </Text>

          <QuestionText>
            6. COMO A SOLUMEDI PROTEGE SEUS DADOS PESSOAIS?
          </QuestionText>

          <Text>
            <span style={{ fontWeight: "bold" }}>Nossa responsabilidade é cuidar dos seus dados pessoais e utilizá-los somente para as finalidades descritas nessa Política.</span> Para garantir a sua privacidade e a proteção dos seus dados pessoais, adotamos recursos tecnológicos avançados para garantir a segurança de todos os dados tratados pela Solumedi. Entre as medidas de segurança implementadas estão a implementação de controles de acesso a sistemas e ambientes de tratamento de dados, técnicas de criptografia e a instalação de barreiras contra o acesso indevido às bases de dados (incluindo firewalls), entre outros controles de segurança da informação.
          </Text>
          <Text>
            <span style={{ fontWeight: "bold" }}>Nós nos esforçamos para proteger a privacidade de seus dados pessoais, mas infelizmente não podemos garantir total segurança.</span> Entradas e usos não autorizados de terceiros com informações suas, falhas de hardware ou software que não estejam sob controle da Solumedi e outros fatores externos podem comprometer a segurança dos seus dados pessoais. Por isso, sua atuação é fundamental para a manutenção de um ambiente seguro para todos. Caso você identifique ou tome conhecimento de qualquer fator que comprometa a segurança dos seus dados na sua relação com a Solumedi, por favor entre em contato conosco por meio das informações de contato indicadas abaixo.
          </Text>

          <QuestionText>
            7. COMO FALAR SOBRE DADOS PESSOAIS COM A SOLUMEDI?
          </QuestionText>

          <Text>
            Se você acredita que seus dados pessoais foram tratados de maneira incompatível com esta Política ou com as suas escolhas enquanto titular dos seus dados pessoais, ou, ainda, se você tiver dúvidas, comentários ou sugestões relacionadas a esta Política e à forma como tratamos seus dados pessoais, entre em contato conosco. Nós temos um Encarregado que está à disposição nos seguintes endereços de contato:
          </Text>
          <Text>
            <span style={{ fontWeight: "bold" }}>Encarregado:</span> Eluilson B. Carvalho
          </Text>
          <Text>
            <span style={{ fontWeight: "bold" }}>E-mail para contato:</span> eluilson@solumedi.com.br
          </Text>
          <QuestionText>
            9. MUDANÇAS NA POLÍTICA DE PRIVACIDADE
          </QuestionText>
          <Text>
            Como estamos sempre buscando melhorar os nossos serviços e a forma como operamos, esta Política de Privacidade pode passar por atualizações para refletir as melhorias realizadas. Desta forma, recomendamos a visita periódica desta página para que você tenha conhecimento sobre as modificações efetivadas.
          </Text>
          <Text>
            {/* DATA DA ÚLTIMA ATUALIZAÇÃO: 01 Dezembro de 2021. */}
            <small>
              DATA DA ÚLTIMA ATUALIZAÇÃO: 17 Maio de 2022.
            </small>
          </Text>
        </TextDiv>
      </Flex>
    </>
  );
}