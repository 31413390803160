import styled from 'styled-components';


export const MainRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0px 0px 0px 0px;
`

export const Column = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 40px 0px;
  @media (min-width: 768px) {
    width: 45%;
  }
`

export const Container = styled.div`
  width: 90%;
  padding-top: 90%;
  background: url(${props => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
