import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const Content = styled.div`
  padding: 5px;
  overflow: hidden;
`

export const Text = styled.p`
  text-align: justify;
  color: ${colors.gray2};
  margin: 0px;
  font-size: 12px;
  margin: 5px 0px 10px 0px;
  @media (min-width: 768px) {
    font-size: 16px;
    margin: 10px 0px 10px 0px;
  }
`
